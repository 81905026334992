import axios from "axios";
import qs from 'qs';

const instance = (process.env.REACT_APP_ENV === 'demo') ? 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  }) :
  (process.env.REACT_APP_ENV === 'production') ? 
    axios.create({
      baseURL: 'https://service.tkonft.art',
    }) : 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  });

export function Summary(token, params) {
  return instance.get("/admin/portal-user/list" + params,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function Detail(token, id) {
  return instance.get("/admin/portal-user/show/"+id,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function UpdateSuspend(token, id, data) {
  return instance.post("/admin/portal-user/suspend-status/"+id,data,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function UpdateConfirm(token, id, data) {
  return instance.post("/admin/portal-user/confirm/"+id,data,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function UpdateFeature(token, data) {
  return instance.post("/admin/tkonft/featured-merchants-create",
    qs.stringify(data),{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function DetailListContract(token, id, params) {
  return instance.get("/admin/portal-user/show-contract/"+id+params,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function getFee(token, address) {
  return instance.get("/marketplace/check-value?contractAddress="+address,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function UpdateFee(token, data) {
  return instance.post("/marketplace/submit-customfee", data ,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}