import React from 'react';
import { Link } from "react-router-dom";
import clsx from "clsx";
import moment from 'moment';
import { Form, Button, ButtonGroup } from "react-bootstrap";
import { createBrowserHistory } from 'history';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class UpdateMerchant extends React.Component {
  history() {
    history.push("/leaderboard");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tcdx-upload-dev.oss-ap-southeast-5.aliyuncs.com/' : 'https://tcdx-upload.oss-ap-southeast-5.aliyuncs.com/',
      page: '',
      idData: '',
      loading: false,
      name: '',
      address: '',
      email: '',
      phone: '',
      status: '',
      active: false,
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
  }

  emptyState(){
    this.setState({
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tcdx-upload-dev.oss-ap-southeast-5.aliyuncs.com/' : 'https://tcdx-upload.oss-ap-southeast-5.aliyuncs.com/',
      page: '',
      idData: '',
      loading: false,
      questionID: '',
      questionEN: '',
      contentID: '<h2>Syarat Dan Ketentuan</h2><ul><li>Syarat A</li><li>Syarat B</li><li>Syarat C</li><li>Syarat D</li><li>Syarat E</li></ul><p><a href="https://tokocrypto.com">Selengkapnya →</a></p>',
      contentEN: '<h2>Term And Condition</h2><ul><li>Term A</li><li>Term B</li><li>Term C</li><li>Term D</li><li>Term E</li></ul><p><a href="https://tokocrypto.com">Show More →</a></p>',
    });
  }

  handleChangeEditorID =  ( event ) => {
    const data = event.editor.getData();
    this.setState({ contentID: data });
  }

  handleChangeEditorEN =  ( event ) => {
    const data = event.editor.getData();
    this.setState({ contentEN: data });
  }

  // getToken(){
  //   let token = localStorage.getItem("token")
  //   return token;
  // }


  componentDidMount(){
    let checkPath = this.props.history.location.pathname.split('/');
    if(checkPath.indexOf("edit-category") > 0){
      this.setState({
        page: checkPath[checkPath.length - 1],
        idData: checkPath[checkPath.length - 2],
        editPage: true,
      });
      // this.getDetailData(checkPath[checkPath.length - 2]);
    }
    // let id = this.props.history.location.pathname.split('/')[this.props.history.location.pathname.split('/').length - 2];
  }

  onChangeFile = async (e) => {
    let file = e.target.files[0];
    if(file){
      await this.setState({
        banner: file
      })
    }
  }

  dateTime = date => {
    var ISOformat = moment(date).toISOString()
    return ISOformat
  }

  render(){
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                  <div>
                    <ButtonGroup style={{width: '30%', marginBottom: '35px'}}>
                      <Button variant={!this.state.active ? 'outline-success' : 'outline-secondary'} onClick={() => this.setState({active: false})}>Merchant Info</Button>
                      <Button variant={!this.state.active ? 'outline-secondary' : 'outline-success'} onClick={() => this.setState({active: true})}>Social Info</Button>
                    </ButtonGroup>
                  </div>
                  {!this.state.active ?
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Merchant Name</Form.Label>
                          <Form.Control type="text" value={this.state.nameMerch} onChange={this.handleChange('name')} required/>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Brand Name</Form.Label>
                          <Form.Control type="text" value={this.state.nameBrand} onChange={this.handleChange('nameBrand')} required/>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Merchant Address</Form.Label>
                          <Form.Control type="text" value={this.state.addressMerc} onChange={this.handleChange('addressMerc')} disabled/>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="text" value={this.state.email} onChange={this.handleChange('email')} disabled/>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Phone</Form.Label>
                          <Form.Control type="text" value={this.state.phone} onChange={this.handleChange('phone')} disabled/>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Building Address</Form.Label>
                          <Form.Control type="text" value={this.state.address} onChange={this.handleChange('address')} disabled/>
                        </Form.Group>
                      </div>
                      <div className="col-md-12">
                        <Form.Group style={{width: '60%'}}>
                          <Form.Label>ID Card Photo</Form.Label>
                          <div className="d-flex justify-content-center align-items-center">
                            <Form.Control className="form-control" type="file" onChange={this.onChangeFile} accept="image/*"/>
                          </div>  
                          <Form.Text className="text-muted">
                            <span>*Allowed file: .JPG, .JPEG, .PNG</span>
                          </Form.Text>
                        </Form.Group>
                      </div>
                      <div className="col-md-12">
                        <Form.Group style={{width: '60%'}}>
                          <Form.Label>Other Documents</Form.Label>
                          <div className="d-flex justify-content-center align-items-center">
                            <Form.Control className="form-control" type="file" onChange={this.onChangeFile} accept="image/*"/>
                          </div>  
                          <Form.Text className="text-muted">
                            <span>*Allowed file: .JPG, .JPEG, .PNG, .PDF</span>
                          </Form.Text>
                        </Form.Group>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Merchant Website</Form.Label>
                          <Form.Control type="text" value={this.state.website} onChange={this.handleChange('website')}/>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Instagram</Form.Label>
                          <Form.Control type="text" value={this.state.instagram} onChange={this.handleChange('instagram')}/>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Twitter</Form.Label>
                          <Form.Control type="text" value={this.state.twitter} onChange={this.handleChange('twitter')}/>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Facebook</Form.Label>
                          <Form.Control type="text" value={this.state.facebook} onChange={this.handleChange('facebook')}/>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Tiktok</Form.Label>
                          <Form.Control type="text" value={this.state.tiktok} onChange={this.handleChange('tiktok')}/>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <Form.Label>Youtube</Form.Label>
                          <Form.Control type="text" value={this.state.youtube} onChange={this.handleChange('youtube')}/>
                        </Form.Group>
                      </div>
                    </div>
                  </>
                  }
                  <div className="kt-login__actions" style={{justifyContent: 'center', display: 'flex', marginTop: '100px'}}>
                    <Link to="/merchants" style={{width: '17%'}}>
                      <Button variant="outline-danger" className="mr-2">Back To Merchant</Button>
                    </Link>
                    <button
                      style={{width: '15%'}}
                      type="submit"
                      disabled={this.state.loading}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                        }
                      )}`}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}