import React from 'react';
import { Link } from "react-router-dom";
import clsx from "clsx";
import moment from 'moment';
import { Form, Button } from "react-bootstrap";
import { createBrowserHistory } from 'history';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class UpdateCollector extends React.Component {
  history() {
    history.push("/leaderboard");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tcdx-upload-dev.oss-ap-southeast-5.aliyuncs.com/' : 'https://tcdx-upload.oss-ap-southeast-5.aliyuncs.com/',
      page: '',
      idData: '',
      loading: false,
      name: '',
      address: '',
      email: '',
      phone: '',
      status: '',
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
  }

  emptyState(){
    this.setState({
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tcdx-upload-dev.oss-ap-southeast-5.aliyuncs.com/' : 'https://tcdx-upload.oss-ap-southeast-5.aliyuncs.com/',
      page: '',
      idData: '',
      loading: false,
      questionID: '',
      questionEN: '',
      contentID: '<h2>Syarat Dan Ketentuan</h2><ul><li>Syarat A</li><li>Syarat B</li><li>Syarat C</li><li>Syarat D</li><li>Syarat E</li></ul><p><a href="https://tokocrypto.com">Selengkapnya →</a></p>',
      contentEN: '<h2>Term And Condition</h2><ul><li>Term A</li><li>Term B</li><li>Term C</li><li>Term D</li><li>Term E</li></ul><p><a href="https://tokocrypto.com">Show More →</a></p>',
    });
  }

  handleChangeEditorID =  ( event ) => {
    const data = event.editor.getData();
    this.setState({ contentID: data });
  }

  handleChangeEditorEN =  ( event ) => {
    const data = event.editor.getData();
    this.setState({ contentEN: data });
  }

  // getToken(){
  //   let token = localStorage.getItem("token")
  //   return token;
  // }


  componentDidMount(){
    let checkPath = this.props.history.location.pathname.split('/');
    if(checkPath.indexOf("edit-category") > 0){
      this.setState({
        page: checkPath[checkPath.length - 1],
        idData: checkPath[checkPath.length - 2],
        editPage: true,
      });
      // this.getDetailData(checkPath[checkPath.length - 2]);
    }
    // let id = this.props.history.location.pathname.split('/')[this.props.history.location.pathname.split('/').length - 2];
  }

  dateTime = date => {
    var ISOformat = moment(date).toISOString()
    return ISOformat
  }

  render(){
    const statusType = [
      {
        id: "1",
        name: 'Active',
      },
      {
        id: "0",
        name: 'Disabled',
      },
    ];
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Collector Name</Form.Label>
                        <Form.Control type="text" value={this.state.name} onChange={this.handleChange('name')} required/>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" value={this.state.address} onChange={this.handleChange('address')} disabled/>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" value={this.state.email} onChange={this.handleChange('email')} disabled/>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="text" value={this.state.phone} onChange={this.handleChange('phone')} disabled/>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Select Status</label>
                        <div className="input-group">
                            <select className="form-control" onChange={(e) => this.setState({ status: e.target.value })} placeholder="Select Data">
                                {/* <option disabled selected={this.state.status === '' ? true : false}>Pilih Salah Satu</option> */}
                                {statusType.map((e) => (
                                  <option key={e.id} value={e.id} selected={e.id === this.state.status ? true : false} defaultValue={e.id === this.state.status ? true : false}>{e.name}</option>
                                ))}
                            </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="kt-login__actions" style={{justifyContent: 'center', display: 'flex'}}>
                    <Link to="/faq" style={{width: '17%'}}>
                      <Button variant="outline-danger" className="mr-2">Back To Collector</Button>
                    </Link>
                    <button
                      type="submit"
                      disabled={this.state.loading}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                        }
                      )}`}
                    >
                      {this.state.editPage ? 'Edit' : 'Add'} data Collector
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}