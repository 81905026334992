import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Chart } from "chart.js";
import { metronic } from "../../_metronic";
// import ReactLoading from 'react-loading';

export default function SalesBarChart({ title, datas, label, load = false }) {
  const ref = useRef();
  const { successColor } = useSelector(state => ({
    successColor: metronic.builder.selectors.getConfig(
      state,
      "colors.state.success"
    )
  }));

  const data = useMemo(
    () => ({
      labels: label,
      datasets: [
        {
          // label: 'dataset 1',
          backgroundColor: successColor,
          data: datas
        }
      ]
    }),
    [datas, label, successColor]
  );

  const numberWithCommas = (val) =>{
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    // console.log('load :', load)
    const chart = new Chart(ref.current, {
      data,
      type: "bar",
      options: {
        title: { display: false },
        tooltips: {
          intersect: false,
          mode: "nearest",
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10
        },
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false,
        barRadius: 4,
        scales:{
          yAxes:[{
            scaleLabel:{
              display: false
            },
            ticks: {
              callback: function(val) {
                return numberWithCommas(val);
              },
              color: 'red',
            }
          }],
        },
        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } }
      }
    });

    return () => {
      chart.destroy();
    };
  }, [data, load]);

  return (
    <div className="kt-widget14">
      <div className="kt-widget14__header kt-margin-b-30">
        <h3 className="kt-widget14__title">{title}</h3>
      </div>
      <div className="kt-widget14__chart" style={{ height: "300px" }}>
        {/* {load ? 
          <ReactLoading style={{position: 'relative', left: '50%', fill: successColor, height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} />
          : */}
          <canvas ref={ref} />
        {/* } */}
      </div>
    </div>
  );
}
