import axios from "axios";
import qs from 'qs';

const instance = (process.env.REACT_APP_ENV === 'demo') ? 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  }) :
  (process.env.REACT_APP_ENV === 'production') ? 
    axios.create({
      baseURL: 'https://service.tkonft.art',
    }) : 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  });

export function login(data) {
  return instance.post("/admin/auth", qs.stringify(data), {
    headers: {
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function Summary(token, params) {
  return instance.get("/admin/tkonft/adminuser-search" + params,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function getMenu(token) {
  return instance.get("/admin/tkonft/menu-list",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function getListRole(token) {
  return instance.get("admin/tkonft/get-role-list",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function Detail(token, id) {
  return instance.get("/admin/tkonft/adminuser/"+id+"/detail",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function Create(token, data) {
  return instance.post("/admin/tkonft/adminuser-create", data,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function Update(token, id, data) {
  return instance.put("/admin/tkonft/adminuser/"+id+"/update", data,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function UpdateStatus(token, id, data) {
  return instance.put("/admin/tkonft/adminuser/"+id+"/changestatus",
    qs.stringify(data),{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function ResetPassword(token, id, data) {
  return instance.put("/admin/tkonft/adminuser/"+id+"/resetpassword",
    qs.stringify(data),{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function Delete(token, id) {
  return instance.delete("/admin/tkonft/adminuser/"+id+"/delete",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}