import axios from "axios";

const instance = (process.env.REACT_APP_ENV === 'demo') ? 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  }) :
  (process.env.REACT_APP_ENV === 'production') ? 
    axios.create({
      baseURL: 'https://service.tkonft.art',
    }) : 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  });

export function Summary(token, params) {
  return instance.get("/admin/tkonft/system-logs" + params,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}