// import React, { Suspense, lazy } from "react";
import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "../pages/home/Builder";
import Dashboard from "../pages/dashboard/dashboard";

import Category from "../pages/category/category";
import ManageCategory from "../pages/category/addCategory";

import FAQ from "../pages/faq/faq";
import ManageFAQ from "../pages/faq/addFaq";
import CategoryFAQ from "../pages/faq/categoryFAQ";
import ManageCategoryFAQ from "../pages/faq/addCategoryFaq";

import Banner from "../pages/banner/banner";
import ManageBanner from "../pages/banner/manage-banner";

import Merchant from "../pages/merchant/merchant";
import UM from "../pages/merchant/update";
import LCA from "../pages/merchant/contract";

import Collectors from "../pages/collector/collector";
import UC from "../pages/collector/update";

import Artwork from "../pages/artwork/artwork";
import BB from "../pages/artwork/blindbox";

import FM from "../pages/feature/merchant";
import FA from "../pages/feature/artwork";
import PA from "../pages/feature/partner";
import DPA from "../pages/feature/partnerDetail";

import Trx from "../pages/transaction/transaction";
import TrxCollectibles from "../pages/transaction/collectibles";

import Admin from "../pages/admin/admin";
import ManageAdmin from "../pages/admin/add";

import HIW from "../pages/static-pages/how-it-works";
import TC from "../pages/static-pages/term-condition";
import PP from "../pages/static-pages/privacy-policy";
import WHATISTKONFT from "../pages/static-pages/what-is-tko-nft";

import SupportContract from "../pages/support-contract/support-contract";
import ManageSupportContract from "../pages/support-contract/add";

import Config from "../pages/config/general-configuration";

import SystemLog from "../pages/system-log/system-log";

import ImportContract from "../pages/file-import";
import ManageContract from "../pages/file-import/update-contract";

import { LayoutSplashScreen } from "../../_metronic";

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route exact path="/builder" component={Builder} />
        <Route exact path="/dashboard" component={Dashboard} />

        <Route exact path="/category" component={Category} />
        <Route exact path="/category/add-category" component={ManageCategory} />
        <Route
          exact
          path="/category/:id/edit-category"
          component={ManageCategory}
        />

        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/faq/add-faq" component={ManageFAQ} />
        <Route exact path="/faq/:id/edit-faq" component={ManageFAQ} />
        <Route exact path="/faq/category-faq" component={CategoryFAQ} />
        <Route
          exact
          path="/faq/category-faq/add"
          component={ManageCategoryFAQ}
        />
        <Route
          exact
          path="/faq/category-faq/:id/update"
          component={ManageCategoryFAQ}
        />

        <Route exact path="/banner-management" component={Banner} />
        <Route
          exact
          path="/banner-management/add-banner"
          component={ManageBanner}
        />
        <Route
          exact
          path="/banner-management/:id/edit-banner"
          component={ManageBanner}
        />

        <Route exact path="/merchants" component={Merchant} />
        <Route exact path="/merchants/:id/edit-merchants" component={UM} />
        <Route exact path="/merchants/:id/contract" component={LCA} />

        <Route exact path="/collectors" component={Collectors} />
        <Route exact path="/collectors/:id/edit-collectors" component={UC} />

        <Route exact path="/artworks" component={Artwork} />
        <Route exact path="/toko-surpise" component={BB} />

        <Route exact path="/feature-merchant" component={FM} />
        <Route exact path="/feature-artwork" component={FA} />
        <Route exact path="/feature-partner" component={PA} />
        <Route exact path="/feature-partner/:id" component={DPA} />

        <Route exact path="/transaction-nft-tokosurprise" component={Trx} />
        <Route
          exact
          path="/transaction-collectibles"
          component={TrxCollectibles}
        />

        <Route exact path="/admin" component={Admin} />
        <Route exact path="/admin/add-admin" component={ManageAdmin} />
        <Route exact path="/admin/:id/edit-admin" component={ManageAdmin} />

        <Route exact path="/static-pages/how-it-works" component={HIW} />
        <Route exact path="/static-pages/term-condition" component={TC} />
        <Route exact path="/static-pages/privacy-policy" component={PP} />
        <Route
          exact
          path="/static-pages/what-is-tko-nft"
          component={WHATISTKONFT}
        />

        <Route exact path="/supported-contracts" component={SupportContract} />
        <Route
          exact
          path="/supported-contracts/add"
          component={ManageSupportContract}
        />
        <Route
          exact
          path="/supported-contracts/:id/edit"
          component={ManageSupportContract}
        />

        <Route exact path="/general-configuration" component={Config} />

        <Route exact path="/system-log" component={SystemLog} />

        <Route exact path="/import-contract" component={ImportContract} />
        <Route exact path="/import-contract/:id/update-contract" component={ManageContract} />

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
