import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from "../../_metronic/utils/utils";
import ListExtension from '../handler/extension.json';

const Viewdetail = ({ data, extdata, type = false }) => {
  const listext = ListExtension;
  const [extension, setExt] = useState('image');
  const [url, setUrl] = useState(toAbsoluteUrl("/nft/detail-artwork.jpeg"));
  useEffect(() => {
    const ext = extdata.toLowerCase();
    const extset = listext[ext] ? listext[ext] : 'none';
    let urlimage = data;
    if (extset === 'image') {
      if (data !== undefined || data !== null) {
        const extBack = `.${extdata}`;
        const splitUrl = data.split(extBack);
        let makeword = '';
        makeword += `${splitUrl[0]}_thumbnail${extBack}`;
        urlimage = makeword;
      }
    } else if (extset === 'video') {
      if (data !== undefined || data !== null) {
        const extBack = `.${extdata}`;
        const splitUrl = data.split(extBack);
        let makeword = '';
        makeword += `${splitUrl[0]}_thumbnail.gif`;
        urlimage = makeword;
      }
    } else if (extset === 'gif') {
      if (data !== undefined || data !== null) {
        urlimage = data;
      }
    }
    setExt(extset);
    setUrl(`${urlimage}`);
  }, [data, extdata, listext]);
  const image = (
    <>
      <img src={`${url}`} className={!type ? 'img-list-data' : 'img-detail'} alt="" />
    </>
  );
  const video = (
    <>
      <img src={`${url}`} className={!type ? 'img-list-data' : 'img-detail'} alt="" />
    </>
  );
  const audio = (
    <>
      <img src={toAbsoluteUrl("/media/nft/default-audio.png")} className={!type ? 'img-list-data' : 'img-detail'} alt="" />
    </>
  );
  return (
    <>
      {(extension === 'image' || extension === 'gif') && (
        <>
          { image }
        </>
      )}
      {extension === 'video' && (
        <>
          { video }
        </>
      )}
      {extension === 'audio' && (
        <>
          { audio }
        </>
      )}
      {extension === 'none' && (
        <>
          Unable to preview file.
        </>
      )}
    </>
  );
};
export default Viewdetail;
