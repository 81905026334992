/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Form, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import { Detail, Update } from '../../crud/importcontract';
require("bootstrap/scss/bootstrap.scss");

const ManageContract = () => {
  const history =  createBrowserHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [contractAddress, setContractAddress] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [projectSlug, setProjectSlug] = useState('');
  const [isBlacklisted, setIsBlacklisted] = useState(false);
  const [projectDescription, setProjectDescription] = useState('');
  const [projectWebsite, setProjectWebsite] = useState('');
  const [projectFacebook, setProjectFacebook] = useState('');
  const [projectTwitter, setProjectTwitter] = useState('');
  const [projectTiktok, setProjectTiktok] = useState('');
  const [projectInstagram, setProjectInstagram] = useState('');
  const [projectYoutube, setProjectYoutube] = useState('');
  const [projectDiscord, setProjectDiscord] = useState('');
  const [projectPortofolio, setProjectPortofolio] = useState('');
  const [projectTelegram, setProjectTelegram] = useState('');
  const [avatarImage, setAvatarImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);

  const StatusBlacklist = [
    {
      value: false,
      label: "Whitelisted",
    },
    {
      value: true,
      label: "Blacklisted",
    },
  ];

  const getToken = () => {
    let token = localStorage.getItem("token")
    return token;
  }

  const ErrorFunction = (erorr) => {
    console.log(erorr);
    setIsLoading(false);
    if(erorr.response){
      if(erorr.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${erorr.response.data.code} message: ${erorr.response.data.message}`);
      }
    }
  };
  
  const splitDataProject = (dataProject) => {
    setProjectWebsite(dataProject.website);
    setProjectFacebook(dataProject.facebook);
    setProjectTwitter(dataProject.twitter);
    setProjectTiktok(dataProject.tiktok);
    setProjectInstagram(dataProject.instagram);
    setProjectYoutube(dataProject.youtube);
    setProjectDiscord(dataProject.discord);
    setProjectPortofolio(dataProject.portofolio);
    setProjectTelegram(dataProject.telegram);
  }
  const getDetailDataProject = (contractAddress) => {
    setIsLoading(true);
    Detail(getToken(), contractAddress)
    .then(response => {
      setIsLoading(false);
      setProjectName(response.data.data.name);
      setProjectSlug(response.data.data.slug);
      setIsBlacklisted(response.data.data.is_blacklisted);
      setProjectDescription(response.data.data.short_description);
      splitDataProject(response.data.data.external_url);
    })
    .catch(e => {
      ErrorFunction(e);
      setIsLoading(false);
    })
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const contractAddress = window.location.pathname.split('/')[2];
      getDetailDataProject(contractAddress);
      setContractAddress(contractAddress);
    }
  }, []);

  const isSubmit = event => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('name', projectName);
    formData.append('is_blacklisted', isBlacklisted);
    formData.append('short_description', projectDescription || '');
    formData.append('slug', projectSlug || '');
    formData.append('website', projectWebsite || '');
    formData.append('facebook', projectFacebook || '');
    formData.append('twitter', projectTwitter || '');
    formData.append('tiktok', projectTiktok || '');
    formData.append('instagram', projectInstagram || '');
    formData.append('youtube', projectYoutube || '');
    formData.append('discord', projectDiscord || '');
    formData.append('portofolio', projectPortofolio || '');
    formData.append('telegram', projectTelegram || '');
    if(avatarImage){
      formData.append('file_avatar', avatarImage);
    }
    if(bannerImage){
      formData.append('file_banner', bannerImage);
    }

    updateBanner(formData);
  }

  const backToFileContract = () => {
    const timeoutId = setTimeout(() => {
      history.push("/import-contract");
      window.location.reload();
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(timeoutId);
  }

  const updateBanner = (dataSubmission) => {
    Update(getToken(), contractAddress, dataSubmission)
    .then(responseDeleteBanner => {
      if (responseDeleteBanner.data.code === 200) {
        toast.success('Banner has been Updated');
        backToFileContract();
      }
    })
    .catch(e => {
      ErrorFunction(e);
      setIsLoading(false);
    })
  }

  const onChangeFileAvatar = (e) => {
    let file = e.target.files[0];
    if (file) {
      setAvatarImage(file);
    }
  };
  
  const onChangeFileBanner = (e) => {
    let file = e.target.files[0];
    if (file) {
      setBannerImage(file);
    }
  };

  return (
    <div style={{fontSize: '10px'}} className="head">
      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-portlet__preview">
            <div className="kt-section">
              <form className="m-login__form m-form" onSubmit={isSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group>
                      <Form.Label>Project Name</Form.Label>
                      <Form.Control type="text" placeholder="Banner Title" value={projectName} onChange={(event) => setProjectName(event.target.value)} required/>
                    </Form.Group>
                  </div>
                  <div className='col-md-12'>
                    <Form.Group>
                      <Form.Label>Project Avatar</Form.Label>
                      <div className='d-flex justify-content-center align-items-center'>
                        <Form.Control
                          className='form-control'
                          type='file'
                          onChange={onChangeFileAvatar}
                          accept='image/*'
                        />
                      </div>
                      <Form.Text className='text-muted'>
                        <span>*Image has to be 140 x 140 px</span>
                      </Form.Text>
                    </Form.Group>
                  </div>
                  <div className='col-md-12'>
                    <Form.Group>
                      <Form.Label>Project Banner</Form.Label>
                      <div className='d-flex justify-content-center align-items-center'>
                        <Form.Control
                          className='form-control'
                          type='file'
                          onChange={onChangeFileBanner}
                          accept='image/*'
                        />
                      </div>
                      <Form.Text className='text-muted'>
                        <span>*Image has to be 1280 x 260 px</span>
                      </Form.Text>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group>
                      <Form.Label>Project Slug</Form.Label>
                      <Form.Control type="text" placeholder="tokomall.io/projects/urlslug" value={projectSlug} onChange={(event) => setProjectSlug(event.target.value)}/>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <select className="custom-select costume-dropdown" onChange={(e) => setIsBlacklisted( e.target.value )}>
                        {StatusBlacklist.map((e) => (
                          <option key={e.value} value={e.value} selected={e.value === isBlacklisted} defaultValue={e.value === isBlacklisted}>{e.label}</option>
                        ))}
                      </select>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <Form.Control as="textarea" value={projectDescription} onChange={(event) => setProjectDescription(event.target.value)}/>
                    </Form.Group>
                  </div>
                  <div className="col-md-12">
                    <Form.Group>
                      <Form.Label>Social Media</Form.Label>
                      <div className="d-flex">
                        <Form.Control 
                          type="text"
                          value={projectInstagram}
                          onChange={(event) => setProjectInstagram(event.target.value)}
                          placeholder="Instagram *Optional"
                          className="mr-2"
                        />
                        <Form.Control 
                          type="text"
                          value={projectTwitter}
                          onChange={(event) => setProjectTwitter(event.target.value)}
                          placeholder="Twitter *Optional"
                          className="mr-2"
                        />
                        <Form.Control 
                          type="text"
                          value={projectFacebook}
                          onChange={(event) => setProjectFacebook(event.target.value)}
                          placeholder="Facebook *Optional"
                          className="mr-2"
                        />
                        <Form.Control 
                          type="text"
                          value={projectDiscord}
                          onChange={(event) => setProjectDiscord(event.target.value)}
                          placeholder="Discord *Optional"
                          className="mr-2"
                        />
                        <Form.Control 
                          type="text"
                          value={projectWebsite}
                          onChange={(event) => setProjectWebsite(event.target.value)}
                          placeholder="Website *Optional"
                          className="mr-2"
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="kt-login__actions" style={{justifyContent: 'end', display: 'flex'}}>
                  <Link to="/import-contract">
                    <Button variant="outline-danger" className="mr-2" style={{ width: '160px' }}>Cancel</Button>
                  </Link>
                  <button
                    type="submit"
                    disabled={isLoading}
                    style={{ width: '160px' }}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
                      }
                    )}`}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ManageContract;