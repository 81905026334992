import React, {useEffect, useState} from "react";
import moment from 'moment';
import { Link, useHistory } from "react-router-dom";
import { Button, Table, Badge, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { createBrowserHistory } from 'history';
import { toAbsoluteUrl, limitAddressDisplay, copyPaste } from "../../../_metronic/utils/utils";
import { SummaryPartner, UpdatePartner, SummaryListUserPartner, insertPartner, removePartner } from '../../crud/feature';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import ComponentScroll from '../../component/ComponentScroll';
import Swal from 'sweetalert2';
import { DragHandle } from "../../partials/dnd/DragHandler";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


const Partner = () => {
  const history = useHistory();
  const [listPartner, setListPartner] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [listUser, setListUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [pageScroll, setPageScroll] = useState({
    page: 1,
    totalData: 20
  });
  const [linkImage, setLinkImage] = useState(process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/');

  const getToken = () => {
    let token = localStorage.getItem("token")
    return token;
  };

  const handleAlert = (status = 'error', message = '') => {
    Swal.fire({
      icon: status,
      title: message,
      showConfirmButton: false,
      timer: 1500
    })
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
    if (result.isConfirmed) {
      return true;
    }
    return false;
  };

  const dataUser = async (next = false, search = '', page = 1) => {
    try {
      const data = await SummaryListUserPartner(getToken(), search, page);
      const result = data.data.data;
      console.log(result);
      setPageScroll({
        page: result.current_page,
        totalData: result.per_page
      });
      let resultData = [];
      if (page === 1) {
        resultData = [
          ...result.data
        ];
      } else {
        resultData = [
          ...listUser,
          ...result.data
        ];
      }
      if (result.per_page === 0) {
        setHasMore(false);
      }
      console.log(resultData.length);
      setListUser(resultData);
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };
  const dataPartner = async (next = false) => {
    setLoading(true)
    try {
      const data = await SummaryPartner(getToken());
      const result = data.data.data;
      setListPartner(result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };
  const handleNext = () => {
    dataUser(true, search, pageScroll.page + 1);
  };
  const addPartner = async (val) => {
    setLoading(true);
    try {
      const result = {
        "user_address": val
      };
      const data = await insertPartner(getToken(), result);
      setSearch('');
      setSelectedUser({});
      dataPartner();
      history.push(`/feature-partner/${data.data.data.id}`);
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (error.response) {
        if (error.response.data) {
          handleAlert('error', error.response.data.message);
        }
      }
      setLoading(false)
    }
  };
  const deletePartner = async (val) => {
    const confirmData = await handleDelete();
    console.log(confirmData);
    if (confirmData) {
      setLoading(true);
      try {
        await removePartner(getToken(), val);
        dataPartner();
      } catch (error) {
        console.log(error);
        console.log(error.response);
        if (error.response) {
          if (error.response.data) {
            handleAlert('error', error.response.data.message);
          }
        }
        setLoading(false)
      }
    }
  };
  const updateFeaturePartner = async (val) => {
    setLoading(true);
    try {
      await UpdatePartner(getToken(), val);
      setTimeout(async () => {
        dataPartner();
      }, 1500);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    dataPartner();
  }, []);
  useEffect(() => {
    if (Object.keys(selectedUser).length > 0) {
      setSearch(selectedUser.fullname);
    }
  }, [selectedUser]);
  useEffect(() => {
    if (search.length > 1) {
      if (search !== selectedUser.fullname) {
        setSelectedUser({});
      }
      setHasMore(true);
      dataUser(false, search, 1);
    }
  }, [search]);
  useEffect(() => {
    setLoading(false);
  }, [listPartner]);

  return (
    <div className="head-position">
      <div className="kt-portlet body-transparent">
        <div className="kt-portlet__body pddlessForce">
          <div className="kt-portlet__preview">
            <div className="search-partner">
              <div className="form-search">
                <div className="searchUser" />
                <input type="text" onChange={e => setSearch(e.target.value)} value={search} placeholder="Add Merchant" />
                <div className="frame-button">
                  <button className="btn btn-success" disabled={Object.keys(selectedUser).length === 0} onClick={() => addPartner(selectedUser.crypto_address)}>
                    <span className="fas fa-plus"></span>
                  </button>
                </div>
                {search.length > 1 && listUser.length > 0 && Object.keys(selectedUser).length === 0 ? (
                  <ComponentScroll classPage="floating-select" triggerCheck={handleNext}>
                    <>
                      {listUser.map((user, t) => (
                        <div className="item-data" onClick={() => setSelectedUser(user)} key={t}>
                          {user.fullname}
                        </div>
                      ))}
                    </>
                  </ComponentScroll>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="kt-portlet height-content">
        <div className="kt-portlet__body pddlessForce">
          <div className="kt-portlet__preview h-100">
            <Table striped responsive>
              <thead>
                <tr>
                  <th className="tableheh" style={{ width: '15%' }}>
                    <div className="p-left" style={{color: '#000000'}}>
                      Positions
                    </div>
                  </th>
                  <th className="tableheh" style={{ width: '36%' }}>
                    Partners
                  </th>
                  <th className="tableheh" style={{ width: '15%' }}>
                    Email
                  </th>
                  <th className="center tableheh" style={{width: '10%'}}>Action</th>
                </tr>
              </thead>
              <DragDropContext
                onDragEnd={(param) => {
                  const srcI = param.source.index;
                  const desI = param.destination?.index;
                  console.log(desI);
                  console.log(srcI);
                  listPartner.splice(desI, 0, listPartner.splice(srcI, 1)[0]);
                  let dataPosition = [];
                  listPartner.forEach((element, index) => 
                    dataPosition.push({'id': element.id, 'position': index+1}),
                  );
                  console.log(dataPosition);
                  let data = {
                    'data' : dataPosition
                  }
                  updateFeaturePartner(data);
                }}
              >
                {loading ? (
                  <tbody>
                    <tr>
                      <td className="center" colSpan="4"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                    </tr>
                  </tbody>
                ) : (
                  <Droppable droppableId="droppable-1">
                    {(provided, _) => (
                      <tbody
                        className="content-tbody"
                        ref={provided.innerRef}
                        {...provided.droppableProps}>
                        {listPartner.map((item, i) => (
                          <Draggable
                            key={item.id}
                            draggableId={"draggable-" + item.id}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  // boxShadow: snapshot.isDragging
                                  //   ? "0 0 .4rem #666"
                                  //   : "none",
                                  // left: snapshot.isDragging ? 0 : "none",
                                  // color: snapshot.isDragging ? "#ffffff": "none",
                                  // top: snapshot.isDragging ? 900 : "none"
                                }}
                              >
                                <td className="align-middle">
                                  <div className="p-left-2" style={{color: '#000000'}}>
                                    {i+1}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex ">
                                    <img
                                      alt="Logo"
                                      src={item.user.photo ? `${linkImage}${item.user.photo}` : toAbsoluteUrl("/media/users/profile.png")}
                                      className="img-list-data img-partner-user"
                                    />
                                    <div className="align-self-center">
                                      <span className="name-user">{item.user.fullname ? item.user.fullname : '-'}</span><br />
                                      <div className="frame-email">
                                        <span className="copy-icon" onClick={() => copyPaste(item.user_address)} />
                                        <span className="email-user">{limitAddressDisplay(item.user_address, 6)}</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="align-middle">{item.user.email ? item.user.email : '-'}</td>
                                <td className="content-action">
                                  <DragHandle {...provided.dragHandleProps} />
                                  <span style={{ marginLeft: '0' }}>|</span>
                                  <Link to={`/feature-partner/${item.id}`}>
                                    <OverlayTrigger
                                      placement='top'
                                      overlay={
                                        <Tooltip id={`tooltip-top`}>
                                          Detail Partner
                                        </Tooltip>
                                      }
                                    >
                                      <span className="btn-action" onClick={() => console.log(item.id)}>
                                        <div className="showIcon" />
                                      </span>
                                    </OverlayTrigger>
                                  </Link>
                                  <span>|</span>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={
                                      <Tooltip id={`tooltip-top`}>
                                        Remove Merchant
                                      </Tooltip>
                                    }
                                  >
                                    <span className="btn-action" onClick={() => deletePartner(item.id)}>
                                      <div className="removeIcon" />
                                    </span>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                )}
              </DragDropContext>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;