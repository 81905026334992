import React from 'react';
import CKEditor from 'ckeditor4-react';
import clsx from "clsx";
import { Form } from "react-bootstrap";
import { createBrowserHistory } from 'history';
import { Create, Detail, Update } from '../../crud/staticpages';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class TermCondition extends React.Component {
  history() {
    history.push("/leaderboard");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tcdx-upload-dev.oss-ap-southeast-5.aliyuncs.com/' : 'https://tcdx-upload.oss-ap-southeast-5.aliyuncs.com/',
      page: '',
      idData: '',
      loading: false,
      loadingUpdate: false,
      titleID: '',
      titleEN: '',
      descID: '<p>Lorem ipsum lorem ipsum lorem ipsum</p>',
      descEN: '<p>Lorem ipsum lorem ipsum lorem ipsum</p>',
      slugID: 'term-condition',
      slugEN: 'term-condition',
      stateType: 2,
      submit: false,
    }
  }

  emptyState(){
    this.setState({
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tcdx-upload-dev.oss-ap-southeast-5.aliyuncs.com/' : 'https://tcdx-upload.oss-ap-southeast-5.aliyuncs.com/',
      page: '',
      idData: '',
      loading: false,
      titleID: '',
      titleEN: '',
      descID: '<p>Lorem ipsum lorem ipsum lorem ipsum</p>',
      descEN: '<p>Lorem ipsum lorem ipsum lorem ipsum</p>',
      slugID: 'term-condition',
      slugEN: 'term-condition',
      stateType: 1,
      submit: false,
    });
  }

  handleChangeEditorID =  ( event ) => {
    const data = event.editor.getData();
    this.setState({ descID: data });
  }

  handleChangeEditorEN =  ( event ) => {
    const data = event.editor.getData();
    this.setState({ descEN: data });
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getDetailData(){
    this.setState({
      loading: true
    })

    Detail(this.getToken(), this.state.stateType)
    .then(res => {
      this.setState({
        loading: false,
        titleID: res.data.data.title_id,
        titleEN: res.data.data.title_en,
        descID: res.data.data.body_id,
        descEN: res.data.data.body_en,
        slugID: res.data.data.slug_id,
        slugEN: res.data.data.slug_en,
      })
    })
    .catch(e => {
      console.log(e.response.data);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 400){
          this.setState({
            submit: true
          })
        }
        else if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }

  isSubmit = event => {
    event.preventDefault();

    let data = {
      'title_id': this.state.titleID,
      'title_en': this.state.titleEN,
      'slug_id': this.state.slugID,
      'slug_en': this.state.slugEN,
      'body_id': this.state.descID,
      'body_en': this.state.descEN,
      'type': this.state.stateType
    }

    if(this.state.submit){
      this.addEvent(data);
    }else{
      this.editEvent(data);
    }
  }

  editEvent(data){
    this.setState({
      loadingUpdate: true
    })

    Update(this.getToken(), this.state.stateType, data)
    .then(res => {
      toast.success('Update Success');
      this.setState({
        loadingUpdate: false,
      })
      this.getDetailData();
    })
    .catch(e => {
      console.log(e);
      this.emptyState();
      this.setState({
        loadingUpdate: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  addEvent(data){
    this.setState({
      loadingUpdate: true
    })

    Create(this.getToken(), data)
    .then(res => {
      toast.success('Create new data is Success');
      this.setState({
        loadingUpdate: false,
      })
      this.getDetailData();
    })
    .catch(e => {
      console.log(e);
      this.emptyState();
      this.setState({
        loadingUpdate: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  componentDidMount(){
    this.getDetailData();
  }

  onChangeSlugID = async (e) =>{
    let event = e.target.value;
    let str = event
    // let str = event.replace(/\s+/g, '-');
    str = str.replace(/[-!@#$%^&*()_+|~=`{}[\]:";'<>?,./]/g, "");
    str = str.replace(/\s/g, "-");
    str = str.replace(/--/g, "-");
    this.setState({slugID: str.toLowerCase(), titleID: event})
  }

  onChangeSlugEN = async (e) =>{
    let event = e.target.value;
    let str = event
    str = str.replace(/[-!@#$%^&*()_+|~=`{}[\]:";'<>?,./]/g, "");
    str = str.replace(/\s/g, "-");
    str = str.replace(/--/g, "-");
    this.setState({slugEN: str.toLowerCase(), titleEN: event})
  }

  render(){
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Question (ID)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Event Name" value={this.state.titleID} onChange={(e) => this.setState({titleID: e.target.value})} required/>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Question (EN)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Event Name" value={this.state.titleEN} onChange={(e) => this.setState({titleEN: e.target.value})} required/>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Slug (ID)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Event Name" value={this.state.slugID} disabled/>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Slug (EN)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Event Name" value={this.state.slugEN} disabled/>
                      </Form.Group>
                    </div>
                  </div>
                  <Form.Group>
                    <Form.Label>Content (ID)</Form.Label>
                    <br />
                    <CKEditor
                      onChange={this.handleChangeEditorID}
                      data={this.state.descID}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Content (EN)</Form.Label>
                    <br />
                    <CKEditor
                      onChange={this.handleChangeEditorEN}
                      data={this.state.descEN}
                    />
                  </Form.Group>
                  <div className="kt-login__actions" style={{justifyContent: 'center', display: 'flex'}}>
                    <button
                      type="submit"
                      disabled={this.state.loadingUpdate}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loadingUpdate
                        }
                      )}`}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}