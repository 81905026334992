import React from 'react';
import moment from 'moment';
import { Summary } from '../../crud/systemlog';
import {Table, Modal} from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import Pagination from "react-js-pagination";
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class SystemLog extends React.Component {
  historyEdit(id) {
    history.push("/leaderboard/"+id+"/editEvent");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingListData: false,
      loadingModal: false,
      totalData: 0,
      data:[],
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderSort: 'asc',
      active: 1,
      show: false,
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true});
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    });
    this.getSummary(activePage);
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = null
    if (date === null) {  // d.valueOf() could also work
      fmtDate = 'Date is Null'
    } else {
      fmtDate = moment(date1).format('DD-MM-YY hh:m A');
    }
    return fmtDate
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getSummary(pages = 1) {
    this.setState({loading: true})

    let search = ''
    if(this.state.seacrhParams !== ''){
      search=`&title=${this.state.seacrhParams}`
    }

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?&order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&page=${pages}&limit=${this.state.postPerPage}${search}`
    }else{
      params = `?&order_sort=${this.state.orderSort}&page=${pages}&limit=${this.state.postPerPage}${search}`
    }

    Summary(this.getToken(), params)
    .then(res => {
      this.setState({
        totalData: res.data.data.total_data,
        data: res.data.data.data || [],
        postPerPage: res.data.data.per_page,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        data:[],
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }
  

  componentDidMount(){
    this.getSummary(this.state.active);
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.getSummary(this.state.active);
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value });
  }

  searchFunction = () => {
    this.getSummary(1);
    this.setState({ active: 1 });
  }

  render(){
    
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start">
                  <input 
                    type="text" 
                    className="form-control mr-3" 
                    name="search" 
                    placeholder="Search by Email" 
                    value={this.state.seacrhParams} 
                    onChange={this.changeSearch} /> 
                  <button
                    className="btn btn-success btn-sm pr-5 pl-5 mr-3"
                    type="button"
                    onClick={() => this.searchFunction()}>
                      Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ width: '4%' }}>
                        No
                      </th>
                      <th className="tableheh" style={{ width: '10%' }}>
                        IP Address
                        {/* <button onClick={(e) => this.onSortChange('event_name')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button> */}
                      </th>
                      <th className="tableheh" style={{ width: '15%' }}>
                        Modules
                      </th>
                      <th className="tableheh" style={{ width: '20%' }}>
                        User
                      </th>
                      <th className="tableheh" style={{ width: '36%' }}>
                        Activities
                      </th>
                      <th className="tableheh">
                        Created At
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((item, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td>{item.ip}</td>
                        <td>{item.modules}</td>
                        <td>{item.email}</td>
                        <td>{item.activities}</td>
                        <td>{this.dateTime(item.created_at)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
          <Modal.Header closeButton style={{padding: '20px 15px 20px 75px' }}>
            <Modal.Title><b>Question 1 / Pertanyaan 1</b></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{padding: '45px 75px'}}>
            <div className="mb-5">
              <span className="title-popup-style">Answer (EN)</span><br />
              <span className="desc-popup-style">In publishing and graphic design, Lorem ipsum is a placeholder text.</span>
            </div>
            <div>
              <span className="title-popup-style">Answer (ID)</span><br />
              <span className="desc-popup-style">commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</span>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}