import axios from "axios";

const instance = (process.env.REACT_APP_ENV === 'demo') ? 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  }) :
  (process.env.REACT_APP_ENV === 'production') ? 
    axios.create({
      baseURL: 'https://service.tkonft.art',
    }) : 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  });

export function Summary(token, params) {
  return instance.get("/admin/collectibles/projects/list" + params,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function Detail(token, contract_address) {
  return instance.get("/admin/collectibles/projects/"+contract_address+"/detail",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function Create(token, data) {
  return instance.post("/admin/collectibles/projects/import", data, {
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function Update(token, contract_address, data) {
  return instance.put("/collectibles/projects/"+contract_address+"/update/admin", data,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function UpdateStatus(token, contract_address) {
  const data = {};
  return instance.post("/admin/collectibles/projects/"+contract_address+"/blacklist", data,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function Delete(token, contract_address) {
  return instance.delete("/admin/collectibles/projects/"+contract_address,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}