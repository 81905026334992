import React from 'react';
import { Link } from "react-router-dom";
import clsx from "clsx";
import Swal from 'sweetalert2';
import moment from 'moment';
import { Table, Badge, Form, OverlayTrigger, ButtonToolbar, Tooltip, Modal, Button } from "react-bootstrap";
import { limitAddressDisplay, toAbsoluteUrl, copyPaste } from "../../../_metronic/utils/utils";
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import Pagination from "react-js-pagination";
import { Summary, Create, Delete, UpdateStatus } from '../../crud/importcontract';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class FileImport extends React.Component {
  historyEdit(id) {
    history.push("/leaderboard/"+id+"/editEvent");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isShow: false,
      showBlacklist: false,
      totalData: 0,
      dataSummary:[],
      postPerPage: 15,
      seacrhParams: '',
      active: 1,
      confirm: '',
      inputContractAddress: '',
      contractAddress: null,
      isBlacklist: false
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
    this.handleAddNewContractShow = this.handleAddNewContractShow.bind(this);
    this.handleAddNewContractClose = this.handleAddNewContractClose.bind(this);
    this.handeleBlacklistShow = this.handeleBlacklistShow.bind(this);
    this.handeleBlacklistClose = this.handeleBlacklistClose.bind(this);
  }

  handleAddNewContractShow(){
    this.setState({isShow : true})
  }
  handleAddNewContractClose(){
    this.setState({isShow : false, inputContractAddress: ''})
  }
  handeleBlacklistShow(contractAddressProject, valBlacklist){
    this.setState({showBlacklist : true, contractAddress: contractAddressProject, isBlacklist: valBlacklist})
  }
  handeleBlacklistClose(){
    this.setState({showBlacklist : false, contractAddress: null})
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.getSummary()
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getSummary() {
    this.setState({
      loading: true
    })

    let params = '';
    params = `?page=${this.state.active}&limit=${this.state.postPerPage}&q=${this.state.seacrhParams}`

    Summary(this.getToken(), params)
    .then(res => {
      this.setState({
        totalData: res.data.data.total_data,
        dataSummary: res.data.data.data,
        postPerPage: res.data.data.per_page,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        false: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  CreateContract = event => {
    event.preventDefault();

    let data = {
      'contract_address': this.state.inputContractAddress
    }

    this.CreateContractCollectible(data);
  }

  CreateContractCollectible(data) {
    this.setState({
      loading: true
    })

    Create(this.getToken(), data)
    .then(res => {
      if(res){
        toast.success(`Import is success`);
      }
      this.setState({
        loading: false,
        isShow: false,
        inputContractAddress: '',
      })
      this.getSummary();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  componentDidMount(){
    this.getSummary();
  }

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  }

  handleDelete(contract){
    Swal.fire({
      title: 'Are you sure to delete the contract?',
      text: "If you delete a contract, you can't get it back!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.delete(contract);
      }
    })
  }

  delete(contract) {
    this.setState({
      loading: true
    })

    Delete(this.getToken(), contract)
    .then(res => {
      this.setState({
        loading: false,
      })
      toast.success('Delete Success');
      this.getSummary();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  changeStatusBlacklist() {
    this.setState({
      loading: true
    })

    UpdateStatus(this.getToken(), this.state.contractAddress)
    .then(res => {
      this.setState({
        loading: false,
        showBlacklist: false,
      })
      toast.success('Update Success');
      this.getSummary();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false,
        showBlacklist: false,
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  render(){
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start">
                  <input 
                    type="text" 
                    className="form-control mr-3" 
                    name="search" 
                    placeholder="Search Contract Address or Owner Address" 
                    value={this.state.seacrhParams} 
                    onChange={this.changeSearch} /> 
                  <button
                    className="btn btn-success btn-sm pr-5 pl-5 mr-3"
                    type="button"
                    onClick={() => this.getSummary()}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-primary btn-sm mr-3"
                    type="button"
                    style={{width: '17%', padding: '10px'}}
                    onClick={() => this.handleAddNewContractShow()}
                  >
                    Add New Contract
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ width: '4%' }}>
                        No
                      </th>
                      <th className="tableheh">
                        Contract Address
                      </th>
                      <th className="tableheh">
                        Owner Address
                      </th>
                      <th className="tableheh center">
                        Status
                      </th>
                      <th className="tableheh">
                        Date of import
                      </th>
                      <th className="center tableheh" style={{width: '10%'}}>Action</th>
                    </tr>
                  </thead>
                  {this.state.loading ? 
                    <tbody>
                      <tr>
                        <td className="center" colSpan="5"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                      </tr>
                    </tbody> :
                    <tbody>
                      {this.state.dataSummary.map((row, index) => (
                        <tr>
                          <td className="align-middle">{index+1}</td>
                          <td className="align-middle">
                            <span className="btn-action address-list" onClick={() =>  copyPaste(row.contract_address)}>
                              <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                              <u>{limitAddressDisplay(row.contract_address, 12)}</u>
                            </span>
                          </td>
                          <td className="align-middle">
                            {row.owner_address ?
                              <span className="btn-action address-list" onClick={() =>  copyPaste(row.owner_address)}>
                                <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                                <u>{limitAddressDisplay(row.owner_address, 12)}</u>
                              </span>
                            : <span className="btn-action address-list" onClick={() =>  copyPaste('')}>
                                <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                                -
                              </span>}
                          </td>
                          <td className="all-center">
                            {row.is_blacklisted ?
                              <Badge variant="danger" onClick={() => this.handeleBlacklistShow(row.contract_address, false)} style={{cursor: 'pointer'}}>
                                Blacklist
                              </Badge>
                              :
                              <Badge variant="success" onClick={() => this.handeleBlacklistShow(row.contract_address, true)} style={{cursor: 'pointer'}}>
                                Whitelist
                              </Badge>
                            }
                          </td>
                          <td className="align-middle">{this.dateTime(row.created_at)}</td>
                          <td className="all-center">
                            <ButtonToolbar style={{ justifyContent: 'center'}}>
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Edit Data
                                  </Tooltip>
                                }
                              >
                                <Link to={`/import-contract/${row.contract_address}/update-contract`} style={{width: '17%'}}>
                                  <span className="btn-action">
                                    <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-pencil-alt text-info" />
                                  </span>
                                </Link>
                              </OverlayTrigger>
                              &nbsp; | &nbsp;
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Delete Data
                                  </Tooltip>
                                }
                              >
                                <span className="btn-action" onClick={() => this.handleDelete(row.contract_address)}>
                                  <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-trash-alt text-danger" />
                                </span>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </Table>
                {this.state.totalData > 15 &&
                  <div className="d-flex justify-content-center align-items-center pagination-data">
                    <Pagination
                      activePage={this.state.active}
                      itemsCountPerPage={this.state.postPerPage}
                      itemClass="page-item"
                      linkClass="page-link"
                      totalItemsCount={this.state.totalData}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                    />
                  </div>}
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.isShow} onHide={this.handleAddNewContractClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
                <Modal.Title>Import New Contract</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="m-login__form m-form" onSubmit={this.CreateContract}>
              <Form.Group>
                <Form.Control
                  type="text"
                  value={this.state.inputContractAddress}
                  onChange={this.handleChange('inputContractAddress')}
                  placeholder="Type Contract Address"
                />
              </Form.Group>
              <div className="kt-login__actions" style={{justifyContent: 'space-around', display: 'flex'}}>
                <button
                style={{ width: '100%' }}
                  type="submit"
                  disabled={this.state.loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                    }
                  )}`}
                >
                  Submit
                </button>
              </div>

            </form>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showBlacklist} onHide={this.handeleBlacklistClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column justify-content-center text-center align-items-center">
              <img
                className="icon-is-blacklist"
                src={toAbsoluteUrl(`/media/collectibles/${this.state.isBlacklist ? 'blacklist' : 'whitelist'}.svg`)}
                alt=""
              />
              <div className="title-question">
                Are you sure you want to change your status to
                {' '}
                {this.state.isBlacklist ? <span className="red">Blacklist</span> : <span className="green">Whitelist</span>} ?
              </div>
              <div className="content-agreement">
                <span>
                  If you agree your status will change to
                  {' '}
                  {this.state.isBlacklist ? 'Blacklist!' : 'Whitelist!'}
                </span>
              </div>
              <div className="d-flex">
                {this.state.isBlacklist ?
                  <>
                    <Button
                      variant="outline-danger"
                      className="btn-cancel-isblacklist"
                      onClick={() => this.handeleBlacklistClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => this.changeStatusBlacklist()}
                    >
                      Change To Blacklist
                    </Button>
                  </>
                : <>
                    <Button
                      variant="outline-success"
                      className="btn-cancel-isblacklist"
                      onClick={() => this.handeleBlacklistClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => this.changeStatusBlacklist()}
                    >
                      Change To Whitelist
                    </Button>
                  </>}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}