import axios from "axios";
import qs from 'qs';

const instance = (process.env.REACT_APP_ENV === 'demo') ? 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  }) :
  (process.env.REACT_APP_ENV === 'production') ? 
    axios.create({
      baseURL: 'https://service.tkonft.art',
    }) : 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  });

export function Summary(token, params) {
  return instance.get("/admin/tkonft/staticpage-search" + params,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function Detail(token, id) {
  return instance.get("/admin/tkonft/staticpage/"+id+"/detail",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function Create(token, data) {
  return instance.post("/admin/tkonft/staticpage-create",
    qs.stringify(data),{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function Update(token, id, data) {
  return instance.put("/admin/tkonft/staticpage/"+id+"/update",
    qs.stringify(data),{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

// export function Delete(token, id) {
//   return instance.delete("/admin/tkonft/staticpage/"+id+"/delete",{
//     headers: {
//       'Authorization': token,
//       'X-TCDX-SIGNATURE': 'salamtothemoon',
//       "Content-Type": "application/x-www-form-urlencoded"
//     }
//   });
// }