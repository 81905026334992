import axios from "axios";
import qs from 'qs';

const instance = (process.env.REACT_APP_ENV === 'demo') ? 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  }) :
  (process.env.REACT_APP_ENV === 'production') ? 
    axios.create({
      baseURL: 'https://service.tkonft.art',
    }) : 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  });

export function Summary(token, params) {
  return instance.get("/admin/tkonft/category-search" + params,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function SummaryCategory(token) {
  return instance.get("/admin/tkonft/category/list",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function Detail(token, id) {
  return instance.get("/admin/tkonft/category/"+id+"/detail",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function Create(token, data) {
  return instance.post("/admin/tkonft/category-create",
    data,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    }
  });
}

export function Update(token, id, data) {
  return instance.put("/admin/tkonft/category/"+id+"/update",data,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    }
  });
}

export function Delete(token, id) {
  return instance.delete("/admin/tkonft/category/"+id+"/delete",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}