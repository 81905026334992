import React from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import Swal from 'sweetalert2';
import { Table, Modal, OverlayTrigger, ButtonToolbar, Tooltip } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import Pagination from "react-js-pagination";
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class SupportContract extends React.Component {
  historyEdit(id) {
    history.push("/leaderboard/"+id+"/editEvent");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingListData: false,
      loadingModal: false,
      totalData: 0,
      data:[],
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderSort: 'asc',
      active: 1,
      show: false,
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true});
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.getEvent()
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).format('DD-MM-YY hh:m A');

    return fmtDate
  }

  // getToken(){
  //   let token = localStorage.getItem("token")
  //   return token;
  // }

  componentDidMount(){
    // this.getEvent();
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.getEvent()
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  }

  copyPaste(val){
    navigator.clipboard.writeText(val);
    this.handleNotif(val);
    return
  }
  handleNotif(val){
    Swal.fire({
      icon: 'success',
      title: `Address ${val} copied`,
      showConfirmButton: false,
      timer: 1500
    })
  }

  handleDelete(){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // this.delete(id);
      }
    })
  }

  render(){
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start">
                  <input 
                    type="text" 
                    className="form-control mr-3" 
                    name="search" 
                    placeholder="Search" 
                    value={this.state.seacrhParams} 
                    onChange={this.changeSearch} /> 
                  <button className="btn btn-success btn-sm pr-5 pl-5 mr-3" type="button" onClick={() => this.getEvent()}>Search</button>
                  <Link to="/supported-contracts/add" style={{width: '17%'}}>
                    <button className="btn btn-outline-primary btn-sm mr-3" type="button" style={{width: '100%', padding: '10px'}}>Add Contract</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ width: '4%' }}>
                        No
                      </th>
                      <th className="tableheh" style={{ width: '20%' }}>
                        Contract Name
                        {/* <button onClick={(e) => this.onSortChange('event_name')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button> */}
                      </th>
                      <th className="tableheh" style={{ width: '41%' }}>
                        Contract Address
                      </th>
                      <th className="tableheh">
                        Created At
                      </th>
                      <th className="center tableheh" style={{width: '10%'}}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>BakerySwap NFT</td>
                      <td>
                        <span className="btn-action" onClick={() =>  this.copyPaste('0x61a98......AB130bd06')}>
                          <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                          <u>0x61a98......AB130bd06</u>
                        </span>
                      </td>
                      <td>12-01-2021 15:30 PM</td>
                      <td>
                        <ButtonToolbar style={{ justifyContent: 'center'}}>
                          <OverlayTrigger
                            placement='top'
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                Delete Data
                              </Tooltip>
                            }
                          >
                            <span className="btn-action" onClick={() => this.handleDelete()}>
                              <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-trash-alt text-danger" />
                            </span>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>OpenSea NFT</td>
                      <td>
                        <span className="btn-action" onClick={() =>  this.copyPaste('0x61a98......AB130bd06')}>
                          <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                          <u>0x61a98......AB130bd06</u>
                        </span>
                      </td>
                      <td>12-01-2021 15:30 PM</td>
                      <td>
                        <ButtonToolbar style={{ justifyContent: 'center'}}>
                          <OverlayTrigger
                            placement='top'
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                Delete Data
                              </Tooltip>
                            }
                          >
                            <span className="btn-action">
                              <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-trash-alt text-danger" />
                            </span>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
          <Modal.Header closeButton style={{padding: '20px 15px 20px 75px' }}>
            <Modal.Title><b>Question 1 / Pertanyaan 1</b></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{padding: '45px 75px'}}>
            <div className="mb-5">
              <span className="title-popup-style">Answer (EN)</span><br />
              <span className="desc-popup-style">In publishing and graphic design, Lorem ipsum is a placeholder text.</span>
            </div>
            <div>
              <span className="title-popup-style">Answer (ID)</span><br />
              <span className="desc-popup-style">commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</span>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}