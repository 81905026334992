import React from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import clsx from "clsx";
import List from "../data";
import { ButtonGroup, Button, Table, Badge, Modal, OverlayTrigger, ButtonToolbar, Tooltip } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import SelectDate from '../../component/datetime';
import ViewArtwork from '../../component/thumbnail';
import Pagination from "react-js-pagination";
import { Detail, Summary, UpdateFeature, UpdateSuspend } from '../../crud/artwork';
import { SummaryCategory } from '../../crud/category';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class Artwork extends React.Component {
  historyEdit(id) {
    history.push("/leaderboard/"+id+"/editEvent");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      linkImage: process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/',
      show: false,
      showSuspend: false,
      showFeature: false,
      loading: false,
      loadingSuspend: false,
      totalData: 0,
      data:[],
      dataCategory:[],
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderSort: 'desc',
      active: 1,
      params: '',
      date: null,
      isCheckAll: false,
      isCheck: [],
      list: [],
      suspend: '',
      CategoryStatus: '',
      status: '',
      rejectTrue: false,
      rejectReason: '',
      activeTab: false,
      idChange: 0,
      suspendStat: '',
      dataDetail: {},
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handeleSuspendShow = this.handeleSuspendShow.bind(this);
    this.handeleSuspendClose = this.handeleSuspendClose.bind(this);
    this.handeleFeatureShow = this.handeleFeatureShow.bind(this);
    this.handeleFeatureClose = this.handeleFeatureClose.bind(this);
  }

  handleShow(id) {
    this.setState({ show: true});
    this.detailArtwork(id);
  }
  handleClose() {
    this.setState({ show: false });
  }

  handeleFeatureShow(id, val) {
    this.setState({ showFeature: true, idChange: id, suspendStat: val});
  }
  handeleFeatureClose() {
    this.setState({ showFeature: false });
  }

  handeleSuspendShow(id, val) {
    this.setState({ showSuspend: true, idChange: id, suspendStat: val});
  }
  handeleSuspendClose() {
    this.setState({ showSuspend: false });
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.getSummary()
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getSummary() {
    this.setState({
      loading: true
    })

    let search = ''
    if(this.state.seacrhParams !== ''){
      search=`&q=${this.state.seacrhParams}`
    }

    // let filterDate = ''
    // if(this.state.date){
    //   filterDate=`&date=${moment(this.state.date).format('YYYY-MM-DD')}`
    // }

    let statusCat = ''
    if(this.state.CategoryStatus !== ''){
      statusCat=`&category=${parseInt(this.state.CategoryStatus)}`
    }

    // let status_suspend = ''
    // if(this.state.suspendStatus !== ''){
    //   status_suspend=`&suspended=${this.state.suspendStatus}`
    // }

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?type=3&order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}${search}${statusCat}`
    }else{
      params = `?type=3&order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}${search}${statusCat}`
    }

    Summary(this.getToken(), params)
    .then(res => {
      this.setState({
        totalData: res.data.data.total_data,
        data: res.data.data.data || [],
        postPerPage: res.data.data.per_page,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        data:[],
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  approveFeature() {
    this.setState({
      loadingSuspend: true
    })

    let data = {
        "artwork_id": this.state.idChange
    }

    UpdateFeature(this.getToken(), data)
    .then(res => {
      toast.success(`message: ${this.state.suspendStat} the artwork from the featured artwork list is success`);
      this.setState({
        showFeature: false,
        suspendStat: '',
        idChange: 0,
        loadingSuspend: false,
      })
      this.getSummary();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        showFeature: false,
        suspendStat: '',
        idChange: 0,
        loadingSuspend: false,
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  suspend() {
    this.setState({
      loadingSuspend: true
    })

    let data = {
        "suspended": this.state.suspendStat
    }

    UpdateSuspend(this.getToken(), this.state.idChange, data)
    .then(res => {
      toast.success(`message: update status to ${this.state.suspendStat} is success`);
      this.setState({
        showSuspend: false,
        idChange: 0,
        suspendStat: '',
        loadingSuspend: false,
      })
      this.getSummary();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        showSuspend: false,
        loadingSuspend: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  detailArtwork(id){
    this.setState({
      loading: true
    })

    Detail(this.getToken(), id)
    .then(res => {
      this.setState({
        loading: false,
        dataDetail: res.data.data || {}
      })
      this.getSummary();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  getCategory(){
    this.setState({
      loading: true
    })

    SummaryCategory(this.getToken())
    .then(res => {
      let FilterScreeningRisk = [{label: "All", value: ""}];
      res.data.data.map(c => {
        return FilterScreeningRisk.push({
          label: `${c.title_id} / ${c.title_en}`,
          id: c.id
        });
      });
      this.setState({
        dataCategory: FilterScreeningRisk,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  componentDidMount(){
    this.getSummary();
    this.getCategory()
    this.setState({
      list: List.getList()
    })
  }

  copyPaste(val){
    navigator.clipboard.writeText(val);
    this.handleNotif(val);
    return
  }
  handleNotif(val){
    Swal.fire({
      icon: 'success',
      title: `Address ${val} copied`,
      showConfirmButton: false,
      timer: 1500
    })
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.getSummary()
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  }

  handleDelete(){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // this.delete(id);
      }
    })
  }

  handleSelectAll = e => {
    this.setState({
      isCheckAll: !this.state.isCheckAll,
      isCheck: this.state.list.map(li => li.id),
    })
    // setIsCheckAll(!isCheckAll);
    // setIsCheck(list.map(li => li.id));

    if (this.state.isCheckAll) {
      this.setState({
        isCheck: [],
      })
    }
  };
  handleClick = e => {
    const { id, checked } = e.target;
    // setIsCheck([...isCheck, id]);
    this.setState({
      isCheck: [...this.state.isCheck, parseInt(id)],
    })
    if (!checked) {
      // setIsCheck(isCheck.filter(item => item !== id));
      this.setState({
        isCheck: this.state.isCheck.filter(item => item !== parseInt(id)),
      })
    }
  };

  limitAddressDisplay = (address, length) => {
    return address.substring(0, length) + " ... " + address.substring(address.length-length);
  }

  handleSearch = () => {
    this.setState({
      active: 1
    }, () => {
      this.getSummary()
    })
  }

  render(){
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start">
                  <input 
                    type="text" 
                    className="form-control mr-3" 
                    name="Artwork Title" 
                    placeholder="Artwork Title" 
                    value={this.state.seacrhParams} 
                    onChange={this.changeSearch} />
                  <select className="form-control font-8rem mr-3" onChange={(e) => this.setState({ CategoryStatus: e.target.value })} placeholder="roles" style={{width: '25%', height: '39px'}}>
                    {/* <option disabled selected={this.state.status === '' ? true : false}>status</option> */}
                    {this.state.dataCategory.map((e) => (
                      <option key={e.id} value={e.id} defaultValue={e.id === this.state.CategoryStatus ? true : false}>{e.label}</option>
                    ))}
                  </select>
                  <div style={{width: '25%', marginRight: '1rem'}}>
                    <SelectDate
                      changeDate={(val) => this.setState({ date: val })}
                      selected={this.state.date}
                      placeholder="Created Date"/>
                  </div>
                  <button className="btn btn-success btn-sm pr-5 pl-5 mr-3" type="button" onClick={() => this.handleSearch()}>Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table responsive style={{borderBottom: '1px solid #ebedf2'}}>
                  <thead>
                    <tr>
                      {/* <th>
                        <Checkbox
                          type="checkbox"
                          name="selectAll"
                          id="selectAll"
                          handleClick={() => this.handleSelectAll()}
                          isChecked={this.state.isCheckAll}
                        />
                      </th> */}
                      <th className="tableheh" style={{ width: '4%' }}>
                        No
                      </th>
                      <th className="tableheh">
                        Categories
                      </th>
                      <th className="tableheh" style={{ width: '31%' }}>
                        Artworks
                        {/* <button onClick={(e) => this.onSortChange('event_name')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button> */}
                      </th>
                      <th className="tableheh">
                        Created At &nbsp;
                      </th>
                      <th className="tableheh">
                        Featured
                      </th>
                      {/* <th className="tableheh">
                        Approval
                      </th> */}
                      {/* <th className="tableheh">
                        Status
                      </th> */}
                      <th className="tableheh">
                        Suspend
                      </th>
                      <th className="tableheh center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((item, index) => (
                      <tr key={index}>
                        {/* <td className="align-middle">
                          <Checkbox
                            type="checkbox"
                            name={item.name}
                            id={item.id}
                            handleClick={this.handleClick}
                            isChecked={this.state.isCheck.includes(item.id)}
                            />
                        </td> */}
                        <td className="align-middle">{index+1}</td>
                        <td className="align-middle">
                          {item.category && <>
                            {item.category.title_id}
                            <br />
                            {item.category.title_en}
                          </>}
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <ViewArtwork
                              data={`${this.state.linkImage}user/${item.id_user_creator}/artwork/${item.image_hash}.${item.image_ext}`}
                              extdata={item.image_ext}
                            />
                            <div>
                              <span className="name-user">{item.title} <span className="text-success">&nbsp;#{item.artwork_series}</span></span>
                              <br />
                              <span className="btn-action email-user" onClick={() =>  this.copyPaste(item.contract.contract_address)}>
                                <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                                <u>{this.limitAddressDisplay(item.contract.contract_address, 12)}</u>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="align-middle">{this.dateTime(item.created_at)}</td>
                        <td className="align-middle">
                          {item.featured_artwork_id ?
                            <Badge variant="success" onClick={() => this.handeleFeatureShow(item.id, 'remove')} style={{cursor: 'pointer'}}>
                              Active
                            </Badge>
                            :
                            <Badge variant="danger" onClick={() => this.handeleFeatureShow(item.id, 'add')} style={{cursor: 'pointer'}}>
                              Inactive
                            </Badge>
                          }
                        </td>
                        {/* <td className="align-middle">
                          <Badge variant="warning" onClick={() => this.handeleApproveShow()} style={{cursor: 'pointer'}}>
                            Pending
                          </Badge>
                        </td> */}
                        {/* <td className="align-middle">
                          <Badge variant="success" onClick={() => this.handeleStatusShow()} style={{cursor: 'pointer'}}>
                            Success
                          </Badge>
                        </td> */}
                        <td className="align-middle">
                          {item.is_suspended === 0 ?
                            <Badge variant="success" onClick={() => this.handeleSuspendShow(item.id,'suspend')} style={{cursor: 'pointer'}}>
                              Not Suspended
                            </Badge>
                            :
                            <Badge variant="danger" onClick={() => this.handeleSuspendShow(item.id,'unsuspend')} style={{cursor: 'pointer'}}>
                              Suspended
                            </Badge>
                          }
                        </td>
                        <td className="all-center">
                          <ButtonToolbar style={{ justifyContent: 'center'}}>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Detail Artwork
                                </Tooltip>
                              }
                            >
                              <span className="btn-action" onClick={() => this.handleShow(item.id)}>
                                <i style={{fontSize: '1rem', paddingRight: '0'}} className="far fa-eye" />
                              </span>
                            </OverlayTrigger>
                          </ButtonToolbar>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {/* <div className="d-flex justify-content-end mb-5 pb-5 pr-3">
                  <div className="align-self-center pr-3" style={{fontSize: '1rem'}}>
                    <span>You have selected 1 items</span>
                  </div>
                  <select className="form-control font-8rem mr-3" onChange={(e) => this.setState({ suspend: e.target.value })} placeholder="roles" style={{width: '20%', height: '39px'}}>
                    {suspendStatus.map((e) => (
                      <option key={e.id} value={e.id} defaultValue={e.id === this.state.suspend ? true : false}>{e.name}</option>
                    ))}
                  </select>
                  <button className="btn btn-outline-danger btn-sm pr-5 pl-5" type="button">Confirm</button>
                </div> */}
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          dialogClassName="modal-costume-width"
          centered
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton style={{padding: '0', borderBottom: '0'}}/>
          <Modal.Body className="pt-0">
            {/* <button type="button" className="close" onClick={() => this.handleClose()}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button> */}
            <div className="detail-artwork">
              <div className="detai-artwork-container-top">
                {this.state.dataDetail.artworks &&
                  <ViewArtwork
                    data={`${this.state.linkImage}user/${this.state.dataDetail.artworks?.id_user_creator}/artwork/${this.state.dataDetail.artworks?.image_hash}.${this.state.dataDetail.artworks?.image_ext}`}
                    extdata={this.state.dataDetail.artworks?.image_ext}
                    type={true}
                  />}
                <div style={{width: '100%'}}>
                  <div className="detail-artwork-nft-name">
                    <span>{this.state.dataDetail.artworks?.title}
                     {' '}
                      <span style={{color: '#17A84B'}}>{this.state.dataDetail.artworks?.artwork_series}</span>
                    </span>&nbsp;&nbsp;&nbsp;
                    {/* <Badge variant="secondary" style={{fontSize: '12px', padding: '5px 14px'}}>
                      Series 4/10
                    </Badge> */}
                  </div>
                  <div className="detail-artwork-name">
                    <span>{this.state.dataDetail.artworks?.owner?.fullname}</span>
                  </div>
                  <div className="detail-artwork-created-at">
                    <span>Created at {moment(this.state.dataDetail.created_at).format('DD MMMM YYYY')}</span>
                  </div>
                  <div className="d-flex" style={{marginBottom: '20px'}}>
                    <div className="detail-address-price">
                      <span>Contract ID: </span>&nbsp;
                    </div>
                    <span className="btn-action detail-address mr-2" onClick={() =>  this.copyPaste(this.state.dataDetail.artworks?.contract.contract_address || '0')}>
                      <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                      <u>{this.limitAddressDisplay(this.state.dataDetail.artworks?.contract.contract_address || '0', 6)}</u>
                    </span>
                    <div>
                    </div>
                    <div className="detail-address-price">
                      <span>Token ID: </span>&nbsp;
                    </div>
                    <span className="btn-action detail-address mr-2">
                      {this.state.dataDetail.artworks?.token_id}
                    </span>
                    <div>
                    </div>
                    <div className="detail-address-price">
                        <span>Price: </span>&nbsp;
                      </div>
                      <div className="detail-price">
                        <span>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'IDR' }).format(this.state.dataDetail.artworks?.price)}</span>
                      </div>
                  </div>
                  <div style={{marginBottom: '20px'}}>
                    <ButtonGroup>
                      <Button variant={!this.state.activeTab ? 'outline-success' : 'outline-secondary'} onClick={() => this.setState({activeTab: false})}>Desc (ID)</Button>
                      <Button variant={!this.state.activeTab ? 'outline-secondary' : 'outline-success'} onClick={() => this.setState({activeTab: true})}>Desc (EN)</Button>
                    </ButtonGroup>
                  </div>
                  <div className="detail-desc">
                    {this.state.activeTab ?
                      <div
                        className="desc"
                        dangerouslySetInnerHTML={{
                          __html: this.state.dataDetail.artworks?.description_id,
                        }}
                      />
                      :
                      <div
                        className="desc"
                        dangerouslySetInnerHTML={{
                          __html: this.state.dataDetail.artworks?.description_en,
                        }}
                      />
                    }
                  </div>
                </div>
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Other Series</th>
                    <th className="center">NFT Contract ID</th>
                    <th className="center">Token ID</th>
                    <th className="center">Price</th>
                    <th className="center">Current Owner</th>
                  </tr>
                </thead>
                <tbody style={{borderBottom: '1px solid #ebedf2'}}>
                  {this.state.dataDetail.other_series?.map((item, i) => (
                    <tr key={i}>
                      <td>#{item.artwork_series}</td>
                      <td className="center"><u>{this.limitAddressDisplay(item.contract.contract_address,6)}</u></td>
                      <td className="center">{item.token_id}</td>
                      <td className="text-success center"><b>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'IDR' }).format(item.price)}</b></td>
                      <td className="center"><u>{item.owner?.fullname}</u></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showFeature} onHide={this.handeleFeatureClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body style={{textAlign: 'center'}}>
            <button type="button" className="close" onClick={() => this.handeleFeatureClose()}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            <div className="d-flex">
              <div className="swal2-header mt-4">
                <ul className="swal2-progress-steps" style={{ display: 'none' }}></ul>
                <h3>Do you want to {this.state.suspendStat} the artwork from the featured artwork list? </h3>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
            <Button style={{ width: '25%', marginRight: '0.3rem'}} variant="primary" onClick={() => this.handeleFeatureClose()}>Cancel</Button>
            <Button
              style={{ width: '25%' }}
              variant="danger"
              disabled={this.state.loadingSuspend}
              className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                {
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loadingSuspend
                }
              )}`}
              onClick={() => this.approveFeature()}>Yes</Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showSuspend} onHide={this.handeleSuspendClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body style={{textAlign: 'center'}}>
            <button type="button" className="close" onClick={() => this.handeleSuspendClose()}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            <div className="d-flex">
              <div className="swal2-header mt-4">
                <ul className="swal2-progress-steps" style={{ display: 'none' }}></ul>
                <h3>Are you sure you want to change the status to {this.state.suspendStat} ? </h3>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
            <Button style={{ margin: '0.3em' }} variant="primary" onClick={() => this.handeleSuspendClose()}>Cancel</Button>
            <Button
              style={{ margin: '0.3em' }}
              variant="danger"
              disabled={this.state.loadingSuspend}
              className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                {
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loadingSuspend
                }
              )}`}
              onClick={() => this.suspend()}>Submit</Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}