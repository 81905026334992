import React, {useEffect, useState} from "react";
import moment from 'moment';
import { Link, useParams, useHistory } from "react-router-dom";
import { Button, Table, Badge, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl, limitAddressDisplay, copyPaste } from "../../../_metronic/utils/utils";
import { DetailPartner, removeNftPartner, UpdatePosisiItemPartner, UpdateType, ListNftProject } from '../../crud/feature';
import ReactLoading from 'react-loading';
import ComponentScroll from '../../component/ComponentScroll';
import Swal from 'sweetalert2';
import { DragHandle } from "../../partials/dnd/DragHandler";
import ViewArtwork from '../../component/thumbnail';
import TableNft from '../../component/FeaturedPartner/TableNft';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const PartnerDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const [listType, ] = useState({
    0: 'Single NFT',
    1: 'TokoSuprise',
    2: 'Collectibles'
  });
  const [typeNft, setType] = useState(0);
  const [listItemPartner, setListItemPartner] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [pageScroll, setPageScroll] = useState({
    page: 1,
    totalData: 20
  });


  const [projectName, setProjectName] = useState(null);
  const [searchProject, setSearchProject] = useState('');
  const [listProjects, setListProjects] = useState([]);
  const [detailListProject, setDetailListProject] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loadingProjectName, setLoadingProjectName] = useState(false);

  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalNft, setModalNft] = useState(false);
  const [valTemp, setValTemp] = useState(null); 
  const [linkImage, ] = useState(process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/');

  const getToken = () => {
    let token = localStorage.getItem("token")
    return token;
  };

  const handleAlert = (status = 'error', message = '') => {
    Swal.fire({
      icon: status,
      title: message,
      showConfirmButton: false,
      timer: 1500
    })
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
    if (result.isConfirmed) {
      return true;
    }
    return false;
  };
  const [detailPartner, setDetailPartner] = useState({
    fullname: '-',
    user_address: '0x000000000000000000',
    photo: null,
  });
  const reStrukturBlindBox = (itemData) => {
    const makeItems = [];
    itemData.forEach(element => {
      const insertedItem = element;
      insertedItem.typeData = 1;
      insertedItem.image = element.blind_box ? `${linkImage}${element.blind_box.image}` : toAbsoluteUrl("/media/users/profile.png");
      insertedItem.name = element.blind_box.name;
      insertedItem.address = element.blind_box.contract.contract_address;
      insertedItem.user_fullname = element.blind_box.creator.fullname;
      insertedItem.user_image = element.blind_box.creator.photo;
      insertedItem.user_address = element.blind_box.creator.crypto_address;
      makeItems.push(insertedItem);
    });
    return makeItems;
  };
  const reStrukturCollectibles = (itemData) => {
    const makeItems = [];
    itemData.forEach(element => {
      const insertedItem = element;
      insertedItem.image = element.image;
      insertedItem.typeData = 2;
      insertedItem.name = element.name;
      insertedItem.address = element.contract_address;
      insertedItem.user_fullname = element.owner ? element.owner.fullname : '';
      insertedItem.user_image = element.owner ? element.owner.photo : '';
      insertedItem.user_address = element.owner_address ? element.owner_address : '';
      makeItems.push(insertedItem);
    });
    return makeItems;
  };
  const reStrukturSingle = (itemData) => {
    const makeItems = [];
    itemData.forEach(element => {
      const insertedItem = element;
      insertedItem.image = `${linkImage}user/${element.artwork.creator.id}/artwork/${element.artwork.image_hash}.${element.artwork.image_ext}`;
      insertedItem.typeData = 0;
      insertedItem.image_ext = element.artwork.image_ext;
      insertedItem.name = element.artwork.name;
      insertedItem.address = element.artwork.owner_address;
      insertedItem.user_fullname = element.artwork.owner ? element.artwork.owner.fullname : '';
      insertedItem.user_image = element.artwork.owner ? element.artwork.owner.photo : '';
      insertedItem.user_address = element.artwork.owner_address ? element.artwork.owner_address : '';
      makeItems.push(insertedItem);
    });
    return makeItems;
  };
  const detailpartner = async (next = false) => {
    setLoading(true)
    try {
      const data = await DetailPartner(getToken(), id);
      const result = data.data.data;
      result.fullname = result.user.fullname ? result.user.fullname : '';
      result.photo = result.user.photo ? result.user.photo : '';
      setDetailPartner(result);
      setType(result.type);
      let callData = [];
      const itemsData = result.items;
      if (result.detail_project) {
        setSearchProject(result.detail_project.name);
        setProjectName(result.detail_project);
      }
      if (result.type === 1) {
        callData = reStrukturBlindBox(itemsData);
      } else if(result.type === 0){
        callData = reStrukturSingle(itemsData);
      } else {
        callData = reStrukturCollectibles(itemsData);
      }
      setListItemPartner(callData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  const changeType = async (val) => {
    setLoading(true)
    try {
      const data = {
        type: val,
        contract_address: detailPartner.contract_address
      };
      if (parseInt(val) === 2){
        data.contract_address = projectName === null ? null : projectName.contract_address;
      }
      await UpdateType(getToken(), id, data);
      detailpartner();
      setLoading(false);
      setValTemp(null);
      if (parseInt(val) !== 2) {
        console.log('berubah');
        setSearchProject('');
        setProjectName(null);
      }
      handleClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const removeItem = async (val) => {
    const confirmData = await handleDelete();
    if (confirmData) {
      setLoading(true);
      try {
        await removeNftPartner(getToken(), id, val);
        detailpartner();
      } catch (error) {
        console.log(error);
        console.log(error.response);
        if (error.response) {
          if (error.response.data) {
            handleAlert('error', error.response.data.message);
          }
        }
        setLoading(false)
      }
    }
  };
  const updatePosisi = async (val) => {
    setLoading(true);
    try {
      await UpdatePosisiItemPartner(getToken(), id, val);
      setTimeout(async () => {
        await detailpartner();
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const selectedProject = (project) => {
    setSearchProject(project.name);
    setModalConfirmation(true);
    setTimeout(() => {
      setProjectName(project);
    }, 100);
  };
  const typeAlert = (val) => {
    setValTemp(parseInt(val));
    setModalConfirmation(true);
  };
  const loadProject = async (next = false, page = 1, searchData = '') => {
    try {
      const data = await ListNftProject(getToken(), id, page, searchData);
      const result = data.data.data;
      setDetailListProject({
        current_page: page,
      });
      if (result.data.length === 0) {
        setHasMore(false);
      }
      if (next) {
        const combineData = [
          ...listProjects,
          ...result.data,
        ];
        setListProjects(combineData);
      } else {
        setListProjects(result.data);
      }
      setLoading(false);
      setLoadingProjectName(false);
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };
  // const searchInProject = () => {
  //   const contractData = detailPartner.contract_address;
  //   if (contractData !== null) {
  //     let resultSearch = {};
  //     console.log(listProjects, 'test projects');
  //     listProjects.forEach(element => {
  //       if (element.contract_address === contractData) {
  //         resultSearch = element;
  //       }
  //     });
  //     console.log(resultSearch, 'test search');
      // setSearchProject(resultSearch.name);
      // setTimeout(() => {
      //   setProjectName(resultSearch);
      // }, 100);
  //   }
  // };
  const handleNextProject = () => {
    if (hasMore) {
      loadProject(true, detailListProject.current_page + 1);
    }
  };
  useEffect(() => {
    if (searchProject.length > 0) {
      setLoadingProjectName(true);
      if (projectName !== null) {
        if (searchProject !== projectName.fullname) {
          setProjectName(null);
        }
      }
      setHasMore(true);
      loadProject(false, 1, searchProject);
    }
  }, [searchProject]);
  const handleClose = (close = false) => {
    setModalConfirmation(false);
    setModalNft(false);
    // if (typeNft === 2 && close) {
    //   setSearchProject('');
    //   setProjectName(null);
    // }
  };
  const closePage = () => {
    handleClose();
    detailpartner();
  };

  useEffect(() => {
    if (id) {
      detailpartner();
    }
  }, [id]);
  useEffect(() => {
    if (valTemp === 2) {
      setSearchProject('');
      setProjectName(null);
    }
  }, [valTemp]);
  return (
    <div className="head-position">
      <div className="kt-portlet body-transparent">
        <div className="kt-portlet__body pddlessForce">
          <div className="kt-portlet__preview">
            <div className="partner-side-search">
              <div className="left-partner">
                <div className="caretLeft" onClick={() => history.push('/feature-partner')} />
                <div className="partner-details">
                  <div className="frame-img-partners">
                    <img
                      alt="Logo"
                      src={detailPartner.photo ? `${linkImage}${detailPartner.photo}` : toAbsoluteUrl("/media/users/profile.png")}
                    />
                  </div>
                  <div className="align-self-center">
                    <span className="name-user">{detailPartner.fullname}</span><br />
                    <div className="frame-email">
                      <div className="copy-icon" onClick={() => copyPaste(detailPartner.user_address)} />
                      <div className="email-user">{limitAddressDisplay(detailPartner.user_address, 6)}</div>
                    </div>
                  </div>
                </div>
              </div> 
              <div className="right-partner">
                <div className="form-partner">
                  <div className="inline-form-partner">
                    <label htmlFor="selectType">
                      <span>NFT Types</span>
                      <div className="frame-form-partner">
                        <select name="select-type" id="selectType" value={valTemp !== null ? valTemp : typeNft} onChange={(e) => typeAlert(e.target.value)}>
                          {Object.keys(listType).map(((type) => (
                            <option key={type} value={type}>{listType[type]}</option>
                          )))}
                        </select>
                      </div>
                    </label>
                  </div>
                  {valTemp === 2 || typeNft === 2 ? (
                    <div className="inline-form-partner ml-2">
                      <label htmlFor="selectType">
                        <span>Project Name</span>
                        <div className="frame-form-partner form-input-projects form-search">
                          <input type="text" value={searchProject} onChange={(e) => setSearchProject(e.target.value)} />
                          {searchProject.length > 0 && projectName === null ? (
                            <ComponentScroll classPage="floating-select" triggerCheck={handleNextProject}>
                              <>
                                {listProjects.length === 0 && !loadingProjectName ? (
                                  <div className="item-data text-center">
                                    Empty Data
                                  </div>
                                ): null}
                                {loadingProjectName ? (
                                  <div className="item-data text-center">
                                    Loading Data...
                                  </div>
                                ): (
                                  <>
                                    {listProjects.map((project, t) => (
                                      <div className="item-data" onClick={() => selectedProject(project)} key={t}>
                                        {project.name}
                                      </div>
                                    ))}
                                  </>
                                )}
                              </>
                            </ComponentScroll>
                          ) : null}
                        </div>
                      </label>
                    </div>
                  ) : null}
                  {valTemp === 2 || typeNft === 2 ? (
                    <>
                      {projectName !== null && (
                        <div className="frame-button">
                          <OverlayTrigger
                            placement='bottom'
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                Add Item
                              </Tooltip>
                            }
                          >
                            <button className="btn btn-success" type="button" onClick={() => setModalNft(true)}>
                              <span className="fas fa-plus"></span>
                            </button>
                          </OverlayTrigger>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="frame-button">
                      <OverlayTrigger
                        placement='bottom'
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            Add Item
                          </Tooltip>
                        }
                      >
                        <button className="btn btn-success" type="button" onClick={() => setModalNft(true)}>
                          <span className="fas fa-plus"></span>
                        </button>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
      <div className="kt-portlet height-content">
        <div className="kt-portlet__body pddlessForce">
          <div className="kt-portlet__preview h-100">
            <Table striped responsive>
              <thead>
                <tr>
                  <th className="tableheh" style={{ width: '5%' }}>
                    <div className="p-left" style={{color: '#000000'}}>
                      SN
                    </div>
                  </th>
                  <th className="tableheh" style={{ width: '20%' }}>
                    NFT
                  </th>
                  <th className="tableheh" style={{ width: '15%' }}>
                    Owner
                  </th>
                  <th className="tableheh" style={{width: '10%'}}>Action</th>
                </tr>
              </thead>
              {listItemPartner.length === 0 && !loading && (
                <tbody>
                  <tr>
                    <td className="center" colSpan="4">
                      Empty Data
                    </td>
                  </tr>
                </tbody>
              )}
              <DragDropContext
                onDragEnd={(param) => {
                  const srcI = param.source.index;
                  const desI = param.destination?.index;
                  listItemPartner.splice(desI, 0, listItemPartner.splice(srcI, 1)[0]);
                  let dataPosition = [];
                  listItemPartner.forEach((element, index) => 
                    dataPosition.push({'id': element.id, 'position': index+1}),
                  );
                  let data = {
                    'data' : dataPosition
                  };
                  updatePosisi(data);
                }}
              >
                {loading ? (
                  <tbody>
                    <tr>
                      <td className="center" colSpan="4"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                    </tr>
                  </tbody>
                ) : (
                  <Droppable droppableId="droppable-1">
                    {(provided, _) => (
                      <tbody
                        className="content-tbody"
                        ref={provided.innerRef}
                        {...provided.droppableProps}>
                        {listItemPartner.map((item, i) => (
                          <Draggable
                            key={item.id}
                            draggableId={"draggable-" + item.id}
                            index={i}
                          >
                            {(provided, snapshot) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  // boxShadow: snapshot.isDragging
                                  //   ? "0 0 .4rem #666"
                                  //   : "none",
                                  // left: snapshot.isDragging ? 0 : "none",
                                  // color: snapshot.isDragging ? "#ffffff": "none",
                                  // top: snapshot.isDragging ? 900 : "none"
                                }}
                              >
                                <td className="align-middle">
                                  <div className="p-left-2" style={{color: '#000000'}}>
                                    {i+1}
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex ">
                                    <>
                                    <>
                                      {item.typeData === 0 ? (
                                        <ViewArtwork
                                          data={item.image}
                                          extdata={item.image_ext}
                                        />
                                      ) : (
                                        <img
                                          alt="Logo"
                                          src={item.image}
                                          className="img-list-data img-partner-user"
                                        />
                                      )}
                                    </>
                                      <div className="align-self-center">
                                        <span className="name-user">
                                          {item.name ? item.name : '-'}
                                          {item.artwork && (
                                            <span className="green-series">
                                              #{item.artwork.artwork_series}
                                            </span>
                                          )}
                                        </span><br />
                                        <div className="frame-email">
                                          <span className="copy-icon" onClick={() => copyPaste(item.address)} />
                                          <span className="email-user">{limitAddressDisplay(item.address, 6)}</span>
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                </td>
                                <td>
                                  <div className={`d-flex ${typeNft === 0 ? 'mt-3' : ''}`}>
                                    <div className="align-self-center">
                                      <span className="name-user">{item.user_fullname ? item.user_fullname : '-'}</span><br />
                                      <div className="frame-email">
                                        <span className="copy-icon" onClick={() => copyPaste(item.user_address)} />
                                        <span className="email-user">{limitAddressDisplay(item.user_address, 6)}</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="content-action">
                                  <DragHandle {...provided.dragHandleProps} />
                                  <span style={{ marginLeft: '0' }}>|</span>
                                  {/* <span>|</span> */}
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={
                                      <Tooltip id={`tooltip-top`}>
                                        Remove Item
                                      </Tooltip>
                                    }
                                  >
                                    <span className="btn-action" onClick={() => removeItem(item.id)}>
                                      <div className="removeMedia" />
                                    </span>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                )}
              </DragDropContext>
            </Table>
          </div>
        </div>
      </div>
      <Modal
          show={modalConfirmation}
          onHide={() => handleClose()}
          // dialogClassName="modal-costume-width"
          centered
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body className="p-0">
            <div className="modal-featured-header">
              <div />
              <div className="closeIcon" onClick={() => handleClose(true)}/>
            </div>
            <div className="modal-featured-content">
              <div className="content-modal-featured">
                <h4>
                  Once you changed the NFT type, all NFTs that you’ve choosen below will be cleared up
                </h4>
                <div className="button-featured-group">
                 <button type="button" className="btn btn-cancel" onClick={() => handleClose(true)}>
                  Cancel
                 </button>
                 <button type="button" className="btn btn-okay" onClick={() => changeType(valTemp === null ? typeNft : valTemp)}>
                  Okay
                 </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      <Modal
          show={modalNft}
          onHide={() => handleClose()}
          dialogClassName="modal-costume-width-partner"
          centered
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body className="p-0 modal-featured-table">
            <div className="modal-featured-header">
              <div />
              <div className="closeIcon-white" onClick={() => handleClose(true)}/>
            </div>
            <div className="modal-featured-content">
              <div className="partner-side-search">
                <div className="left-partner">
                  <div className="partner-details">
                    <div className="frame-img-partners">
                      <img
                        alt="Logo"
                        src={detailPartner.photo ? `${linkImage}${detailPartner.photo}` : toAbsoluteUrl("/media/users/profile.png")}
                      />
                    </div>
                    <div className="align-self-center">
                      <span className="name-user">{detailPartner.fullname}</span><br />
                      <div className="frame-email">
                        <div className="copy-icon" onClick={() => copyPaste(detailPartner.user_address)} />
                        <div className="email-user">{limitAddressDisplay(detailPartner.user_address, 6)}</div>
                      </div>
                    </div>
                  </div>
                </div> 
                <div className="right-partner">
                  <div className="form-partner">
                    <div className="inline-form-partner">
                      {projectName !== null ? (
                        <label htmlFor="selectType">
                          <span>Project Name</span>
                          <div className="frame-form-partner disabled-item">
                            <select name="select-type" id="selectType" disabled value={1}>
                              <option value={1}>{projectName.name}</option>
                            </select>
                          </div>
                        </label>
                      ) : (
                        <label htmlFor="selectType">
                          <span>NFT Types</span>
                          <div className="frame-form-partner disabled-item">
                            <select name="select-type" id="selectType" disabled value={typeNft}>
                              {Object.keys(listType).map(((type) => (
                                <option key={type} value={type}>{listType[type]}</option>
                              )))}
                            </select>
                          </div>
                        </label>
                      )}
                    </div>
                  </div>
                </div> 
              </div>
              <TableNft id={id} type={typeNft} trigger={() => closePage()} />
            </div>
          </Modal.Body>
        </Modal>
    </div>
  );
};

export default PartnerDetail;