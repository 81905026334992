import React, {useEffect, useState} from "react";
import moment from 'moment';
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import {Table} from "react-bootstrap";
import { SummaryMerchant, UpdateMerchant } from '../../crud/feature';
import { DragHandle } from "../../partials/dnd/DragHandler";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Merchant = () => {
  const [listArtwork, setListArtwork] = useState([]);
  const [loading, setLoading] = useState(false);
  const [linkImage, setLinkImage] = useState(process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/');

  const getToken = () => {
    let token = localStorage.getItem("token")
    return token;
  }

  const dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).format('DD-MM-YY hh:m A');

    return fmtDate
  }

  const getFeatureArtwork = async () => {
    setLoading(true)
    try {
      const data = await SummaryMerchant(getToken());
      setListArtwork(data.data.data)
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  useEffect(() => {
    getFeatureArtwork();
  }, []);

  const updateFeatureArtwork = async (val) => {
    setLoading(true)
    try {
      await UpdateMerchant(getToken(), val);
      getFeatureArtwork();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="head" style={{fontSize: '10px'}}>
      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-portlet__preview">
            <div className="d-flex">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/illustrations/example.png")}
                style={{width: '50px', height: '50px', marginLeft: '30px', marginRight: '50px'}}
              />
              <div className="desc-dnd">
                <span>
                  These data can be added here by set the status on the artworks modules as featured artworks. You can drag and drop to set the position of the data on the website.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="kt-portlet">
        <div className="kt-portlet__body pddlessForce">
          <div className="kt-portlet__preview">
            <Table striped responsive>
              <thead>
                <tr>
                  <th className="tableheh" style={{ width: '4%' }}>
                    Position
                  </th>
                  <th className="tableheh" style={{ width: '56%' }}>
                    Merchant
                    {/* <button onClick={(e) => this.onSortChange('event_name')} className="sort-button">
                      <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                    </button> */}
                  </th>
                  <th className="tableheh">
                    Created At &nbsp;
                  </th>
                  <th className="center tableheh" style={{width: '10%'}}>Action</th>
                </tr>
              </thead>
              <DragDropContext
                onDragEnd={(param) => {
                  const srcI = param.source.index;
                  const desI = param.destination?.index;
                  if (desI) {
                    listArtwork.splice(desI, 0, listArtwork.splice(srcI, 1)[0]);
                    let dataPosition = [];
                    listArtwork.forEach((element, index) => 
                      dataPosition.push({'id': element.id, 'position': index+1}),
                    );
                    let data = {
                      'data' : dataPosition
                    }
                    updateFeatureArtwork(data);
                  }
                }}
              >
                <Droppable droppableId="droppable-1">
                  {(provided, _) => (
                    <tbody
                      ref={provided.innerRef}
                      {...provided.droppableProps}>
                      {listArtwork.map((item, i) => (
                        <Draggable
                          key={item.id}
                          draggableId={"draggable-" + item.id}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                // boxShadow: snapshot.isDragging
                                //   ? "0 0 .4rem #666"
                                //   : "none",
                                // left: snapshot.isDragging ? 0 : "none",
                                // color: snapshot.isDragging ? "#ffffff": "none",
                                // top: snapshot.isDragging ? 900 : "none"
                              }}
                            >
                              <td className="align-middle" style={{color: '#000000'}}>{i+1}</td>
                              <td>
                                <div className="d-flex ">
                                  <img
                                    alt="Logo"
                                    src={item.portal_user.photo ? `${linkImage}${item.portal_user.photo}` : toAbsoluteUrl("/media/users/profile.png")}
                                    className="img-list-data"
                                  />
                                  <div className="align-self-center">
                                    <span className="name-user">{item.portal_user?.fullname}</span><br />
                                    <span className="email-user">{item.portal_user?.merchant_type === 0 ? 'Individual' : 'Coorporate'}</span>
                                  </div>
                                </div>
                              </td>
                              <td className="align-middle">{dateTime(item.created_at)}</td>
                              <td className="all-center">
                                <DragHandle {...provided.dragHandleProps} />
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Merchant;