import React, { useState, useEffect, useRef } from 'react';
import { AvailableSetNft, ListNftPartner, insertNftPartner } from '../../crud/feature';
import { toAbsoluteUrl, limitAddressDisplay, copyPaste, handleAlert } from "../../../_metronic/utils/utils";
import ViewArtwork from '../thumbnail';
import { useChecklist } from 'react-checklist';
import ComponentScroll from '../ComponentScroll';
import './table.scss';

const TableNft = ({ id, type, trigger = () => {} }) => {
  const content = useRef(null);  
  const [linkImage, ] = useState(process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [available, setAvailable] = useState(0);
  const [nftData, setNftData] = useState([]);
  const [detailPage, setDetailPage] = useState({
    current_page: 1,
  });
  const [hasMore, setHasMore] = useState(true);
  const getToken = () => {
    let token = localStorage.getItem("token")
    return token;
  };
  const checkAvailableNft = async () => {
    const data = await AvailableSetNft(getToken(), id);
    setAvailable(data.data.data.max_nft - data.data.data.current_featured_merchant_NFTs);
  };
  const reCreateArray = (arrayData) => {
    let array = [];
    for (let i = 0; i < arrayData.length; i++) {
      const element = arrayData[i];
      element.token_id = parseInt(element.token_id);
      array.push(element);
    }
    return array;
  };
  const loadData = async (next = false, page = 1, searchData = '') => {
    try {
      const data = await ListNftPartner(getToken(), id, page, searchData);
      const result = data.data.data;
      setDetailPage({
        current_page: page,
      });
      if (result.data.length === 0) {
        setHasMore(false);
      }
      const makeData = reCreateArray(result.data);
      if (next) {
        const combineData = [
          ...nftData,
          ...makeData,
        ];
        setNftData(combineData);
      } else {
        setNftData(makeData);
      }
      setLoading(false);
      setLoadingSearch(false);
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };
  useEffect(() => {
    if (id) {
      loadData();
      checkAvailableNft();
    }
  }, [id]);
  const addCommas = (x) => {
    if(x){
      const checkNumber = x.toFixed(6).toString().split('.');
      let numberCommas = '';
      if (checkNumber[1] === '000000') {
        numberCommas = checkNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        numberCommas = `${checkNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${checkNumber[1]}`;
      }
      return numberCommas;
    }else{
      return "0"
    }
  };
  const handleNext = () => {
    if (hasMore) {
      setLoadingSearch(true);
      loadData(true, detailPage.current_page + 1, search);
    }
  };
  const { handleCheck, isCheckedAll, checkedItems, setCheckedItems } = useChecklist(nftData, {
    key: type === 2 ? 'token_id' : 'id',
    keyType: 'number',
  });
  const handleReset = () => setCheckedItems(new Set());
  const delay = (fn, ms) => {
    let timer = 0
    return function(...args) {
      clearTimeout(timer)
      timer = setTimeout(fn.bind(this, ...args), ms || 0)
    }
  }
  useEffect(() => {
    setLoading(true);
    if(search.length > 0){
      loadData(false, 1, search);
      handleReset();
    }
  }, [search]);
  
  const handleSubmit = async () => {
    setLoading(true);
    try {
      if ([...checkedItems].length > available){
        handleAlert("error", "You can only select " + available + " items");
        setLoading(false);
        return;
      }
      const makeData = [];
      checkedItems.forEach(element => {
        makeData.push(element);
      });
      const resData = {
        datas: makeData
      };
      await insertNftPartner(getToken(), id, resData);
      trigger();
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };
  return (
    <div className="frame-table-nft">
      <div className="frame-header-table">
        <div className="searching-nft">
          <div className="searchData" />
          <input type="text" placeholder="Search by NFT Title & Contract Address" value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
        <div className={`search-info ${[...checkedItems].length > available ? 'search-danger' : ''}`}>
          <div className={`infoIcon`} />
          <span>You can only choose {available} available NFT below</span>
        </div>
        {loadingSearch && (
          <div className={`search-info loading-page`}>
            <span>Loading data ...</span>
          </div>
        )}
      </div>
      <ComponentScroll classPage="size-table" triggerCheck={handleNext}>
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="inputTable" style={{ width: '5%' }}>
                <label htmlFor={`checkedAll`}>
                  {isCheckedAll ? (
                    <div className="iconCheck green-check-Icon"></div>
                  ) : (
                    <div className="iconEmptyCheck gray-check-Icon"></div>
                  )}
                </label>
                <input 
                  className="checkboxPartner"
                  id={`checkedAll`}
                  onChange={handleCheck}
                  checked={isCheckedAll}
                  type="checkbox"
                 />
              </th>
              <th>NFT</th>
              <th>Details</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={4} className="text-center">
                  <b>Load Data ...</b>
                </td>
              </tr>
            ) : (
              <>
                {nftData.length === 0 && (
                  <tr>
                    <td colSpan={4} className="text-center">
                      <b>Empty Data</b>
                    </td>
                  </tr>
                )}
                {nftData.map((item, key) => (
                  <tr key={`${key}_${type === 2 ? parseInt(item.token_id) : item.id}`}>
                    <td className="inputTable" style={{ width: '5%' }}>
                      <label htmlFor={`id-${key}_${type === 2 ? parseInt(item.token_id) : item.id}`}>
                        {checkedItems.has(type === 2 ? parseInt(item.token_id) : item.id) ? (
                          <div className="iconCheck green-check-Icon"></div>
                        ) : (
                          <div className="iconEmptyCheck gray-check-Icon"></div>
                        )}
                      </label>
                      <input 
                        id={`id-${key}_${type === 2 ? parseInt(item.token_id) : item.id}`}
                        type="checkbox"
                        className="checkboxPartner"
                        data-key={type === 2 ? parseInt(item.token_id) : item.id}                  
                        onChange={handleCheck}            
                        checked={checkedItems.has(type === 2 ? parseInt(item.token_id) : item.id)} 
                      />
                    </td>
                    <td>
                      {type === 2 ? (
                        <img
                          alt="Logo"
                          src={item.image ? `${item.image}` : toAbsoluteUrl("/media/users/profile.png")}
                          className="img-list-data img-partner-user"
                        />
                      ) : (
                        <>
                          {type === 0 ? (
                            <ViewArtwork
                              data={`${linkImage}user/${item.creator ? item.creator.id : ''}/artwork/${item.image_hash}.${item.image_ext}`}
                              extdata={item.image_ext}
                            />
                          ) : (
                            <img
                              alt="Logo"
                              src={item.image ? `${linkImage}${item.image}` : toAbsoluteUrl("/media/users/profile.png")}
                              className="img-list-data img-partner-user"
                            />
                          )}
                        </>
                      )}
                    </td>
                    <td>
                      <div className="align-self-center detail-table">
                        <span className="name-user">
                          {item.name}
                          {item.artwork_series && (
                            <span className="green-series">
                               #{item.artwork_series}
                            </span>
                          )}
                        </span><br />
                        <div className="frame-email d-flex">
                          <div className="copy-icon" onClick={() => copyPaste(
                            item.contract ? 
                            item.contract.contract_address 
                            : 
                            item.contract_address ? item.contract_address : '-'
                          )} />
                          <div className="email-user">
                            {item.contract ? 
                            limitAddressDisplay(item.contract.contract_address, 6) 
                            : 
                            item.contract_address ? limitAddressDisplay(item.contract_address, 6) : '-'}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="price-table-nft">
                      {addCommas(item.price)} IDR
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </ComponentScroll>
      <div className="frame-footer-table">
        <div className="content-footer-table">
          <button type="button" className="btn btn-success" onClick={handleSubmit}>
            Save Item
          </button>
        </div>
      </div>
    </div>
  );
};
export default TableNft;