import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import moment from 'moment';
import { Table, Badge, OverlayTrigger, ButtonToolbar, Tooltip } from "react-bootstrap";
import ReactLoading from 'react-loading';
import Pagination from "react-js-pagination";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import { SummaryBanners, DeleteBanner, UpdateStatusBanner } from '../../crud/banner';
import SelectDate from '../../component/datetime';
require("bootstrap/scss/bootstrap.scss");

const Banner = () => {
  const history =  createBrowserHistory();
  const linkImage = useState(process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/')
  const [isLoading, setIsLoading] = useState(false);
  const [dataBanners, setDataBanners] = useState([]);
  const [bannerType, setBannerType] = useState('');
  const [seacrhParam, setSeacrhParam] = useState('');
  const [dateParam, setDateParam] = useState(null);
  const [detailPage, setDetailPage] = useState({
    current_page: 1,
    per_page: 15,
    total_data: 0,
  });

  const BannerTypes = [
    {
      id: "",
      name: "All Type Banners",
    },
    {
      id: "0",
      name: "Hero Section",
    },
    {
      id: "1",
      name: "Our NFT",
    },
  ];

  const ErrorFunction = (erorr) => {
    console.log(erorr);
    setIsLoading(false);
    if(erorr.response){
      if(erorr.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${erorr.response.data.code} message: ${erorr.response.data.message}`);
      }
    }
  };

  const getSummaryBanners = (page = 1) => {
    setIsLoading(true);
    const search = seacrhParam !== '' ? `&title=${seacrhParam}` : '';
    const date = dateParam ? `&created_date=${moment(dateParam).format('YYYY-MM-DD')}` : '';
    const typeParam = bannerType === '' ? '' : `&type=${bannerType}`;
    const params = `?page=${page}&limit=${detailPage.per_page}${search}${date}${typeParam}`;
    
    SummaryBanners(params)
    .then(responseBanner => {
      setDataBanners(responseBanner.data.data.data);
      setDetailPage({
        current_page: responseBanner.data.data.current_page,
        per_page: responseBanner.data.data.per_page,
        total_data: responseBanner.data.data.total_data,
      });
      setIsLoading(false);
    })
    .catch(e => {
      ErrorFunction(e);
    })
  };

  useEffect(() => {
    getSummaryBanners(1);
  }, []);

  const updateStatusBanner = (id) => {
    setIsLoading(false);
    UpdateStatusBanner(id)
    .then(responseDeleteBanner => {
      if (responseDeleteBanner.data.code === 200) {
        toast.success('Update is success');
        getSummaryBanners(detailPage.current_page);
      }
      setIsLoading(false);
    })
    .catch(e => {
      ErrorFunction(e);
    })
  }
  const deleteBanner = (id) => {
    setIsLoading(false);
    DeleteBanner(id)
    .then(responseDeleteBanner => {
      if (responseDeleteBanner.data.code === 200) {
        toast.success('Delete is success');
        getSummaryBanners(detailPage.current_page);
      }
      setIsLoading(false);
    })
    .catch(e => {
      ErrorFunction(e);
    })
  }
  const BannerConfirmation = (id, type = 'delete') => {
    Swal.fire({
      title: 'Are you sure?',
      text: type === 'delete' ? `You won't be able to revert this!` : `You wan't to change the status ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: type === 'delete' ? 'Yes, delete it!' : 'Yes, Continue'
    }).then((result) => {
      if (result.isConfirmed) {
        if (type === 'delete') {
          deleteBanner(id);
        } else {
          updateStatusBanner(id);
        }
      }
    })
  }

  
  const historyEdit = (id) => {
    history.push("/banner-management/"+id+"/edit-banner");
    window.location.reload();
  }
  const handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    setDetailPage({
      current_page: activePage,
      per_page: detailPage.per_page,
      total_data: detailPage.total_data,
    })
    getSummaryBanners(activePage);
  }

  return (
    <div className="head" style={{fontSize: '10px'}}>
      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-portlet__preview d-flex justify-content-start">
            <input 
              type="text" 
              className="form-control mr-3" 
              name="search" 
              placeholder="Banner Title" 
              value={seacrhParam} 
              onChange={(event) =>setSeacrhParam(event.target.value)}
            />
            <div style={{width: '30%', marginRight: '1rem'}}>
              <SelectDate
                changeDate={(val) => setDateParam(val)}
                selected={dateParam}
                placeholder="Created Date"/>
            </div>
            <select className="form-control font-8rem mr-3" onChange={(e) => setBannerType(e.target.value)} placeholder="roles" style={{width: '30%', height: '39px'}}>
              {BannerTypes.map((e) => (
                <option key={e.id} value={e.id} defaultValue={e.id === bannerType}>{e.name}</option>
              ))}
            </select>
            <button className="btn btn-success btn-sm pr-5 pl-5 mr-3" type="button" onClick={() =>  getSummaryBanners(1)}>Search</button>   
            <Link to="/banner-management/add-banner" style={{width: '30%'}}>
              <button className="btn btn-primary btn-sm mr-3" type="button" style={{width: '100%', padding: '10px'}}>Add new Banner</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="kt-portlet">
        <div className="kt-portlet__body pddlessForce">
          <div className="kt-portlet__preview">
            <div className="kt-section">
              <Table responsive style={{borderBottom: '1px solid #ebedf2'}}>
                <thead>
                  <tr>
                    <th className="tableheh" style={{ width: '4%' }}>
                      No
                    </th>
                    <th className="tableheh">
                      Banner
                    </th>
                    <th className="tableheh">
                      Title
                    </th>
                    <th className="tableheh">
                      type
                    </th>
                    <th className="tableheh">
                      Sorting No
                    </th>
                    <th className="tableheh">
                      Created At
                    </th>
                    <th className="tableheh">
                      Status
                    </th>
                    <th className="tableheh center">
                      Action
                    </th>
                  </tr>
                </thead>
                {isLoading ? 
                  <tbody>
                    <tr>
                      <td className="center" colSpan="8"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                    </tr>
                  </tbody> :
                  <tbody>
                    {dataBanners.length > 0 && dataBanners.map((itemBanner, index) => (
                      <tr key={itemBanner.id}>
                        <td className="align-middle">{index+1}</td>
                        <td className="align-middle">
                          <div style={{width: '90px', height: '96px'}}>
                            <img style={{width: '100%', height: '100%'}} src={linkImage[0]+itemBanner.image} alt="" />
                          </div>
                        </td>
                        <td className="align-middle">
                          <span>{itemBanner.title}</span>
                        </td>
                        <td className="align-middle">
                          <span>{itemBanner.type === 0 ? 'Hero Section' : 'Our NFT'}</span>
                        </td>
                        <td className="align-middle">
                          <span>{itemBanner.order}</span>
                        </td>
                        <td className="align-middle">
                          <span>{moment(itemBanner.created_at).format('DD MMMM YYYY')}</span>
                        </td>
                        <td className="align-middle ">
                          <div className="btn-action" onClick={() => BannerConfirmation(itemBanner.id, 'update')}>
                            {itemBanner.status === 0 ?
                              <Badge variant="success">
                                Enable
                              </Badge>
                              :
                              <Badge variant="danger">
                                Disabled
                              </Badge>
                            }
                          </div>
                        </td>
                        <td className="all-center">
                          <ButtonToolbar style={{ justifyContent: 'center'}}>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Update Banner
                                </Tooltip>
                              }
                            >
                              <span className="btn-action" onClick={() => historyEdit(itemBanner.id)}>
                                <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-pencil-alt text-info" />
                              </span>
                            </OverlayTrigger>
                            &nbsp; | &nbsp;
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Delete Banner
                                </Tooltip>
                              }
                            >
                              <span className="btn-action" onClick={() => BannerConfirmation(itemBanner.id, 'delete')}>
                                <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-trash-alt text-danger" />
                              </span>
                            </OverlayTrigger>
                          </ButtonToolbar>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                }
              </Table>
              <div className="d-flex justify-content-center align-items-center pagination-data">
                <Pagination
                  activePage={detailPage.current_page}
                  itemsCountPerPage={detailPage.per_page}
                  itemClass="page-item"
                  linkClass="page-link"
                  totalItemsCount={detailPage.total_data}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Banner;