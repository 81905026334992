import React from 'react';
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import Swal from 'sweetalert2';
import moment from 'moment';
import clsx from "clsx";
import { Button, Table, Badge, Modal, OverlayTrigger, ButtonToolbar, Tooltip } from "react-bootstrap";
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import SelectDate from '../../component/datetime';
// import Checkbox from '../../component/checkbox'
import Pagination from "react-js-pagination";
import { Detail, Summary, UpdateSuspend, UpdateFeature, UpdateConfirm } from '../../crud/nft';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class Merchant extends React.Component {
  historyEdit(id) {
    history.push("/leaderboard/"+id+"/editEvent");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      linkImage: process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/',
      show: false,
      showStatus: false,
      showFeature: false,
      showApprove: false,
      loadingSuspend: false,
      loading: false,
      totalData: 0,
      data:[],
      dataDetail: {},
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderSort: 'asc',
      active: 1,
      params: '',
      date: null,
      isCheckAll: false,
      isCheck: [],
      list: [],
      status: '',
      suspend: '',
      suspendStatus: '',
      rejectTrue: false,
      rejectReason: '',
      idChange: 0,
      suspendStat: '',
      rejectNote: '',
      rejectState: ''
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handeleStatusShow = this.handeleStatusShow.bind(this);
    this.handeleStatusClose = this.handeleStatusClose.bind(this);
    this.handeleFeatureShow = this.handeleFeatureShow.bind(this);
    this.handeleFeatureClose = this.handeleFeatureClose.bind(this);
    this.handeleApproveShow = this.handeleApproveShow.bind(this);
    this.handeleApproveClose = this.handeleApproveClose.bind(this);
    this.handeleSuspendShow = this.handeleSuspendShow.bind(this);
    this.handeleSuspendClose = this.handeleSuspendClose.bind(this);
  }

  handleShow(id) {
    this.setState({ show: true});
    this.getDetail(id);
  }
  handleClose() {
    this.setState({ show: false });
  }

  handeleStatusShow() {
    this.setState({ showStatus: true});
  }
  handeleStatusClose() {
    this.setState({ showStatus: false });
  }

  handeleFeatureShow(id, val) {
    this.setState({ showFeature: true, idChange: id, suspendStat: val});
  }
  handeleFeatureClose() {
    this.setState({ showFeature: false });
  }

  handeleApproveShow(id) {
    this.setState({ showApprove: true, idChange: id,});
  }
  handeleApproveClose() {
    this.setState({ showApprove: false, rejectTrue: false, rejectReason: '', rejectState: '' });
  }

  handeleSuspendShow(id, val) {
    this.setState({ showSuspend: true, idChange: id, suspendStat: val});
  }
  handeleSuspendClose() {
    this.setState({ showSuspend: false });
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.getSummary(activePage)
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getSummary(pages = 1) {
    this.setState({
      loading: true
    })

    let search = ''
    if(this.state.seacrhParams !== ''){
      search=`&q=${this.state.seacrhParams}`
    }

    let filterDate = ''
    if(this.state.date){
      filterDate=`&date=${moment(this.state.date).format('YYYY-MM-DD')}`
    }

    let status_type = ''
    if(this.state.status !== ''){
      status_type=`&status=${this.state.status}`
    }

    let status_suspend = ''
    if(this.state.suspendStatus !== ''){
      status_suspend=`&suspended=${this.state.suspendStatus}`
    }

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?type=3&order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&page=${pages}&limit=${this.state.postPerPage}${filterDate}${search}${status_type}${status_suspend}`
    }else{
      params = `?type=3&order_sort=${this.state.orderSort}&page=${pages}&limit=${this.state.postPerPage}${filterDate}${search}${status_type}${status_suspend}`
    }

    Summary(this.getToken(), params)
    .then(res => {
      this.setState({
        totalData: res.data.data.total_data,
        data: res.data.data.data || [],
        postPerPage: res.data.data.per_page,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        data:[],
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  getDetail(id) {
    this.setState({
      loading: true
    })

    Detail(this.getToken(), id)
    .then(res => {
      this.setState({
        dataDetail: res.data.data || {},
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        dataDetail: {},
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  suspend() {
    this.setState({
      loadingSuspend: true
    })

    let data = {
        "is_suspended": this.state.suspendStat
    }

    UpdateSuspend(this.getToken(), this.state.idChange, data)
    .then(res => {
      this.setState({
        showSuspend: false,
        idChange: 0,
        suspendStat: '',
        loadingSuspend: false,
      })
      this.getSummary(this.state.active);
    })
    .catch(e => {
      console.log(e);
      this.setState({
        showSuspend: false,
        loadingSuspend: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  feature() {
    this.setState({
      loadingSuspend: true
    })

    let data = {
        "id_user": this.state.idChange
    }

    UpdateFeature(this.getToken(), data)
    .then(res => {
      this.setState({
        showFeature: false,
        suspendStat: '',
        idChange: 0,
        loadingSuspend: false,
      })
      this.getSummary(this.state.active);
    })
    .catch(e => {
      console.log(e);
      this.setState({
        showFeature: false,
        suspendStat: '',
        idChange: 0,
        loadingSuspend: false,
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  approve(val) {
    this.setState({
      loadingSuspend: true
    })

    let data = {
        "status": val,
        "reject_note": this.state.rejectReason,
    }
    
    UpdateConfirm(this.getToken(), this.state.idChange, data)
    .then(res => {
      this.setState({
        loadingSuspend: false,
      })
      this.handeleApproveClose();
      this.getSummary(this.state.active);
    })
    .catch(e => {
      console.log(e);
      this.setState({
        showFeature: false,
        suspendStat: '',
        idChange: 0,
        loadingSuspend: false,
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }
  
  componentDidMount(){
    this.getSummary(this.state.active);
  }

  getFile(val){
    console.log('aye ayee :', val);
  }

  copyPaste(val){
    navigator.clipboard.writeText(val);
    this.handleNotif(val);
    return
  }
  handleNotif(val){
    Swal.fire({
      icon: 'success',
      title: `Address ${val} copied`,
      showConfirmButton: false,
      timer: 1500
    })
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.getSummary(this.state.active);
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  }

  handleDelete(){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // this.delete(id);
      }
    })
  }

  handleSelectAll = e => {
    this.setState({
      isCheckAll: !this.state.isCheckAll,
      isCheck: this.state.list.map(li => li.id),
    })
    // setIsCheckAll(!isCheckAll);
    // setIsCheck(list.map(li => li.id));

    if (this.state.isCheckAll) {
      this.setState({
        isCheck: [],
      })
    }
  };
  handleClick = e => {
    const { id, checked } = e.target;
    // setIsCheck([...isCheck, id]);
    this.setState({
      isCheck: [...this.state.isCheck, parseInt(id)],
    })
    if (!checked) {
      // setIsCheck(isCheck.filter(item => item !== id));
      this.setState({
        isCheck: this.state.isCheck.filter(item => item !== parseInt(id)),
      })
    }
  };
  searchFunction = () => {
    this.getSummary(1);
    this.setState({ active: 1 });
  }

  render(){
    const statusType = [
      {
        id: "",
        name: "All Status",
      },
      {
        id: "0",
        name: "Pending",
      },
      {
        id: "1",
        name: "Approve",
      },
      {
        id: "-1",
        name: "Resubmit",
      },
      {
        id: "-2",
        name: "Rejected",
      }
    ];
    const suspendType = [
      {
        id: "",
        name: "All Status",
      },
      {
        id: "0",
        name: "Not Suspended",
      },
      {
        id: "1",
        name: "Suspended",
      },
    ];
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start">
                  <input 
                    type="text" 
                    className="form-control mr-3" 
                    name="search" 
                    placeholder="Name, Email, Address" 
                    value={this.state.seacrhParams} 
                    onChange={this.changeSearch} />
                  <div style={{width: '25%', marginRight: '1rem'}}>
                    <SelectDate
                      changeDate={(val) => this.setState({ date: val })}
                      selected={this.state.date}
                      placeholder="Created Date"/>
                  </div>
                  <select className="form-control font-8rem mr-3" onChange={(e) => this.setState({ status: e.target.value })} placeholder="status" style={{width: '20%', height: '39px'}}>
                    {/* <option disabled selected={this.state.status === '' ? true : false}>status</option> */}
                    {statusType.map((e) => (
                      <option key={e.id} value={e.id} defaultValue={e.id === this.state.status ? true : false}>{e.name}</option>
                    ))}
                  </select>
                  <select className="form-control font-8rem mr-3" onChange={(e) => this.setState({ suspendStatus: e.target.value })} placeholder="suspend" style={{width: '20%', height: '39px'}}>
                    {/* <option disabled selected={this.state.status === '' ? true : false}>status</option> */}
                    {suspendType.map((e) => (
                      <option key={e.id} value={e.id} defaultValue={e.id === this.state.suspendStatus ? true : false}>{e.name}</option>
                    ))}
                  </select>
                  <button className="btn btn-success btn-sm pr-5 pl-5 mr-3" type="button" onClick={() => this.searchFunction()}>Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table responsive style={{borderBottom: '1px solid #ebedf2'}}>
                  <thead>
                    <tr>
                      {/* <th>
                        <Checkbox
                          type="checkbox"
                          name="selectAll"
                          id="selectAll"
                          handleClick={() => this.handleSelectAll()}
                          isChecked={this.state.isCheckAll}
                        />
                      </th> */}
                      <th className="tableheh" style={{ width: '4%' }}>
                        No
                      </th>
                      <th className="tableheh" style={{ width: '46%' }}>
                        Artwork
                      </th>
                      <th className="tableheh">
                        Created At
                      </th>
                      <th className="tableheh">
                        Featured
                      </th>
                      <th className="tableheh">
                        Approval Status
                      </th>
                      <th className="tableheh">
                        suspend
                      </th>
                      <th className="tableheh center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  {this.state.loading ? 
                    <tbody>
                      <tr>
                        <td className="center" colSpan="5"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                      </tr>
                    </tbody> :
                    <tbody>
                      {this.state.data.map((item, index) => (
                        <tr key={index}>
                          {/* <td className="align-middle">
                            <Checkbox
                              type="checkbox"
                              name={item.name}
                              id={item.id}
                              handleClick={this.handleClick}
                              isChecked={this.state.isCheck.includes(item.id)}
                              />
                          </td> */}
                          <td className="align-middle">{index+1}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                  alt="Logo"
                                  src={item.photo ? `${this.state.linkImage}${item.photo}` : toAbsoluteUrl("/media/users/profile.png")}
                                  className="img-list-data"
                                />
                              <div>
                                <span className="name-user">{item.fullname}</span>
                                <br />
                                <span>{item.email}</span>
                                <br />
                                <span className="btn-action email-user" onClick={() =>  this.copyPaste(item.crypto_address)}>
                                  <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                                  <u>{item.crypto_address}</u>
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">{this.dateTime(item.created_at)}</td>
                          <td className="align-middle">
                            {item.featured_merchant_id ?
                              <Badge variant="success" onClick={() => this.handeleFeatureShow(item.id, 'remove')} style={{cursor: 'pointer'}}>
                                Active
                              </Badge>
                              :
                              <Badge variant="danger" onClick={() => this.handeleFeatureShow(item.id, 'add')} style={{cursor: 'pointer'}}>
                                Inactive
                              </Badge>
                            }
                          </td>
                          <td className="align-middle">
                            {item.status === -2 &&
                              <Badge variant="danger">
                                Reject
                              </Badge>
                            }
                            {item.status === -1 &&
                              <Badge variant="warning">
                                Resubmit
                              </Badge>
                            }
                            {item.status === 0 &&
                              <Badge variant="warning" onClick={() => this.handeleApproveShow(item.id)} style={{cursor: 'pointer'}}>
                                Pending
                              </Badge>
                            }
                            {item.status === 1 &&
                              <Badge variant="success">
                                Approve
                              </Badge>
                            }
                          </td>
                          <td className="align-middle">
                            {item.is_suspended === 0 ?
                              <Badge variant="success" onClick={() => this.handeleSuspendShow(item.id,'suspend')} style={{cursor: 'pointer'}}>
                                Not Suspended
                              </Badge>
                              :
                              <Badge variant="danger" onClick={() => this.handeleSuspendShow(item.id,'unsuspend')} style={{cursor: 'pointer'}}>
                                Suspended
                              </Badge>
                            }
                          </td>
                          <td className="all-center">
                            <ButtonToolbar style={{ justifyContent: 'center'}}>
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Detail Merchant
                                  </Tooltip>
                                }
                              >
                                <span className="btn-action" onClick={() => this.handleShow(item.id)}>
                                  <i style={{fontSize: '1rem', paddingRight: '0'}} className="far fa-eye" />
                                </span>
                              </OverlayTrigger>
                              {item.contract && <>
                                &nbsp; | &nbsp;
                                <OverlayTrigger
                                  placement='top'
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      Edit Merchant
                                    </Tooltip>
                                  }
                                >
                                  <Link to={`/merchants/${item.id}/contract`} style={{width: '17%'}}>
                                    <span className="btn-action">
                                      <i style={{fontSize: '1rem', paddingRight: '0', color: '#000' }} className="far fa-list-alt" />
                                    </span>
                                  </Link>
                                </OverlayTrigger>
                              </>}
                              {/* &nbsp; | &nbsp;
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Delete Data
                                  </Tooltip>
                                }
                              >
                                <span className="btn-action" onClick={() => this.handleDelete()}>
                                  <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-trash-alt text-danger" />
                                </span>
                              </OverlayTrigger> */}
                            </ButtonToolbar>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </Table>
                {/* <div className="d-flex justify-content-end mb-5 pb-5 pr-3">
                  <div className="align-self-center pr-3" style={{fontSize: '1rem'}}>
                    <span>You have selected 1 items</span>
                  </div>
                  <select className="form-control font-8rem mr-3" onChange={(e) => this.setState({ suspend: e.target.value })} placeholder="roles" style={{width: '20%', height: '39px'}}>
                    {suspendStatus.map((e) => (
                      <option key={e.id} value={e.id} defaultValue={e.id === this.state.suspend ? true : false}>{e.name}</option>
                    ))}
                  </select>
                  <button className="btn btn-outline-danger btn-sm pr-5 pl-5" type="button">Confirm</button>
                </div> */}
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          dialogClassName="modal-costume-width"
          centered
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton style={{padding: '0', borderBottom: '0'}}/>
          <Modal.Body className="pt-0">
            {/* <button type="button" className="close" onClick={() => this.handleClose()}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button> */}
            <div className="detail">
              <img
                alt="Logo"
                src={this.state.dataDetail.photo ? `${this.state.linkImage}${this.state.dataDetail.photo}` : toAbsoluteUrl("/media/users/profile.png")}
              />
              <div className="detail-artwork-name">
                <span>{this.state.dataDetail.fullname}</span>
              </div>
              <div className="detail-artwork-join">
                <span>Joined since {moment(this.state.dataDetail.created_at).format('DD MMMM YY')}</span>
              </div>
              <div className="detail-artwork-address btn-action" onClick={() =>  this.copyPaste(this.state.dataDetail.crypto_address)}>
                <span>
                  <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                  <u>{this.state.dataDetail.crypto_address}</u>
                </span>
              </div>
              <div className="detail-artwork-status">
                <span>
                  Approval Status: &nbsp;&nbsp;
                  {this.state.dataDetail.status === -2 && <span className="text-danger">Banned</span>}
                  {this.state.dataDetail.status === -1 && <span className="text-danger">Rejected</span>}
                  {this.state.dataDetail.status === 0 && <span className="text-warning">Pending</span>}
                  {this.state.dataDetail.status === 1 && <span className="text-success">Approved</span>}
                </span>
              </div>
              {this.state.dataDetail?.detail?.reject_reason &&
                <div className="detail-artwork-status">
                  <span>
                    Reason: &nbsp;
                    <span className="text-danger">
                      {this.state.dataDetail.detail.reject_reason}
                    </span>
                  </span>
                </div>}
              <div className="detail-artwork-description" style={{marginBottom: '31px'}}>
                <span>{this.state.dataDetail.detail?.biografi}</span>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="detail-title">
                    <span>Contact Person</span>
                  </div>
                  <div className="detail-title-content">
                    <span>
                      <i style={{fontSize: '1rem', paddingRight: '8px'}} className="flaticon-email" />
                      <u>{this.state.dataDetail.detail?.email}</u>
                    </span>
                  </div>
                  <div className="detail-title-content">
                    <span>
                      <i style={{fontSize: '1rem', paddingRight: '8px'}} className="fas fa-phone-volume" />
                      {this.state.dataDetail.detail?.contact_phone}
                    </span>
                  </div>
                  <div className="detail-title-content">
                    <span>
                      <i style={{fontSize: '1rem', paddingRight: '8px'}} className="far fa-building" />
                      {this.state.dataDetail.detail?.address_of_brand}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="detail-title">
                    <span>Organizational Info</span>
                  </div>
                  <div className="detail-title-content">
                    <span>
                      <i style={{fontSize: '1rem', paddingRight: '8px'}} className="far fa-building" />
                      {this.state.dataDetail.detail?.brand_name}
                    </span>
                  </div>
                  <div className="detail-title-content">
                    <span>
                      <i style={{fontSize: '1rem', paddingRight: '8px'}} className="fa fa-globe" />
                      <u>{this.state.dataDetail.detail?.brand_website}</u>
                    </span>
                  </div>
                  <div className="detail-title-content">
                    <span>
                      <i style={{fontSize: '1rem', paddingRight: '8px'}} className="fas fa-clipboard-list" />
                      {this.state.dataDetail.detail?.web_info}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="detail-title">
                    <span>Social Media</span>
                  </div>
                  <div className="detail-title-content">
                    <span>
                      <i style={{fontSize: '1rem', paddingRight: '8px'}} className="fab fa-instagram" />
                      {this.state.dataDetail.detail?.instagram}
                    </span>
                  </div>
                  <div className="detail-title-content">
                    <span>
                      <i style={{fontSize: '1rem', paddingRight: '8px'}} className="fab fa-facebook-square" />
                      {this.state.dataDetail.detail?.facebook}
                    </span>
                  </div>
                  <div className="detail-title-content">
                    <span>
                      <i style={{fontSize: '1rem', paddingRight: '8px'}} className="fab fa-tiktok" />
                      {this.state.dataDetail.detail?.twitter}
                    </span>
                  </div>
                  <div className="detail-title-content">
                    <span>
                      <i style={{fontSize: '1rem', paddingRight: '8px'}} className="fab fa-twitter-square" />
                      {this.state.dataDetail.detail?.tiktok}
                    </span>
                  </div>
                  <div className="detail-title-content">
                    <span>
                      <i style={{fontSize: '1rem', paddingRight: '8px'}} className="fab fa-youtube" />
                      {this.state.dataDetail.detail?.youtube}
                    </span>
                  </div>
                  <div className="detail-title-content">
                    <span>
                      <i style={{fontSize: '1rem', paddingRight: '8px'}} className="fas fa-link" />
                      <u>{this.state.dataDetail.detail?.portofolio}</u>
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="pt-5">
                <button className="btn btn-success btn-sm pr-5 pl-5 mr-3" type="button" onClick={() => this.getFile(this.state.dataDetail.detail?.id_card)}>
                  <i style={{fontSize: '1rem', paddingRight: '8px'}} className="fas fa-cloud-download-alt" />
                  Download ID Card
                </button>
                <button className="btn btn-success btn-sm pr-5 pl-5 mr-3" type="button" onClick={() => this.getFile(this.state.dataDetail.detail?.other_document)}>
                  <i style={{fontSize: '1rem', paddingRight: '8px'}} className="fas fa-cloud-download-alt" />
                  Download Documents
                </button>
              </div> */}
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showStatus} onHide={this.handeleStatusClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body style={{textAlign: 'center'}}>
            <button type="button" className="close" onClick={() => this.handeleStatusClose()}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            <div className="d-flex">
              <div className="swal2-header mt-4">
                <ul className="swal2-progress-steps" style={{ display: 'none' }}></ul>
                <h3>Are you sure you want to change the status to inactive ? </h3>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
            <Button style={{ margin: '0.3em' }} variant="primary" onClick={() => this.handeleStatusClose()}>Cancel</Button>
              <Button style={{ margin: '0.3em' }} variant="danger">Submit</Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showFeature} onHide={this.handeleFeatureClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body style={{textAlign: 'center'}}>
            <button type="button" className="close" onClick={() => this.handeleFeatureClose()}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            <div className="d-flex">
              <div className="swal2-header mt-4">
                <ul className="swal2-progress-steps" style={{ display: 'none' }}></ul>
                <h3>Do you want to {this.state.suspendStat} the artwork from the featured artwork list? </h3>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
            <Button style={{ width: '25%', marginRight: '20px' }} variant="primary" onClick={() => this.handeleFeatureClose()}>Cancel</Button>
            <Button
              style={{ width: '25%' }}
              variant="danger"
              onClick={() => this.feature()}
              disabled={this.state.loadingSuspend}
              className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                {
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loadingSuspend
                }
              )}`}
            >
              Yes</Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showApprove} onHide={this.handeleApproveClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body style={{textAlign: 'center'}}>
            <button type="button" className="close" onClick={() => this.handeleApproveClose()}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            <div className="d-flex">
              <div className="swal2-header mt-4">
                <ul className="swal2-progress-steps" style={{ display: 'none' }}></ul>
                {!this.state.rejectTrue ?
                  <h3>Do you want to approve this merchant ? </h3>
                  :
                  <>
                    <h3>Please write your {this.state.rejectState} reason for this merchant</h3>
                    <textarea
                      rows="4" cols="50"
                      className="form-control mt-3 mb-3" 
                      name="Reject Reason" 
                      placeholder="Insert your reject reason here" 
                      value={this.state.rejectReason} 
                      onChange={(val) => {
                        this.setState({ rejectReason: val.target.value })
                      }} />
                  </>
                }
              </div>
            </div>
            {this.state.rejectTrue ?
                <div className="d-flex justify-content-center mt-4">
                  <Button
                    style={{ margin: '0.3em' }}
                    variant="primary"
                    onClick={() => this.handeleApproveClose()}>Cancel</Button>
                  <Button
                    style={{ margin: '0.3em' }}
                    variant="danger"
                    disabled={this.state.rejectReason === '' || this.state.loadingSuspend}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loadingSuspend
                      }
                    )}`}
                    onClick={() => this.approve(this.state.rejectState)}
                    >Submit</Button>
                </div>
              :
                <div className="d-flex justify-content-center mt-5">
                  <Button
                    style={{ marginRight: '20px', width: '20%' }}
                    variant="primary"
                    disabled={this.state.loadingSuspend}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--center kt-spinner--md kt-spinner--light": this.state.loadingSuspend
                      }
                    )}`}
                    onClick={() => this.approve('approved')}>Approve</Button>
                  <Button
                    style={{ marginRight: '20px' }}
                    variant="warning" onClick={() => this.setState({rejectTrue: true, rejectState: 'rejected'})}
                    disabled={this.state.loadingSuspend}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--center kt-spinner--md kt-spinner--light": this.state.loadingSuspend
                      }
                    )}`}>Resubmit Reject</Button>
                  <Button style={{ width: '20%' }}
                    variant="danger"
                    onClick={() => this.setState({rejectTrue: true, rejectState: 'banned'})}
                    disabled={this.state.loadingSuspend}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--center kt-spinner--md kt-spinner--light": this.state.loadingSuspend
                      }
                    )}`}>Banned</Button>
                </div>
            }
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showSuspend} onHide={this.handeleSuspendClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body style={{textAlign: 'center'}}>
            <button type="button" className="close" onClick={() => this.handeleSuspendClose()}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            <div className="d-flex">
              <div className="swal2-header mt-4">
                <ul className="swal2-progress-steps" style={{ display: 'none' }}></ul>
                <h3>Are you sure you want to change the status to {this.state.suspendStat} ? </h3>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
            <Button style={{ margin: '0.3em' }} variant="primary" onClick={() => this.handeleSuspendClose()}>Cancel</Button>
            <Button
              style={{ margin: '0.3em' }}
              disabled={this.state.loadingSuspend}
              className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                {
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loadingSuspend
                }
              )}`}
              variant="danger" onClick={() => this.suspend()}>Submit</Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}