import React from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Summary, getExport } from '../../crud/history';
import { toast } from 'react-toastify';
import { Table, Badge, Modal, OverlayTrigger, ButtonToolbar, Tooltip } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import SelectDate from '../../component/datetime';
import Pagination from "react-js-pagination";
import ViewArtwork from '../../component/thumbnail';
import ViewBB from '../../component/thumbnailBB';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

const sortTypes = {
  asc: {
    className: 'sort-up',
    fn: (a, b) => a.net_worth - b.net_worth
  },
  desc: {
    className: 'sort-down',
    fn: (a, b) => b.net_worth - a.net_worth
  },
  default: {
    className: 'sort',
    fn: (a, b) => a
  }
};

export default class Transaction extends React.Component {
  historyEdit(id) {
    history.push("/leaderboard/"+id+"/editEvent");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      linkImage: process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/',
      show: false,
      loading: false,
      totalData: 0,
      data:[],
      dataDetail:{},
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderSort: 'desc',
      active: 1,
      params: '',
      startDate: null,
      endDate: null,
      priceTKOTicker: 0,
      status: '',
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleShow(index = 1) {
    this.setState({ show: true, dataDetail: this.state.data[index]});
  }

  handleClose() {
    this.setState({ show: false });
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.getSummary()
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getSummary() {
    this.setState({loading: true})

    let search = ''
    if(this.state.seacrhParams !== ''){
      search=`&q=${this.state.seacrhParams}`
    }

    let start = ''
    if(this.state.startDate !== null){
      start=`&start_date=${moment(this.state.startDate).format('YYYY-MM-DD')}`
    }
    let end = ''
    if(this.state.endDate !== null){
      end=`&end_date=${moment(this.state.endDate).format('YYYY-MM-DD')}`
    }

    let stat = ''
    if(this.state.status !== ''){
      stat=`&nft_type=${this.state.status}`
    }

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?&order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}${search}${start}${end}${stat}`
    }else{
      params = `?&order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}${search}${start}${end}${stat}`
    }

    Summary(this.getToken(), params)
    .then(res => {
      this.setState({
        totalData: res.data.data.total_data,
        data: res.data.data.data || [],
        postPerPage: res.data.data.per_page,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        data:[],
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }

  getExportSummary() {
    this.setState({loading: true})

    let search = ''
    if(this.state.seacrhParams !== ''){
      search=`&q=${this.state.seacrhParams}`
    }

    let start = ''
    if(this.state.startDate !== null){
      start=`&start_date=${moment(this.state.startDate).format('YYYY-MM-DD')}`
    }
    let end = ''
    if(this.state.endDate !== null){
      end=`&end_date=${moment(this.state.endDate).format('YYYY-MM-DD')}`
    }
    let stat = ''
    if(this.state.status !== ''){
      stat=`&nft_type=${this.state.status}`
    }

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?&order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}${search}${start}${end}${stat}`
    }else{
      params = `?&order_sort=${this.state.orderSort}${search}${start}${end}${stat}`
    }

    getExport(this.getToken(), params)
    .then(res => {
      if(res){
        toast.success(`Export is successful, the export transaction will be sent to your email.`);
      }
      this.setState({
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }

  getTko = async() => {
    fetch('https://api.binance.me/api/v3/ticker/price?symbol=TKOBIDR')
    .then(response => response.json())
    .then(data => {
      this.setState({
        priceTKOTicker: data.price || 0,
      })
    });
  };

  componentDidMount(){
    // setInterval(() => {
    //   this.getTko();
    // }, 3000);
    this.getSummary();
  }

  searching(){
    this.setState({
      active: 1
    })
    this.getSummary();
  }

  copyPaste(val){
    navigator.clipboard.writeText(val);
    this.handleNotif(val);
    return
  }
  handleNotif(val){
    Swal.fire({
      icon: 'success',
      title: `Address ${val} copied`,
      showConfirmButton: false,
      timer: 1500
    })
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.getSummary()
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  }

  handleDelete(){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // this.delete(id);
      }
    })
  }
  addCommas = (x) => {
    if(x){
      const checkNumber = x.toFixed(6).toString().split('.');
      let numberCommas = '';
      if (checkNumber[1] === '000000') {
        numberCommas = checkNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        numberCommas = `${checkNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${checkNumber[1]}`;
      }
      return numberCommas;
    }else{
      return "0"
    }
    
  };
  limitAddressDisplay = (address = '', length) => {
    return address.substring(0, length) + " ... " + address.substring(address.length-length);
  }

  render(){
    const statusType = [
      {
        id: "",
        name: "All",
      },
      {
        id: "single_nft",
        name: "Single NFT",
      },
      {
        id: "blind_box",
        name: "TokoSurprise",
      }
    ];
    const detailData = this.state.dataDetail;
    console.log('load :', this.state.loading);
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start">
                  <input 
                    type="text" 
                    className="form-control mr-3 font-8rem p-4"
                    style={{width: '70%'}}
                    name="search" 
                    placeholder="trx hash, trx block, contract adrress, from addrees, to adress" 
                    value={this.state.seacrhParams} 
                    onChange={this.changeSearch} />
                  <div style={{width: '25%', marginRight: '1rem'}}>
                    <SelectDate
                      changeDate={(val) => this.setState({ startDate: val })}
                      selected={this.state.startDate}
                      placeholder="Start Date"/>
                  </div>
                  <div style={{width: '25%', marginRight: '1rem'}}>
                    <SelectDate
                      changeDate={(val) => this.setState({ endDate: val })}
                      selected={this.state.endDate}
                      placeholder="End Date"/>
                  </div>
                  <select className="form-control font-8rem mr-3" onChange={(e) => this.setState({ status: e.target.value })} placeholder="roles" style={{width: '20%', height: '39px'}}>
                    {statusType.map((e) => (
                      <option key={e.id} value={e.id} defaultValue={e.id === this.state.status ? true : false}>{e.name}</option>
                    ))}
                  </select>
                  <button className="btn btn-success btn-sm pr-5 pl-5 mr-3" type="button" onClick={() => this.searching()}>Search</button>
                  <button className="btn btn-primary btn-sm pr-3 pl-3 mr-3" style={{ width: '20%'}} type="button" onClick={() => this.getExportSummary()} disabled={this.state.loading}>Export Report</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ width: '4%' }}>
                        No
                      </th>
                      <th className="tableheh">
                        Type
                      </th>
                      <th className="tableheh" style={{ width: '24%' }}>
                        Artwork
                      </th>
                      <th className="tableheh">
                        From
                      </th>
                      <th className="tableheh">
                        To
                      </th>
                      <th className="tableheh">
                        Amount
                      </th>
                      <th className="tableheh">
                        Created At &nbsp;
                        <button onClick={(e) => this.onSortChange('created_at')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].className}`} />
                        </button>
                      </th>
                      <th className="tableheh">
                        Status
                      </th>
                      <th className="tableheh">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((item, index) => (
                      <tr key={index}>
                        <td className="align-middle">{index+1}</td>
                        <td className="align-middle">{item.nft_type === 0 ? 'Single NFT' : 'Toko Surprise'}</td>
                        <td>
                          {item.nft_type === 0 ?
                            <div className="d-flex align-items-center">
                              {item.image_hash && item.artworks &&
                                <ViewArtwork
                                  data={`${this.state.linkImage}/user/${item.id_user_creator}/artwork/${item.image_hash}.${item.artworks?.image_ext}`}
                                  extdata={item.artworks?.image_ext}
                                />}
                              <div>
                                <span className="btn-action email-user">
                                  {item.is_external && 'External'}
                                  <span style={{color: '#ffb822'}}>{item.from_blind_box_series && `${item.from_blind_box_series.blindbox.title} #${item.from_blind_box_series.blindbox_series}` }</span>
                                </span>
                                <br />
                                <span className="name-user">{item.artworks?.title} <span className="text-success">&nbsp;#{item.artwork_series}</span></span>
                                <br />
                                <span className="btn-action email-user" onClick={() =>  this.copyPaste(item.contract?.contract_address)}>
                                  <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                                  <u>{this.limitAddressDisplay(item.contract?.contract_address, 9)}</u>
                                </span>
                              </div>
                            </div>
                            :
                            <div className="d-flex align-items-center">
                              <ViewBB
                                data={item.blind_box_series.blindbox}
                                extdata={item.blind_box_series.blindbox.image_ext}
                              />
                              <div>
                                <span className="name-user">
                                  {item.blind_box_series?.blindbox.title}
                                  <span className="text-success">
                                    &nbsp;#{item.blind_box_series?.blindbox_series}
                                  </span>
                                </span>
                                <br />
                                <span className="btn-action email-user" onClick={() =>  this.copyPaste(item.contract?.contract_address)}>
                                  <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                                  <u>{this.limitAddressDisplay(item.contract?.contract_address, 9)}</u>
                                </span>
                              </div>
                            </div>
                          }
                        </td>
                        <td className="align-middle">
                          {item.seller ? <>
                            <span className="from-to-user">{item.seller.fullname}</span> <br />
                            <span className="btn-action email-user" onClick={() =>  this.copyPaste(item.seller.crypto_address)}>
                              <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                              <u>{this.limitAddressDisplay(item.seller.crypto_address, 9)}</u>
                            </span></> : '-'}
                        </td>
                        <td className="align-middle">
                          {item.buyer ? <>
                            <span className="from-to-user">{item.buyer?.fullname}</span> <br />
                            <span className="btn-action email-user" onClick={() =>  this.copyPaste(item.buyer?.crypto_address)}>
                              <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                              <u>{this.limitAddressDisplay(item.buyer?.crypto_address, 9)}</u>
                            </span></> : '-'}
                        </td>
                        <td className="align-middle">
                          {item.price_tko ? <>
                            <span className="ammount-tko">
                              {this.addCommas(item.price_tko)} TKO
                            </span>
                            <br />
                            <span>
                              {this.addCommas(item.price_idr)} IDR
                            </span></> : '-'}
                        </td>
                        <td className="align-middle">{this.dateTime(item.transacted_at)}</td>
                        <td className="align-middle">
                          {item.status === -1 &&
                            <Badge variant="danger">
                              Failde
                            </Badge>
                          }
                          {item.status === 0 &&
                            <Badge variant="warning">
                              Pending
                            </Badge>
                          }
                          {item.status === 1 &&
                            <Badge variant="success">
                              Success
                            </Badge>
                          }
                        </td>
                        <td className="all-center">
                          <ButtonToolbar style={{ justifyContent: 'center'}}>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  View Detail
                                </Tooltip>
                              }
                            >
                              <span className="btn-action" onClick={() => this.handleShow(index)}>
                                <i style={{fontSize: '1rem', paddingRight: '0'}} className="far fa-eye" />
                              </span>
                            </OverlayTrigger>
                          </ButtonToolbar>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          dialogClassName="modal-costume-width"
          centered
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton style={{padding: '0', borderBottom: '0'}}/>
          <Modal.Body className="pt-0">
            {/* <button type="button" className="close" onClick={() => this.handleClose()}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button> */}
            <div className="d-flex justify-content-center">
              {detailData &&
                <div className="text-center mr-3">
                  {detailData.nft_type === 0 ? 
                    <ViewArtwork
                      data={`${this.state.linkImage}/user/${detailData.id_user_creator}/artwork/${detailData.image_hash}.${detailData.artworks?.image_ext}`}
                      extdata={detailData.artworks?.image_ext}
                      type={true}
                    /> : 
                    <ViewBB
                      data={detailData.blind_box_series?.blindbox}
                      extdata={detailData.blind_box_series?.blindbox?.image_ext}
                      specialPage
                    />}
                  <br />
                  {detailData.nft_type === 0 ? 
                    <span className="name-user">
                      {detailData.artworks?.title}
                      <span className="text-success">&nbsp;#{detailData.artwork_series}</span>
                    </span> : 
                    <span className="name-user">
                      {detailData.blind_box_series?.blindbox.title}
                      <span className="text-success">&nbsp;#{detailData.blind_box_series?.blindbox.blindbox_series}</span>
                    </span>}
                </div>}
              <div className="d-flex flex-row align-self-center">
                <div>
                  <div className="detail-trx-title">
                    <span>Contract ID: </span>
                  </div>
                  <div className="detail-trx-title">
                    <span>Token ID: </span>
                  </div>
                  <div className="detail-trx-title">
                    <span>Transaction ID: </span>
                  </div>
                  <div className="detail-trx-title">
                    <span>Transaction Hash: </span>
                  </div>
                  <div className="detail-trx-title">
                    <span>Transaction Date: </span>
                  </div>
                </div>
                <div className="ml-3">
                  <div className="detail-trx-address">
                    <span className="btn-action" onClick={() =>  this.copyPaste(detailData.contract?.contract_address)}>
                      <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                      <u>{this.limitAddressDisplay(detailData.contract?.contract_address, 12)}</u>
                    </span>
                  </div>
                  <div className="detail-trx-address">
                    <span className="btn-action" onClick={() =>  this.copyPaste(detailData.token_id)}>
                      <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                      <u>{detailData.token_id}</u>
                    </span>
                  </div>
                  <div className="detail-trx-address">
                    <span className="btn-action">
                      <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                      <u>{detailData.id}</u>
                    </span>
                  </div>
                  <div className="detail-trx-address">
                    <span className="btn-action" onClick={() =>  this.copyPaste(detailData.transaction_hash)}>
                      <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                      <u>{this.limitAddressDisplay(detailData.transaction_hash, 12)}</u>
                    </span>
                  </div>
                  <div className="detail-trx-address">
                    <span>{moment(detailData.transacted_at).format('DD MMMM YYYY')}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <Table responsive>
                <thead>
                  <tr>
                    <th colSpan="2">
                      <span className="name-user">Transaction Detail</span>
                    </th>
                    <th colSpan="2">
                      <span className="name-user">Commission Details</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="detail-trx-title">Price:</span>
                    </td>
                    <td>
                      <span className="detail-trx-title">
                        {this.addCommas(detailData.seller_earn_idr)} IDR
                        {' '}
                        ({this.addCommas(detailData.seller_earn_tko)} TKO)
                      </span>
                    </td>
                    <td>
                      <span className="detail-trx-title">Seller:</span>
                    </td>
                    <td className="text-right">
                      <span className="detail-trx-title">
                        {this.addCommas(detailData.seller_earn_idr)} IDR
                        {' '}
                        ({this.addCommas(detailData.seller_earn_tko)} TKO)
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="detail-trx-title">Comission Fee:</span>
                    </td>
                    <td>
                      <span className="detail-trx-title">
                        {this.addCommas(detailData.merchant_earn_idr + detailData.toko_earn_idr)} IDR
                        {' '}
                        ({this.addCommas(detailData.merchant_earn_tko + detailData.toko_earn_tko)} TKO)
                      </span>
                    </td>
                    <td>
                      <span className="detail-trx-title">Merchants:</span>
                    </td>
                    <td className="text-right">
                      <span className="detail-trx-title">
                        {this.addCommas(detailData.merchant_earn_idr)} IDR
                        {' '}
                        ({this.addCommas(detailData.merchant_earn_tko)} TKO)
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="detail-trx-title">Total Amount:</span>
                    </td>
                    <td>
                      <span className="detail-trx-title text-success">
                        {this.addCommas(detailData.price_idr)} IDR
                        {' '}
                        ({this.addCommas(detailData.price_tko)} TKO)
                      </span>
                    </td>
                    <td>
                      <span className="detail-trx-title">Tokocrypto:</span>
                    </td>
                    <td className="text-right">
                      <span className="detail-trx-title">
                        {this.addCommas(detailData.toko_earn_idr)} IDR
                        {' '}
                        ({this.addCommas(detailData.toko_earn_tko)} TKO)
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}