import axios from "axios";
import qs from 'qs';

const instance = (process.env.REACT_APP_ENV === 'demo') ? 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  }) :
  (process.env.REACT_APP_ENV === 'production') ? 
    axios.create({
      baseURL: 'https://service.tkonft.art',
    }) : 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  });

export function Summary(token, params) {
  return instance.get("/admin/tkonft/faq-search" + params,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function Detail(token, id) {
  return instance.get("/admin/tkonft/faq/"+id+"/detail",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function Create(token, data) {
  return instance.post("/admin/tkonft/faq-create",
    qs.stringify(data),{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function Update(token, id, data) {
  return instance.put("/admin/tkonft/faq/"+id+"/update",
    qs.stringify(data),{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function UpdateStatus(token, id) {
  return instance.put("/admin/tkonft/faq/"+id+"/status",{},{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    }
  });
}

export function Delete(token, id) {
  return instance.delete("/admin/tkonft/faq/"+id+"/delete",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function SummaryCategory(token, params) {
  return instance.get("/admin/tkonft/faq-category-search" + params,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function SummaryCategoryDropdown(token) {
  return instance.get("/admin/tkonft/faq-category/list",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function DetailCategory(token, id) {
  return instance.get("/admin/tkonft/faq-category/"+id+"/detail",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function CreateCategory(token, data) {
  return instance.post("/admin/tkonft/faq-category-create",data,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function UpdateCategory(token, id, data) {
  return instance.put("/admin/tkonft/faq-category/"+id+"/update",data,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}


export function UpdateCategoryStatus(token, id) {
  return instance.put("/admin/tkonft/faq-category/"+id+"/status",{},{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    }
  });
}

export function DeleteCategory(token, id) {
  return instance.delete("/admin/tkonft/faq-category/"+id+"/delete",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}