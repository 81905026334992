import React from 'react';
import { Link } from "react-router-dom";
import CKEditor from 'ckeditor4-react';
import clsx from "clsx";
import moment from 'moment';
import { Form, Button } from "react-bootstrap";
import { createBrowserHistory } from 'history';
import { Create, Detail, Update } from '../../crud/category';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class AddCategory extends React.Component {
  history() {
    history.push("/leaderboard");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/',
      idData: '',
      loading: false,
      titleID: '',
      titleEN: '',
      descID: '<h2>Syarat Dan Ketentuan</h2><ul><li>Syarat A</li><li>Syarat B</li><li>Syarat C</li><li>Syarat D</li><li>Syarat E</li></ul><p><a href="https://tokocrypto.com">Selengkapnya →</a></p>',
      descEN: '<h2>Term And Condition</h2><ul><li>Term A</li><li>Term B</li><li>Term C</li><li>Term D</li><li>Term E</li></ul><p><a href="https://tokocrypto.com">Show More →</a></p>',
      status: '1',
      type: '0',
      banner: null,
      bannerEdit: '',
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
  }

  emptyState(){
    this.setState({
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/',
      idData: '',
      loading: false,
      titleID: '',
      titleEN: '',
      descID: '<h2>Syarat Dan Ketentuan</h2><ul><li>Syarat A</li><li>Syarat B</li><li>Syarat C</li><li>Syarat D</li><li>Syarat E</li></ul><p><a href="https://tokocrypto.com">Selengkapnya →</a></p>',
      descEN: '<h2>Term And Condition</h2><ul><li>Term A</li><li>Term B</li><li>Term C</li><li>Term D</li><li>Term E</li></ul><p><a href="https://tokocrypto.com">Show More →</a></p>',
      status: '',
    });
  }

  handleChangeEditorID =  ( event ) => {
    const data = event.editor.getData();
    this.setState({ descID: data });
  }

  handleChangeEditorEN =  ( event ) => {
    const data = event.editor.getData();
    this.setState({ descEN: data });
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getDetailData(id){
    this.setState({
      loading: true
    })

    Detail(this.getToken(), id)
    .then(res => {
      this.setState({
        loading: false,
        titleID: res.data.data.title_id,
        titleEN: res.data.data.title_en,
        descID: res.data.data.desc_id,
        descEN: res.data.data.desc_en,
        status: res.data.data.status.toString(),
        type: res.data.data.type,
        bannerEdit : res.data.data.photo
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }

  isSubmit = event => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('title_id', this.state.titleID);
    formData.append('title_en', this.state.titleEN);
    formData.append('desc_id', this.state.descID);
    formData.append('desc_en', this.state.descEN);
    formData.append('status', this.state.status);
    formData.append('type', this.state.type);
    if(this.state.banner !== null){
      formData.append('file', this.state.banner);
    }

    // let data = {
    //   'title_id': this.state.titleID,
    //   'title_en': this.state.titleEN,
    //   'desc_id': this.state.descID,
    //   'desc_en': this.state.descEN,
    //   'status': this.state.status,
    //   'type': this.state.type
    // }

    
    if(this.state.editPage){
      this.editEvent(formData);
    }else{
      this.addEvent(formData);
    }
  }

  editEvent(data){
    this.setState({
      loading: true
    })

    Update(this.getToken(), this.state.idData,data)
    .then(res => {
      this.setState({
        loading: false,
      })
      this.emptyState();
      history.push("/category");
      window.location.reload();
    })
    .catch(e => {
      console.log(e);
      this.emptyState();
      this.setState({
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  addEvent(data){
    this.setState({
      loading: true
    })

    Create(this.getToken(), data)
    .then(res => {
      this.setState({
        loading: false,
      })
      this.emptyState();
      history.push("/category");
      window.location.reload();
    })
    .catch(e => {
      console.log(e);
      this.emptyState();
      this.setState({
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  componentDidMount(){
    let checkPath = this.props.history.location.pathname.split('/');
    if(checkPath.indexOf("edit-category") > 0){
      this.setState({
        idData: checkPath[checkPath.length - 2],
        editPage: true,
      });
      console.log(checkPath[checkPath.length - 2]);
      this.getDetailData(checkPath[checkPath.length - 2]);
    }
    // let id = this.props.history.location.pathname.split('/')[this.props.history.location.pathname.split('/').length - 2];
  }

  dateTime = date => {
    var ISOformat = moment(date).toISOString()
    return ISOformat
  }

  onChangeFile = async (e) => {
    let file = e.target.files[0];
    if(file){
      await this.setState({
        banner: file
      })
    }
  }

  render(){
    const categoryType = [
      {
        id: "0",
        name: 'Commerce',
      },
      {
        id: "1",
        name: 'Marketplace',
      },
    ]
    const statusType = [
      {
        id: "1",
        name: 'Enable',
      },
      {
        id: "0",
        name: 'Disabled',
      },
    ];
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Title (ID)</Form.Label>
                        <Form.Control type="text" value={this.state.titleID} onChange={this.handleChange('titleID')} required/>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Title (EN)</Form.Label>
                        <Form.Control type="text" value={this.state.titleEN} onChange={this.handleChange('titleEN')} required/>
                      </Form.Group>
                    </div>
                  </div>
                  <Form.Group>
                    <Form.Label>Description (ID)</Form.Label>
                    <br />
                    <CKEditor
                      onChange={this.handleChangeEditorID}
                      data={this.state.descID}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Description (EN)</Form.Label>
                    <br />
                    <CKEditor
                      onChange={this.handleChangeEditorEN}
                      data={this.state.descEN}
                    />
                  </Form.Group>
                  <div className="row">
                    <div className="col-md-10 col-sm-12">
                      <Form.Group>
                        <Form.Label>Upload Banner</Form.Label>
                        <div className="d-flex justify-content-center align-items-center">
                          <Form.Control className="form-control" type="file" onChange={this.onChangeFile} accept="image/*"/>
                        </div>  
                        <Form.Text className="text-muted">
                          <span>*Please using 100px x 100px for this image</span>
                        </Form.Text>
                      </Form.Group>
                    </div>
                    <div className="col-md-2 col-sm-12">
                      <Form.Group>
                        <div>
                          {this.state.bannerEdit !== '' && this.state.banner === null && (
                            <a href={this.state.urlAsset+this.state.bannerEdit} target='_blank' without rel="noopener noreferrer">
                              <img src={this.state.urlAsset+this.state.bannerEdit} alt="img" className="img-bn" />
                            </a>
                          )}
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Select Type</label>
                        <div className="input-group">
                            <select className="form-control" onChange={(e) => this.setState({ type: e.target.value })} placeholder="Select Data">
                                {/* <option disabled selected={this.state.type === '' ? true : false}>Pilih Salah Satu</option> */}
                                {categoryType.map((e) => (
                                  <option key={e.id} value={e.id} selected={e.id === this.state.type ? true : false} defaultValue={e.id === this.state.type ? true : false}>{e.name}</option>
                                ))}
                            </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Select Status</label>
                        <div className="input-group">
                            <select className="form-control" onChange={(e) => this.setState({ status: e.target.value })} placeholder="Select Data">
                                {/* <option disabled selected={this.state.status === '' ? true : false}>Pilih Salah Satu</option> */}
                                {statusType.map((e) => (
                                  <option key={e.id} value={e.id} selected={e.id === this.state.status ? true : false} defaultValue={e.id === this.state.status ? true : false}>{e.name}</option>
                                ))}
                            </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="kt-login__actions" style={{justifyContent: 'center', display: 'flex'}}>
                    <Link to="/category" style={{width: '17%'}}>
                      <Button variant="outline-danger" className="mr-3">Back To Category</Button>
                    </Link>
                    <button
                      type="submit"
                      disabled={this.state.loading}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                        }
                      )}`}
                    >
                      {this.state.editPage ? 'Edit' : 'Add'} Category
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}