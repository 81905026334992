const List = {
    list: [
      {
          albumId: 1,
          id: 1,
          name: "Ismaya",
          price: "1000TKO",
          type: "Individual",
          title: "accusamus beatae ad facilis cum similique qui sunt",
          url: "https://via.placeholder.com/600/92c952",
          thumbnailUrl: "https://via.placeholder.com/150/92c952"
        },
        {
          albumId: 1,
          id: 2,
          name: "Ismaya",
          price: "1000TKO",
          type: "Business",
          title: "reprehenderit est deserunt velit ipsam",
          url: "https://via.placeholder.com/600/771796",
          thumbnailUrl: "https://via.placeholder.com/150/771796"
        },
        {
          albumId: 1,
          id: 3,
          name: "Ismaya",
          price: "1000TKO",
          type: "Business",
          title: "officia porro iure quia iusto qui ipsa ut modi",
          url: "https://via.placeholder.com/600/24f355",
          thumbnailUrl: "https://via.placeholder.com/150/24f355"
        },
        {
          albumId: 1,
          id: 4,
          name: "Ismaya",
          price: "1000TKO",
          type: "Individual",
          title: "culpa odio esse rerum omnis laboriosam voluptate repudiandae",
          url: "https://via.placeholder.com/600/d32776",
          thumbnailUrl: "https://via.placeholder.com/150/d32776"
        },
        {
          albumId: 1,
          id: 5,
          name: "Ismaya",
          price: "1000TKO",
          type: "Business",
          title: "natus nisi omnis corporis facere molestiae rerum in",
          url: "https://via.placeholder.com/600/f66b97",
          thumbnailUrl: "https://via.placeholder.com/150/f66b97"
        },
        {
          albumId: 1,
          id: 6,
          name: "Ismaya",
          price: "1000TKO",
          type: "Business",
          title: "accusamus ea aliquid et amet sequi nemo",
          url: "https://via.placeholder.com/600/56a8c2",
          thumbnailUrl: "https://via.placeholder.com/150/56a8c2"
        },
        {
          albumId: 1,
          id: 7,
          name: "Ismaya",
          price: "1000TKO",
          type: "Business",
          title: "officia delectus consequatur vero aut veniam explicabo molestias",
          url: "https://via.placeholder.com/600/b0f7cc",
          thumbnailUrl: "https://via.placeholder.com/150/b0f7cc"
        },
        {
          albumId: 1,
          id: 8,
          name: "Ismaya",
          price: "1000TKO",
          type: "Individual",
          title: "aut porro officiis laborum odit ea laudantium corporis",
          url: "https://via.placeholder.com/600/54176f",
          thumbnailUrl: "https://via.placeholder.com/150/54176f"
        },
        {
          albumId: 1,
          id: 9,
          name: "Ismaya",
          price: "1000TKO",
          type: "Business",
          title: "qui eius qui autem sed",
          url: "https://via.placeholder.com/600/51aa97",
          thumbnailUrl: "https://via.placeholder.com/150/51aa97"
        },
        {
          albumId: 1,
          id: 10,
          name: "Ismaya",
          price: "1000TKO",
          type: "Individual",
          title: "beatae et provident et ut vel",
          url: "https://via.placeholder.com/600/810b14",
          thumbnailUrl: "https://via.placeholder.com/150/810b14"
        },
    ],
    getList: function () {
      return (
        (localStorage.getItem("theList") &&
          JSON.parse(localStorage.getItem("theList"))) ||
        this.list
      );
    },
    saveList: (list) => {
      localStorage.setItem("theList", JSON.stringify(list));
    },
  };
  
  export default List;