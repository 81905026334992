import axios from "axios";

const instance = (process.env.REACT_APP_ENV === 'demo') ? 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  }) :
  (process.env.REACT_APP_ENV === 'production') ? 
    axios.create({
      baseURL: 'https://service.tkonft.art',
    }) : 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  });

export function SummaryMerchant(token, params) {
  return instance.get("/admin/portal-user/merchants-total" + params,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function SummaryCollector(token, params) {
  return instance.get("/admin/portal-user/collectors-total" + params,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function SummaryMerchantArtwork(token, params) {
  return instance.get("/admin/artwork/artworks-total-merchants" + params,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function SummaryCollectorArtwork(token, params) {
  return instance.get("/admin/artwork/artworks-total-collector" + params,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function SummaryTransaction(token, params) {
  return instance.get("/admin/transaction/total-by-user" + params,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}