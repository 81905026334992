import React from 'react';
import moment from 'moment';
import clsx from "clsx";
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Summary, Update } from '../../crud/config';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

// const sortTypes = {
//   asc: {
//     className: 'sort-up',
//     fn: (a, b) => a.net_worth - b.net_worth
//   },
//   desc: {
//     className: 'sort-down',
//     fn: (a, b) => b.net_worth - a.net_worth
//   },
//   default: {
//     className: 'sort',
//     fn: (a, b) => a
//   }
// };

export default class GeneralConfiguration extends React.Component {
  historyEdit(id) {
    history.push("/leaderboard/"+id+"/editEvent");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      toko1: 0,
      toko2: 0,
      toko3: 0,
      merch1: 100,
      merch2: 0,
      collect2: 100,
      creat3: 100,
      artWorkValue: 100,
      featureMerchantValue: 0,
      featureArtWorkValue: 0,
      whitelist: '',

    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  emptyState(){
    this.setState({
      toko1: 0,
      toko2: 0,
      toko3: 0,
      merch1: 100,
      merch2: 0,
      collect2: 100,
      creat3: 100,
      artWorkValue: 100,
      featureMerchantValue: 0,
      featureArtWorkValue: 0,
      whitelist: '',
    })
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true});
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.getSummary()
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getSummary() {
    this.setState({
      loading: true
    })

    Summary(this.getToken())
    .then(res => {
      const data = res.data.data || {}
      this.setState({
        loading: false,
        toko1: data.official_merchant_first_fee_tokocrypto,
        toko2: data.official_merchant_second_fee_tokocrypto,
        toko3: data.collector_fee,
        merch1: 100 - parseFloat(data.official_merchant_first_fee_tokocrypto),
        merch2: data.official_merchant_second_fee_merchant,
        collect2: 100 - parseFloat(data.official_merchant_second_fee_tokocrypto) - parseFloat(data.official_merchant_second_fee_merchant),
        creat3: 100 - parseFloat(data.collector_fee),
        artWorkValue: data.max_series_no,
        featureMerchantValue: data.max_featured_merchants,
        featureArtWorkValue: data.max_featured_artworks,
        whitelist: data.collectible_whitelist
      })
    })
    .catch(e => {
      console.log(e);
      this.emptyState();
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  updateData() {
    this.setState({
      loading: true
    })

    let data = {
      'official_merchant_first_fee_tokocrypto': this.state.toko1,
      'official_merchant_second_fee_tokocrypto': this.state.toko2,
      'official_merchant_second_fee_merchant': this.state.merch2,
      'collector_fee': this.state.toko3,
      'max_series_no': this.state.artWorkValue,
      'max_featured_artworks': this.state.featureArtWorkValue,
      'max_featured_merchants': this.state.featureMerchantValue,
      'collectible_whitelist':this.state.whitelist,
    }

    Update(this.getToken(), data)
    .then(res => {
      if(res.data.code === 200){
        toast.success(`Update success`);
      }
      this.setState({
        loading: false,
      })
      this.getSummary();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  componentDidMount(){
    this.getSummary();
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.getSummary()
  };

  change1 = (event) => {
    let valueMerch1 = 100 - parseFloat(event.target.value)
    this.setState({ toko1: event.target.value, merch1: valueMerch1 > 0 ? valueMerch1 : 0 });
  }
  change2 = (event) => {
    let valueMerch2 = 100 - parseFloat(event.target.value) - parseFloat(this.state.merch2)
    this.setState({ toko2: event.target.value, collect2: valueMerch2 > 0 ? valueMerch2 : 0 });
  }
  change3 = (event) => {
    let valueMerch2 = 100 - parseFloat(event.target.value) - parseFloat(this.state.toko2)
    this.setState({ merch2: event.target.value, collect2: valueMerch2 > 0 ? valueMerch2 : 0});
  }
  change4 = (event) => {
    let valuecreator = 100 - parseFloat(event.target.value)
    this.setState({ toko3: event.target.value, creat3: valuecreator > 0 ? valuecreator : 0});
  }

  render(){
    
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="row pb-2">
                <div className="col-md-6">
                  <h3 className="pb-4" style={{color: '#000'}}>Fee: Official Merchant Item</h3>
                  <div className="row">
                    <div className="col-md-4">
                      <h5 style={{color: '#000'}} className="mb-4">Tokocrypto (%)</h5>
                      <input 
                        type="number" 
                        className="form-control" 
                        name="tokocrypto%" 
                        placeholder="0" 
                        value={this.state.toko1}
                        min="0"
                        max="100"
                        step=".01"
                        onChange={this.change1} />
                        <br />
                      <input 
                        type="number" 
                        className="form-control" 
                        name="tokocrypto%" 
                        placeholder="0" 
                        value={this.state.toko2}
                        min="0"
                        max="100"
                        step=".01"
                        onChange={this.change2} /> 
                    </div>
                    <div className="col-md-4">
                      <h5 style={{color: '#000'}} className="mb-4">Merchant (%)</h5>
                      <input 
                        type="number" 
                        className="form-control" 
                        name="merchant%" 
                        placeholder="0" 
                        value={this.state.merch1}
                        disabled/>
                        <br />
                      <input
                        type="number" 
                        className="form-control" 
                        name="merchant%" 
                        placeholder="0" 
                        value={this.state.merch2}
                        min="0"
                        max="100"
                        step=".01"
                        onChange={this.change3} />
                    </div>
                    <div className="col-md-4">
                      <h5 style={{color: '#000'}} className="mb-4">Collector (%)</h5>
                      <input
                        style={{marginTop: '73px'}}
                        type="number" 
                        className="form-control" 
                        name="collector%" 
                        placeholder="0" 
                        value={this.state.collect2}
                        disabled/>
                    </div>
                  </div>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-5">
                  <h3 className="mb-4" style={{color: '#000'}}>Fee: Collector Item</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <h5 style={{color: '#000'}} className="mb-4">Tokocrypto (%)</h5>
                      <input
                        type="number" 
                        className="form-control" 
                        name="tokocrypto%" 
                        placeholder="0" 
                        value={this.state.toko3}
                        min="0"
                        max="100"
                        step=".01"
                        onChange={this.change4} />
                    </div>
                    <div className="col-md-6">
                      <h5 style={{color: '#000'}} className="mb-4">Creator (%)</h5>
                      <input
                        type="number" 
                        className="form-control" 
                        name="creator%" 
                        placeholder="0" 
                        value={this.state.creat3}
                        disabled/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="pt-4 pb-2">
                    <h5 style={{color: '#000'}} className="mb-4">Artwork: Max. Series No.</h5>
                    <input
                      style={{width: '80%'}}
                      type="number" 
                      className="form-control" 
                      name="tokocrypto%" 
                      placeholder="0" 
                      value={this.state.artWorkValue}
                      min="0"
                      max="100"
                      onChange={e => this.setState({artWorkValue: e.target.value})} />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="pt-4 pb-5">
                    <h5 style={{color: '#000'}} className="mb-4">Max. Featured Artworks</h5>
                    <input
                      style={{width: '80%'}}
                      type="number" 
                      className="form-control" 
                      name="tokocrypto%" 
                      placeholder="0" 
                      value={this.state.featureArtWorkValue}
                      min="0"
                      max="10"
                      onChange={e => this.setState({featureArtWorkValue: e.target.value})} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pt-4 pb-2">
                    <h5 style={{color: '#000'}} className="mb-4">Max. Featured Merchant</h5>
                    <input
                      style={{width: '40%'}}
                      type="number" 
                      className="form-control" 
                      name="tokocrypto%" 
                      placeholder="0x000000,0x000000" 
                      value={this.state.featureMerchantValue}
                      min="0"
                      max="10"
                      onChange={e => this.setState({featureMerchantValue: e.target.value})} />
                  </div>
                </div>
              </div>
              <div>
                <h5 style={{color: '#000'}} className="mb-4">Whitelisted Contract</h5>
                <textarea
                  rows="3" cols="50"
                  style={{width: '100%'}}
                  className="form-control" 
                  name="tokocrypto%" 
                  placeholder="0" 
                  value={this.state.whitelist}
                  onChange={e => this.setState({whitelist: e.target.value})} />
              </div>
              <div className="d-flex justify-content-center pt-5">
                <button
                  type="button"
                  style={{width: '20%', padding: '10px'}}
                  onClick={() => this.updateData()}
                  className={`btn btn-primary btn-sm mr-3 btn-elevate kt-login__btn-primary ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                    }
                  )}`}
                  disabled={this.state.loading}>Save & Update</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}