import React from 'react';
import { Link } from "react-router-dom";
import clsx from "clsx";
import Swal from 'sweetalert2';
import moment from 'moment';
import { Button, Table, Badge, Form, OverlayTrigger, ButtonToolbar, Tooltip, Modal } from "react-bootstrap";
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import Pagination from "react-js-pagination";
import { Delete, ResetPassword, Summary } from '../../crud/admin';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

// const sortTypes = {
//   asc: {
//     className: 'sort-up',
//     fn: (a, b) => a.net_worth - b.net_worth
//   },
//   desc: {
//     className: 'sort-down',
//     fn: (a, b) => b.net_worth - a.net_worth
//   },
//   default: {
//     className: 'sort',
//     fn: (a, b) => a
//   }
// };

export default class Admin extends React.Component {
  historyEdit(id) {
    history.push("/leaderboard/"+id+"/editEvent");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      totalData: 0,
      dataSummary:[],
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderSort: 'asc',
      active: 1,
      status: '',
      confirm: '',
      password: '',
      idUser: 0,
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
    this.handleResetShow = this.handleResetShow.bind(this);
    this.handleResetClose = this.handleResetClose.bind(this);
  }

  handleResetShow(id){
    this.setState({show : true, idUser: id})
  }
  handleResetClose(){
    this.setState({show : false})
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.getSummary()
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getSummary() {
    this.setState({
      loading: true
    })

    let roles = ''
    if (this.state.status !== '') {
      roles = `&role=${this.state.status}`
    }

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}&title=${this.state.seacrhParams}${roles}`
    }else{
      params = `?order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}&title=${this.state.seacrhParams}${roles}`
    }

    Summary(this.getToken(), params)
    .then(res => {
      this.setState({
        totalData: res.data.data.total_data,
        dataSummary: res.data.data.data,
        postPerPage: res.data.data.per_page,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        false: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  isUpdate = event => {
    event.preventDefault();

    let data = {
      'password': this.state.confirm
    }

    if(this.state.password !== this.state.confirm){
      toast.error(`your password and your confirm is not match`);
    }else{
      this.updateData(data);
    }
  }

  updateData(data) {
    this.setState({
      loading: true,
      show: false
    })

    ResetPassword(this.getToken(), this.state.idUser, data)
    .then(res => {
      if(res){
        toast.succes(`Reset is success`);
      }
      this.setState({
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  componentDidMount(){
    this.getSummary();
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.getSummary()
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  }

  handleDelete(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.delete(id);
      }
    })
  }

  delete(id) {
    this.setState({
      loading: true
    })

    Delete(this.getToken(), id)
    .then(res => {
      this.setState({
        loading: false,
      })
      toast.success('Delete Success');
      this.getSummary();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  render(){
    const roles = [
      {
        id: "",
        name: "All Roles",
      },
      {
        id: "superuser",
        name: "Superuser",
      },
      {
        id: "admin",
        name: "Admin",
      }
    ];
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start">
                  <input 
                    type="text" 
                    className="form-control mr-3" 
                    name="search" 
                    placeholder="Search Email" 
                    value={this.state.seacrhParams} 
                    onChange={this.changeSearch} /> 
                  <select className="form-control font-8rem mr-3" onChange={(e) => this.setState({ status: e.target.value })} placeholder="roles" style={{width: '20%', height: '39px'}}>
                    {/* <option disabled selected={this.state.status === '' ? true : false}>status</option> */}
                    {roles.map((e) => (
                      <option key={e.id} value={e.id} defaultValue={e.id === this.state.status ? true : false}>{e.name}</option>
                    ))}
                  </select>
                  <button className="btn btn-success btn-sm pr-5 pl-5 mr-3" type="button" onClick={() => this.getSummary()}>Search</button>
                  <Link to="/admin/add-admin" style={{width: '17%'}}>
                    <button className="btn btn-primary btn-sm mr-3" type="button" style={{width: '100%', padding: '10px'}}>Add User</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ width: '4%' }}>
                        No
                      </th>
                      <th className="tableheh" style={{ width: '56%' }}>
                        Title
                        {/* <button onClick={(e) => this.onSortChange('event_name')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button> */}
                      </th>
                      <th className="tableheh">
                        Created At &nbsp;
                      </th>
                      <th className="tableheh center">
                        status &nbsp;
                      </th>
                      <th className="center tableheh" style={{width: '10%'}}>Action</th>
                    </tr>
                  </thead>
                  {this.state.loading ? 
                    <tbody>
                      <tr>
                        <td className="center" colSpan="5"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                      </tr>
                    </tbody> :
                    <tbody>
                      {this.state.dataSummary.map((row, index) => (
                        <tr>
                          <td className="align-middle">{index+1}</td>
                          <td>
                            <div className="d-flex ">
                              <i style={{fontSize: '4rem', paddingRight: '15px'}} className="fas fa-user-tie text-success" />
                              <div>
                                <span className="role-user">
                                  {row.admin_role_menus?.map((e) => `${e.name} `)}
                                </span><br />
                                <span className="name-user">{row.name}</span><br />
                                <span className="email-user">{row.email}</span>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">{this.dateTime(row.created_at)}</td>
                          <td className="all-center">
                            {row.status === 1 ?
                              <Badge variant="success">
                                Active
                              </Badge>
                              :
                              <Badge variant="warning">
                                Inactive
                              </Badge>
                            }
                          </td>
                          <td className="all-center">
                            <ButtonToolbar style={{ justifyContent: 'center'}}>
                            <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Reset Password
                                  </Tooltip>
                                }
                              >
                                <span className="btn-action" onClick={() => this.handleResetShow(row.id)}>
                                  <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-history text-warning" />
                                </span>
                              </OverlayTrigger>
                              &nbsp; | &nbsp;
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Edit Data
                                  </Tooltip>
                                }
                              >
                                <Link to={`/admin/${row.id}/edit-admin`} style={{width: '17%'}}>
                                  <span className="btn-action">
                                    <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-pencil-alt text-info" />
                                  </span>
                                </Link>
                              </OverlayTrigger>
                              &nbsp; | &nbsp;
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Delete Data
                                  </Tooltip>
                                }
                              >
                                <span className="btn-action" onClick={() => this.handleDelete(row.id)}>
                                  <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-trash-alt text-danger" />
                                </span>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </Table>
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleResetClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
                <Modal.Title>Update user admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="m-login__form m-form" onSubmit={this.isUpdate}>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" value={this.state.password} onChange={this.handleChange('password')} required/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" value={this.state.confirm} onChange={this.handleChange('confirm')} required/>
              </Form.Group>
              <div className="kt-login__actions" style={{justifyContent: 'space-around', display: 'flex'}}>
                <Button variant="danger" onClick={this.handleResetClose}>Cancel</Button>
                <button
                  type="submit"
                  disabled={this.state.loading}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                    }
                  )}`}
                >
                  Reset
                </button>
              </div>

            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}