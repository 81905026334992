import React, { useRef } from 'react';

const ComponentScroll = ({ children, classPage = '', triggerCheck = () => {} }) => {
  const contentData = useRef(0);
  const onScrollComponent = () => {
    if (contentData.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentData.current;
      if (scrollTop + clientHeight === scrollHeight) {
        triggerCheck();
      }
    }
  };
  return (
    <div className={classPage} ref={contentData} onScroll={onScrollComponent}>
      {children}
    </div>
  );
};
export default ComponentScroll;
