import React from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import Swal from 'sweetalert2';
import { Table, Modal, OverlayTrigger, ButtonToolbar, Tooltip, Badge } from "react-bootstrap";
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import Pagination from "react-js-pagination";
import { Summary, Delete, Detail, SummaryCategoryDropdown, UpdateStatus } from '../../crud/faq';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

// const sortTypes = {
//   asc: {
//     className: 'sort-up',
//     fn: (a, b) => a.net_worth - b.net_worth
//   },
//   desc: {
//     className: 'sort-down',
//     fn: (a, b) => b.net_worth - a.net_worth
//   },
//   default: {
//     className: 'sort',
//     fn: (a, b) => a
//   }
// };

export default class FAQ extends React.Component {
  historyEdit(id) {
    history.push("/leaderboard/"+id+"/editEvent");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingListData: false,
      loadingModal: false,
      totalData: 0,
      data:[],
      dataDetail: {},
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderSort: 'asc',
      active: 1,
      show: false,
      dataDropdown: [],
      dropdown: '',
      status: '',
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow(id) {
    this.setState({ show: true});
    this.getDetail(id);
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.getSummary(activePage);
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getdataDropdown(){
    this.setState({
      loading: true
    })

    SummaryCategoryDropdown(this.getToken())
    .then(res => {
      let datas = [{label: 'All', value: ''}]
      res.data.data.forEach((element) => {
        datas.push({
          label: element.title_en,
          value: element.id
        })
      });
      this.setState({
        dataDropdown: datas,
        loading: false
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }

  getSummary(pages = 1) {
    this.setState({
      loading: true
    })

    let stat = '';
    if(this.state.status !== '') stat=`&status=${this.state.status}`;
    let categories = '';
    if(this.state.dropdown !== '') categories=`&id_faq_category=${this.state.dropdown}`;

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&page=${pages}&limit=${this.state.postPerPage}&title=${this.state.seacrhParams}${stat}${categories}`
    }else{
      params = `?order_sort=${this.state.orderSort}&page=${pages}&limit=${this.state.postPerPage}&title=${this.state.seacrhParams}${stat}${categories}`
    }

    Summary(this.getToken(), params)
    .then(res => {
      this.setState({
        totalData: res.data.data.total_data,
        data: res.data.data.data,
        postPerPage: res.data.data.per_page,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        data:[],
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  getDetail(id) {
    this.setState({
      loading: true
    })

    Detail(this.getToken(), id)
    .then(res => {
      this.setState({
        dataDetail: res.data.data || {},
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  componentDidMount(){
    this.getSummary(this.state.active);
    this.getdataDropdown();
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.getSummary(this.state.active);
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  }

  handleAlert(id, status = 'delete'){
    Swal.fire({
      title: 'Are you sure?',
      text: status === 'delete' ? "You won't be able to revert this!" : "You change this status ?",
      icon: status === 'delete' ? 'warning' : 'info',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: status === 'delete' ? 'Yes, delete it!' : 'Submit'
    }).then((result) => {
      if (result.isConfirmed) {
        status === 'delete' ? this.delete(id) : this.updateStatus(id);
      }
    })
  }

  updateStatus(id) {
    this.setState({
      loading: true
    })

    UpdateStatus(this.getToken(), id)
    .then(res => {
      this.setState({
        loading: false,
      })
      this.getSummary(this.state.active);
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }
  delete(id) {
    this.setState({
      loading: true
    })

    Delete(this.getToken(), id)
    .then(res => {
      this.setState({
        loading: false,
      })
      this.getSummary(this.state.active);
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  searchFunction = () => {
    this.getSummary(1);
    this.setState({ active: 1 });
  }

  render(){
    const statusFaq = [
      {
        value: '',
        label : 'All'
      },
      {
        value: 0,
        label : 'Enable'
      },
      {
        value: 1,
        label : 'Disabled'
      }
    ]
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start">
                  <input 
                    type="text" 
                    className="form-control mr-3 custom-50" 
                    name="search" 
                    placeholder="Search" 
                    value={this.state.seacrhParams} 
                    onChange={this.changeSearch} /> 
                  <select className="custom-select custom-30 costume-dropdown mr-3" onChange={(e) => this.setState({dropdown: e.target.value})} placeholder="Select Category" required>
                    <option disabled selected={this.state.dropdown === null || this.state.dropdown === '' ? true : false}>Select Category</option>
                    {this.state.dataDropdown.map((e) => (
                      <option key={e.value} value={e.value} selected={e.value === this.state.dropdown ? true : false} defaultValue={e.value === this.state.dropdown ? true : false}>{e.label}</option>
                    ))}
                  </select>
                  <select className="custom-select custom-30 costume-dropdown mr-3" onChange={(e) => this.setState({status: e.target.value})} placeholder="Select Category" required>
                    <option disabled selected={this.state.status === null || this.state.status === '' ? true : false}>Select Status</option>
                    {statusFaq.map((e) => (
                      <option key={e.value} value={e.value} selected={e.value === this.state.status ? true : false} defaultValue={e.value === this.state.status ? true : false}>{e.label}</option>
                    ))}
                  </select>
                  <button className="btn btn-success btn-sm pr-5 pl-5 mr-3" type="button" onClick={() => this.searchFunction()}>Search</button>
                  <Link to="/faq/add-faq" style={{width: '25%', marginRight: '1rem'}}>
                    <button className="btn btn-primary btn-sm mr-3" type="button" style={{width: '100%', padding: '10px'}}>Add New FAQ</button>
                  </Link>
                  <Link to="/faq/category-faq" style={{width: '30%'}}>
                    <button className="btn btn-danger btn-sm mr-3" type="button" style={{width: '100%', padding: '10px'}}>Manage Category</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ width: '4%' }}>
                        No
                      </th>
                      <th className="tableheh">
                        Category
                      </th>
                      <th className="tableheh">
                        Title
                        {/* <button onClick={(e) => this.onSortChange('event_name')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button> */}
                      </th>
                      <th className="tableheh center">
                        Status
                      </th>
                      <th className="tableheh center">
                        Sorting Number
                      </th>
                      <th className="tableheh">
                        Created At
                      </th>
                      <th className="tableheh">
                        Modified At
                      </th>
                      <th className="center tableheh" style={{width: '10%'}}>Action</th>
                    </tr>
                  </thead>
                  {this.state.loading ? 
                    <tbody>
                      <tr>
                        <td className="center" colSpan="8"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                      </tr>
                    </tbody> :
                    <tbody>
                      {this.state.data.map((row, index) => (
                        <tr key={index}>
                          <td>{index+1}</td>
                          <td>{row.faq_category && `${row.faq_category?.title_en} / ${row.faq_category?.title_id}`}</td>
                          <td>{row.title_en} / {row.title_id}</td>
                          <td className="center">
                            {row.status === 0 ?
                              <Badge variant="success" className="pointers" onClick={() => this.handleAlert(row.id, 'update')}>Enable</Badge>
                              :
                              <Badge variant="danger" className="pointers" onClick={() => this.handleAlert(row.id, 'update')}>Disabled</Badge>}
                          </td>
                          <td className="center">{row.order}</td>
                          <td>{this.dateTime(row.created_at)}</td>
                          <td>{this.dateTime(row.updated_at)}</td>
                          <td>
                            <ButtonToolbar style={{ justifyContent: 'center'}}>
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Detail Link
                                  </Tooltip>
                                }
                              >
                                <span className="btn-action" onClick={() => this.handleShow(row.id)}>
                                  <i style={{fontSize: '1rem', paddingRight: '0'}} className="far fa-eye" />
                                </span>
                              </OverlayTrigger>
                              &nbsp; | &nbsp;
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Edit Data
                                  </Tooltip>
                                }
                              >
                                <Link to={`/faq/${row.id}/edit-faq`} style={{width: '17%'}}>
                                  <span className="btn-action">
                                    <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-pencil-alt text-info" />
                                  </span>
                                </Link>
                              </OverlayTrigger>
                              &nbsp; | &nbsp;
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Delete Data
                                  </Tooltip>
                                }
                              >
                                <span className="btn-action" onClick={() => this.handleAlert(row.id)}>
                                  <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-trash-alt text-danger" />
                                </span>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </Table>
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered size="lg">
          <Modal.Header closeButton style={{padding: '20px 15px 20px 75px' }}>
            <Modal.Title><b>{this.state.dataDetail.title_en} / {this.state.dataDetail.title_id}</b></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{padding: '45px 75px'}}>
            <div className="mb-5">
              <span className="title-popup-style">Answer (EN)</span><br />
              <div
                className="desc-popup-style"
                dangerouslySetInnerHTML={{
                  __html: this.state.dataDetail.body_en,
                }}
              />
            </div>
            <div>
              <span className="title-popup-style">Answer (ID)</span><br />
              <div
                className="desc-popup-style"
                dangerouslySetInnerHTML={{
                  __html: this.state.dataDetail.body_id,
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}