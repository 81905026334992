import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { toAbsoluteUrl } from "../../_metronic/utils/utils";

const Datetime = ({ changeDate, selected, placeholder = 'date' }) => {
  const setDate = (val) => {
    changeDate(val);
  };
  const ExampleCustomInput = ({ value = '', onClick }) => (
    <div className="input-date mri-input" role="button" tabIndex={0} onClick={onClick} onKeyPress={onClick}>
      <span>{value === '' ? placeholder : value}</span>
      <button type="button" className="btn remove-frame-button" title="Reset Date" onClick={() => setDate(null)}>
        <img src={toAbsoluteUrl("/media/icons/calender.svg")} alt="" style={{ width: '65%'}}/>
      </button>
    </div>
  );
  return (
    <DatePicker
      popperPlacement="top-end"
      selected={selected}
      onChange={(date) => setDate(date)}
      customInput={<ExampleCustomInput />}
    />
  );
};
export default Datetime;