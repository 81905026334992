import React from 'react';
// import { Link } from "react-router-dom";
// import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import Swal from 'sweetalert2';
import moment from 'moment';
import { Form, Table, Modal, OverlayTrigger, ButtonToolbar, Tooltip } from "react-bootstrap";
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import SelectDate from '../../component/datetime';
// import Checkbox from '../../component/checkbox'
// import Pagination from "react-js-pagination";
import { getFee, DetailListContract, UpdateFee } from '../../crud/nft';
import { Summary } from '../../crud/config';
import { obj2ParamStr } from '../../helper';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class Merchant extends React.Component {
  historyEdit(id) {
    history.push("/leaderboard/"+id+"/editEvent");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      datas: [],
      fullname: '',
      addressContract: '',
      seacrhParams: '',
      date: null,
      selectedOption: 'default',
      toko1: 0,
      toko2: 0,
      merch1: 100,
      merch2: 0,
      collect2: 100,
      toko1Default: 0,
      toko2Default: 0,
      merch1Default: 100,
      merch2Default: 0,
      collect2Default: 100,
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleShow(address, name) {
    this.setState({ show: true, fullname: name, addressContract: address });
    this.getFeeAddress(address);
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleOptionChange = (changeEvent) => {
    this.setState({
      selectedOption: changeEvent.target.value
    });
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.getSummary()
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getSummary() {
    this.setState({
      loading: true
    })
    const histories = this.props.history.location.pathname.split('/');
    const id = histories[2];
    const currentParams = {};
    if(this.state.date){
      currentParams.date = moment(this.state.date).format('YYYY-MM-DD')
    }
    if(this.state.seacrhParams){
      currentParams.title = this.state.seacrhParams
    }
    const params = `?${obj2ParamStr(currentParams)}`;

    DetailListContract(this.getToken(), id, params)
    .then(res => {
      this.setState({
        datas: res.data.data || [],
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        datas:[],
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  getFeeAddress(address) {
    this.setState({
      loading: true
    })

    getFee(this.getToken(), address)
    .then(res => {
      const data = res.data.data;
      this.setState({
        selectedOption: data.hasCustomFee,
        toko1: data.marketplaceFee,
        toko2: data.ownerFee,
        merch1: 100 - parseFloat(data.marketplaceFee),
        merch2: data.merchantFee,
        collect2: 100 - parseFloat(data.ownerFee) - parseFloat(data.merchantFee),
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        dataDetail: {},
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  getConfig() {
    this.setState({
      loading: true
    })

    Summary(this.getToken())
    .then(res => {
      const data = res.data.data || {}
      this.setState({
        loading: false,
        toko1Default: data.official_merchant_first_fee_tokocrypto,
        toko2Default: data.official_merchant_second_fee_tokocrypto,
        merch1Default: 100 - parseFloat(data.official_merchant_first_fee_tokocrypto),
        merch2Default: data.official_merchant_second_fee_merchant,
        collect2Default: 100 - parseFloat(data.official_merchant_second_fee_tokocrypto) - parseFloat(data.official_merchant_second_fee_merchant),
      })
    })
    .catch(e => {
      console.log(e);
      this.emptyState();
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  submitFee() {
    this.setState({
      loadingSuspend: true
    })

    let data = {
        "contractAddress": this.state.addressContract,
        "type": this.state.selectedOption,
        "marketplaceFee": this.state.selectedOption === 'default' ? this.state.toko1Default : this.state.toko1,
        "ownerFee": this.state.selectedOption === 'default' ? this.state.toko2Default : this.state.toko2,
        "merchantFee": this.state.selectedOption === 'default' ? this.state.merch2Default : this.state.merch2,
    }
    
    UpdateFee(this.getToken(), data)
    .then(res => {
      this.setState({
        loadingSuspend: false,
        show: false,
      })
      this.getSummary();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loadingSuspend: false,
        show: false,
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }
  
  componentDidMount(){
    this.getSummary();
    this.getConfig();
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.getSummary()
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  }

  copyPaste(val){
    navigator.clipboard.writeText(val);
    this.handleNotif(val);
    return
  }
  handleNotif(val){
    Swal.fire({
      icon: 'success',
      title: `Address ${val} copied`,
      showConfirmButton: false,
      timer: 1500
    })
  }

  limitAddressDisplay = (address, length) => {
    return address.substring(0, length) + " ... " + address.substring(address.length-length);
  }

  change1 = (event) => {
    let valueMerch1 = 100 - parseFloat(event.target.value)
    this.setState({ toko1: event.target.value, merch1: valueMerch1 > 0 ? valueMerch1 : 0 });
  }
  change2 = (event) => {
    let valueMerch2 = 100 - parseFloat(event.target.value) - parseFloat(this.state.merch2)
    this.setState({ toko2: event.target.value, collect2: valueMerch2 > 0 ? valueMerch2 : 0 });
  }
  change3 = (event) => {
    let valueMerch2 = 100 - parseFloat(event.target.value) - parseFloat(this.state.toko2)
    this.setState({ merch2: event.target.value, collect2: valueMerch2 > 0 ? valueMerch2 : 0});
  }

  render(){
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start">
                  <input 
                    type="text" 
                    className="form-control mr-3" 
                    name="search" 
                    placeholder="Contract" 
                    value={this.state.seacrhParams} 
                    onChange={this.changeSearch} />
                  <div style={{width: '55%', marginRight: '1rem'}}>
                    <SelectDate
                      changeDate={(val) => this.setState({ date: val })}
                      selected={this.state.date}
                      placeholder="Created Date"/>
                  </div>
                  <button className="btn btn-success btn-sm pr-5 pl-5 mr-3" type="button" onClick={() =>  this.getSummary()}>Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table responsive style={{borderBottom: '1px solid #ebedf2'}}>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ width: '4%' }}>
                        No
                      </th>
                      <th className="tableheh" style={{ width: '46%' }}>
                        Contract Address
                      </th>
                      <th className="tableheh">
                        Created At
                      </th>
                      <th className="tableheh center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  {this.state.loading ? 
                    <tbody>
                      <tr>
                        <td className="center" colSpan="5"><ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} /></td>
                      </tr>
                    </tbody> :
                    <tbody>
                      {this.state.datas.contract?.map((item, index) => (
                        <tr key={item.id}>
                          <td className="align-middle">{index +1}</td>
                          <td className="align-middle">
                            <span className="btn-action email-user" onClick={() =>  this.copyPaste(item.contract_address)}>
                              <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                              <u>{item.contract_address}</u>
                            </span>
                          </td>
                          <td className="align-middle">{this.dateTime(item.created_at)}</td>
                          <td className="all-center">
                            <ButtonToolbar style={{ justifyContent: 'center'}}>
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    Detail Merchant
                                  </Tooltip>
                                }
                              >
                                <span className="btn-action" onClick={() => this.handleShow(item.contract_address, item.fullname)}>
                                  <i style={{fontSize: '1rem', paddingRight: '0'}} className="fas fa-pencil-alt text-info" />
                                </span>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </Table>
                {/* <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton style={{padding: '10px 10px 10px 30px' }}>
            <Modal.Title>Fee Setup</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{padding: '30px 35px'}}>
            <div className="row">
              <div className="col-md-4">
                <b style={{color: '#48465b'}}>{this.state.fullname}</b>
              </div>
              <div className="col-md-8">
                <span className="btn-action email-user" onClick={() =>  this.copyPaste(this.state.addressContract)}>
                  <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                  <u>{this.limitAddressDisplay(this.state.addressContract, 12)}</u>
                </span>
              </div>
              <div className="col-md-12 mt-4">
                <Form.Group style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '1rem'}}>
                  <Form.Label className="d-block mr-4" style={{ fontWeight: 'bold', color: '#48465b' }}>
                    Fee Type :
                  </Form.Label>
                  <Form.Check 
                    inline label="Default Settings"
                    type="radio"
                    value="default"
                    checked={this.state.selectedOption === 'default'} 
                    onChange={(e) => this.handleOptionChange(e)} />
                  <Form.Check 
                    inline label="Costumize"
                    type="radio"
                    value="custom"
                    checked={this.state.selectedOption === 'custom'} 
                    onChange={(e) => this.handleOptionChange(e)} />
                </Form.Group>
              </div>
              <div className="col-md-4">
                <b style={{ color: '#48465b' }} className="mb-4">Tokocrypto (%)</b>
                <input
                  style={{marginTop: '10px'}}
                  type="number" 
                  className="form-control" 
                  name="tokocrypto%" 
                  placeholder="0" 
                  value={this.state.selectedOption === 'default' ? this.state.toko1Default : this.state.toko1}
                  disabled={this.state.selectedOption === 'default'}
                  min="0"
                  max="100"
                  step=".01"
                  onChange={this.change1} />
                  <br />
                <input 
                  type="number" 
                  className="form-control" 
                  name="tokocrypto%" 
                  placeholder="0"
                  value={this.state.selectedOption === 'default' ? this.state.toko2Default : this.state.toko2}
                  disabled={this.state.selectedOption === 'default'}
                  min="0"
                  max="100"
                  step=".01"
                  onChange={this.change2} /> 
              </div>
              <div className="col-md-4">
                <b style={{ color: '#48465b' }} className="mb-4">Merchant (%)</b>
                <input
                  style={{marginTop: '10px'}}
                  type="number" 
                  className="form-control" 
                  name="merchant%" 
                  placeholder="0"
                  value={this.state.selectedOption === 'default' ? this.state.merch1Default : this.state.merch1}
                  disabled/>
                  <br />
                <input
                  type="number" 
                  className="form-control" 
                  name="merchant%" 
                  placeholder="0"
                  value={this.state.selectedOption === 'default' ? this.state.merch2Default : this.state.merch2}
                  disabled={this.state.selectedOption === 'default'}
                  min="0"
                  max="100"
                  step=".01"
                  onChange={this.change3} />
              </div>
              <div className="col-md-4">
                <b style={{ color: '#48465b' }} className="mb-4">Collector (%)</b>
                <input
                  style={{marginTop: '68px'}}
                  type="number" 
                  className="form-control" 
                  name="collector%" 
                  placeholder="0"
                  value={this.state.selectedOption === 'default' ? this.state.collect2Default : this.state.collect2}
                  disabled/>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5">
              <button
                className="btn btn-primary btn-sm pr-5 pl-5 mr-3"
                disabled={this.state.loadingSuspend}
                type="button" onClick={() => this.submitFee()}>Submit</button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}