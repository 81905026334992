import React from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Table, Badge, Modal, OverlayTrigger, ButtonToolbar, Tooltip } from "react-bootstrap";
import ReactLoading from 'react-loading';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from "react-js-pagination";
import { createBrowserHistory } from 'history';
import { SummaryCollectible, getExportCollectible } from '../../crud/history';
import { limitAddressDisplay, numberWithCommas, limitTitleDisplay } from '../../helper';
import SelectDate from '../../component/datetime';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class Transaction extends React.Component {
  historyEdit(id) {
    history.push("/leaderboard/"+id+"/editEvent");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      linkImage: process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/',
      show: false,
      loading: false,
      totalData: 0,
      data:[],
      dataDetail:{},
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderSort: 'desc',
      active: 1,
      params: '',
      startDate: null,
      endDate: null,
      priceTKOTicker: 0,
      status: '',
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleShow(index = 1) {
    this.setState({ show: true, dataDetail: this.state.data[index]});
  }

  handleClose() {
    this.setState({ show: false });
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.getSummary()
    })
  }

  dateTime = date => {
    var date1 = date
    return moment(date1).format('DD-MM-YY hh:m A');
  }

  getToken(){
    return localStorage.getItem("token");
  }

  getSummary() {
    this.setState({loading: true})

    let search = ''
    if(this.state.seacrhParams !== ''){
      search=`&q=${this.state.seacrhParams}`
    }

    let start = ''
    if(this.state.startDate !== null){
      start=`&start_date=${moment(this.state.startDate).format('YYYY-MM-DD')}`
    }
    let end = ''
    if(this.state.endDate !== null){
      end=`&end_date=${moment(this.state.endDate).format('YYYY-MM-DD')}`
    }

    let stat = ''
    if(this.state.status !== ''){
      stat=`&events=${this.state.status}`
    }

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?&order_by=${this.state.orderBy}&page=${this.state.active}&limit=${this.state.postPerPage}${search}${start}${end}${stat}`
    }else{
      params = `?page=${this.state.active}&limit=${this.state.postPerPage}${search}${start}${end}${stat}`
    }

    SummaryCollectible(this.getToken(), params)
    .then(res => {
      this.setState({
        totalData: res.data.data.total_data,
        data: res.data.data.data || [],
        postPerPage: res.data.data.per_page,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        data:[],
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }

  getExportSummary() {
    this.setState({loading: true})

    let search = ''
    if(this.state.seacrhParams !== ''){
      search=`&q=${this.state.seacrhParams}`
    }

    let start = ''
    if(this.state.startDate !== null){
      start=`&start_date=${moment(this.state.startDate).format('YYYY-MM-DD')}`
    }
    let end = ''
    if(this.state.endDate !== null){
      end=`&end_date=${moment(this.state.endDate).format('YYYY-MM-DD')}`
    }
    let stat = ''
    if(this.state.status !== ''){
      stat=`&events=${this.state.status}`
    }

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?&order_by=${this.state.orderBy}${search}${start}${end}${stat}`
    }else{
      params = `?${search}${start}${end}${stat}`
    }

    getExportCollectible(this.getToken(), params)
    .then(res => {
      if(res){
        toast.success(`Export is successful, the export transaction will be sent to your email.`);
      }
      this.setState({
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }

  componentDidMount(){
    this.getSummary();
  }

  searching(){
    this.setState({
      active: 1
    })
    this.getSummary();
  }

  copyPaste(val){
    navigator.clipboard.writeText(val);
    this.handleNotif(val);
  }
  handleNotif(val){
    Swal.fire({
      icon: 'success',
      title: `Address ${val} copied`,
      showConfirmButton: false,
      timer: 1500
    })
  }

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  }

  render () {
    const statusType = [
      {
        id: "",
        name: "All",
      },
      // {
      //   id: 1,
      //   name: "Listing",
      // },
      {
        id: 2,
        name: "Sales",
      },
      {
        id: 3,
        name: "Transfer",
      }
    ];
    const detailData = this.state.dataDetail;
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start">
                  <input 
                    type="text" 
                    className="form-control mr-3 font-8rem p-4"
                    style={{width: '70%'}}
                    name="search" 
                    placeholder="trx hash, trx block, contract adrress, from addrees, to adress" 
                    value={this.state.seacrhParams} 
                    onChange={this.changeSearch} />
                  <div style={{width: '25%', marginRight: '1rem'}}>
                    <SelectDate
                      changeDate={(val) => this.setState({ startDate: val })}
                      selected={this.state.startDate}
                      placeholder="Start Date"/>
                  </div>
                  <div style={{width: '25%', marginRight: '1rem'}}>
                    <SelectDate
                      changeDate={(val) => this.setState({ endDate: val })}
                      selected={this.state.endDate}
                      placeholder="End Date"/>
                  </div>
                  <select className="form-control font-8rem mr-3" onChange={(e) => this.setState({ status: e.target.value })} placeholder="roles" style={{width: '20%', height: '39px'}}>
                    {statusType.map((e) => (
                      <option key={e.id} value={e.id} defaultValue={e.id === this.state.status ? true : false}>{e.name}</option>
                    ))}
                  </select>
                  <button className="btn btn-success btn-sm pr-5 pl-5 mr-3" type="button" onClick={() => this.searching()}>Search</button>
                  <button className="btn btn-primary btn-sm pr-3 pl-3 mr-3" style={{ width: '20%'}} type="button" onClick={() => this.getExportSummary()} disabled={this.state.loading}>Export Report</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ width: '4%' }}>
                        No
                      </th>
                      <th className="tableheh" style={{ width: '24%' }}>
                        Artwork
                      </th>
                      <th className="tableheh">
                        From
                      </th>
                      <th className="tableheh">
                        To
                      </th>
                      <th className="tableheh">
                        Amount
                      </th>
                      <th className="tableheh">
                        Created At
                      </th>
                      <th className="tableheh">
                        Status
                      </th>
                      <th className="tableheh">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading
                      ? <tr>
                          <td colSpan={9}>
                            <ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(88, 103, 221)', height: '5%', width: '5%'}} type={'bars'} color={'#5867dd'} height={'5%'} width={'5%'} />
                          </td>
                        </tr>
                      :
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="align-middle">{index+1}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <img src={item.nft_data.image} className={'img-list-data'} alt="" />
                              <div>
                                <span className="btn-action email-user">
                                  {limitTitleDisplay(item.project_data.name, 13)}
                                </span>
                                <br />
                                <span className="name-user">
                                  {limitTitleDisplay(item.nft_data.name, 11)}
                                </span>
                                <br />
                                <span className="btn-action email-user" onClick={() =>  this.copyPaste(item.project_data.contract_address)}>
                                  <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                                  <u>{limitAddressDisplay(6, item.project_data.contract_address)}</u>
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">
                            {item.seller ? <>
                              <span className="from-to-user">{limitTitleDisplay(item.seller.fullname, 9)}</span> <br />
                              <span className="btn-action email-user" onClick={() =>  this.copyPaste(item.seller.crypto_address)}>
                                <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                                <u>{limitAddressDisplay(8, item.seller.crypto_address)}</u>
                              </span></> : '-'}
                          </td>
                          <td className="align-middle">
                            {item.buyer ? <>
                              <span className="from-to-user">{limitTitleDisplay(item.buyer?.fullname, 9)}</span> <br />
                              <span className="btn-action email-user" onClick={() =>  this.copyPaste(item.buyer?.crypto_address)}>
                                <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                                <u>{limitAddressDisplay(8, item.buyer?.crypto_address)}</u>
                              </span></> : '-'}
                          </td>
                          <td className="align-middle">
                            {item.price && item.price_bidr ? <>
                              <span className="ammount-tko">
                                {numberWithCommas(item.price)}
                                {' '}
                                {item.contract_token_data?.name}
                              </span>
                              <br />
                              <span>
                                {numberWithCommas(item.price_bidr)} IDR
                              </span></> : '-'}
                          </td>
                          <td className="align-middle">{this.dateTime(item.trx_date)}</td>
                          <td className="align-middle">
                            {item.event === 1 &&
                              <Badge variant="success">
                                Listing
                              </Badge>
                            }
                            {item.event === 2 &&
                              <Badge variant="success">
                                Sales
                              </Badge>
                            }
                            {item.event === 3 &&
                              <Badge variant="success">
                                Transfer
                              </Badge>
                            }
                          </td>
                          <td className="all-center">
                            <ButtonToolbar style={{ justifyContent: 'center'}}>
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    View Detail
                                  </Tooltip>
                                }
                              >
                                <span className="btn-action" onClick={() => this.handleShow(index)}>
                                  <i style={{fontSize: '1rem', paddingRight: '0'}} className="far fa-eye" />
                                </span>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          dialogClassName="modal-costume-width"
          centered
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton style={{padding: '0', borderBottom: '0'}}/>
          <Modal.Body className="pt-0">
            {Object.keys(detailData).length > 0 &&
              <>
                <div className="d-flex justify-content-center">
                  <div className="text-center mr-3">
                    <img src={detailData.nft_data.image} className="img-detail" alt="" />
                    <br />
                    <span className="name-user">
                      {detailData.nft_data.name}
                    </span>
                  </div>
                  <div className="d-flex flex-row align-self-center">
                    <div>
                      <div className="detail-trx-title">
                        <span>Contract ID: </span>
                      </div>
                      <div className="detail-trx-title">
                        <span>Token ID: </span>
                      </div>
                      <div className="detail-trx-title">
                        <span>Transaction ID: </span>
                      </div>
                      <div className="detail-trx-title">
                        <span>Transaction Hash: </span>
                      </div>
                      <div className="detail-trx-title">
                        <span>Transaction Date: </span>
                      </div>
                    </div>
                    <div className="ml-3">
                      <div className="detail-trx-address">
                        <span className="btn-action" onClick={() =>  this.copyPaste(detailData.contract_address)}>
                          <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                          <u>{limitAddressDisplay(12, detailData.contract_address)}</u>
                        </span>
                      </div>
                      <div className="detail-trx-address">
                        <span className="btn-action" onClick={() =>  this.copyPaste(detailData.token_id)}>
                          <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                          <u>{detailData.token_id}</u>
                        </span>
                      </div>
                      <div className="detail-trx-address">
                        <span className="btn-action">
                          <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                          <u>{detailData.id}</u>
                        </span>
                      </div>
                      <div className="detail-trx-address">
                        <span className="btn-action" onClick={() =>  this.copyPaste(detailData.trx_hash)}>
                          <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                          <u>{detailData.trx_hash && limitAddressDisplay(6, detailData.trx_hash)}</u>
                        </span>
                      </div>
                      <div className="detail-trx-address">
                        <span>{moment(detailData.trx_date).format('DD MMMM YYYY')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th colSpan="2">
                          <span className="name-user">Transaction Detail</span>
                        </th>
                        <th colSpan="2">
                          <span className="name-user">Commission Details</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="detail-trx-title">Price:</span>
                        </td>
                        <td className="text-right">
                          <span className="detail-trx-title">
                            {detailData.price && numberWithCommas(detailData.seller_fee)}
                            {' '}
                            {detailData.contract_token_data?.name}
                            {' '}
                            {detailData.seller_fee_bidr ?
                              <>
                                ({numberWithCommas(detailData.seller_fee_bidr)} IDR)
                              </> : '-'}
                          </span>
                        </td>
                        <td>
                          <span className="detail-trx-title">Creator Fee:</span>
                        </td>
                        <td className="text-right">
                          <span className="detail-trx-title">
                            {detailData.creator_fee && numberWithCommas(detailData.creator_fee)}
                            {' '}
                            {detailData.contract_token_data?.name}
                            {' '}
                            {detailData.creator_fee_bidr ?
                              <>
                                ({numberWithCommas(detailData.creator_fee_bidr)} IDR)
                              </> : '-'}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        {/* <td>
                          <span className="detail-trx-title">Comission Fee:</span>
                        </td>
                        <td>
                          <span className="detail-trx-title">
                            {numberWithCommas(detailData.merchant_earn_idr + detailData.toko_earn_idr)} IDR
                            {' '}
                            ({numberWithCommas(detailData.merchant_earn_tko + detailData.toko_earn_tko)} TKO)
                          </span>
                        </td> */}
                        <td>
                          <span className="detail-trx-title">Commision Fee:</span>
                        </td>
                        <td className="text-right">
                          <span className="detail-trx-title">
                            {detailData.creator_fee && detailData.platform_fee && numberWithCommas(detailData.creator_fee+detailData.platform_fee)}
                            {' '}
                            {detailData.contract_token_data?.name}
                            {' '}
                            {detailData.creator_fee_bidr && detailData.platform_fee_bidr ?
                              <>
                                ({numberWithCommas(detailData.creator_fee_bidr+detailData.platform_fee_bidr)} IDR)
                              </> : '-'}
                          </span>
                        </td>
                        <td>
                          <span className="detail-trx-title">Platform Fee:</span>
                        </td>
                        <td className="text-right">
                          <span className="detail-trx-title">
                            {detailData.platform_fee && numberWithCommas(detailData.platform_fee)}
                            {' '}
                            {detailData.contract_token_data?.name}
                            {' '}
                            {detailData.platform_fee_bidr ?
                              <>
                                ({numberWithCommas(detailData.platform_fee_bidr)} IDR)
                              </> : '-'}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="detail-trx-title">Total Amount:</span>
                        </td>
                        <td className="text-right" colSpan={3}>
                          <span className="detail-trx-title text-success">
                            {detailData.price && numberWithCommas(detailData.price)}
                            {' '}
                            {detailData.contract_token_data?.name}
                            {' '}
                            {detailData.price_bidr ?
                              <>
                                ({numberWithCommas(detailData.price_bidr)} IDR)
                              </> : '-'}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </>
            }
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}