import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Topbar from "./Topbar";
// import HMenu from "./HMenu/HMenu";
import AnimateLoading from "../../../app/partials/layout/AnimateLoading";
import KTHeader from "../../_assets/js/header";
import * as builder from "../../ducks/builder";
import { LayoutContextConsumer } from "../LayoutContext";

class Header extends React.Component {
  headerCommonRef = React.createRef();

  componentDidMount() {
    let options = {};
    if (
      this.headerCommonRef.current.getAttribute("data-ktheader-minimize") ===
      "1"
    ) {
      options["minimize.desktop.on"] = "kt-header--minimize";
      options["offset.desktop"] = 130;
    }

    // eslint-disable-next-line no-undef
    new KTHeader(this.headerCommonRef.current, options);
  }

  render() {
    const {
      // menuHeaderDisplay,
      headerAttributes,
      headerClasses
    } = this.props;
    return (
      <div
        className={`kt-header kt-grid__item ${headerClasses}`}
        id="kt_header"
        ref={this.headerCommonRef}
        {...headerAttributes}
      >
        <AnimateLoading />
        {/* <!-- begin: Header Menu --> */}
        {/* {menuHeaderDisplay && <HMenu />} */}
        {/* <!-- end: Header Menu --> */}
        {/* <!-- begin:: Header Topbar --> */}
        {/* <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden --> */}
        {/* {!menuHeaderDisplay && <div />} */}
        <div className="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
          <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-default">
            <ul className="kt-menu__nav ">
              <li className="kt-menu__item kt-menu__item--active kt-menu__item--here kt-menu__item--rel" aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
              <LayoutContextConsumer>
                {({ subheader: { title } }) => (
                  <>
                    <h3 className="title-menu-config">{title}</h3>
                  </>
                )}
                </LayoutContextConsumer>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="kt-subheader__main">
        </div> */}
        <Topbar />
        {/* <!-- end:: Header Topbar --> */}
      </div>
    );
  }
}

const mapStateToProps = store => ({
  headerClasses: builder.selectors.getClasses(store, {
    path: "header",
    toString: true
  }),
  headerAttributes: builder.selectors.getAttributes(store, { path: "header" }),
  menuHeaderDisplay: objectPath.get(
    store.builder.layoutConfig,
    "header.menu.self.display"
  ),
  fluid:
    objectPath.get(store.builder.layoutConfig, "header.self.width") === "fluid"
});

export default connect(mapStateToProps)(Header);
