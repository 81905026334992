import React, { useEffect, useState } from "react";
import clsx from "clsx";
import moment from 'moment';
import ReactLoading from 'react-loading';
import { addDays } from "date-fns";
import 'react-toastify/dist/ReactToastify.css'; 
import ComponentChart from '../../widgets/SalesBarChart';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { createBrowserHistory } from 'history';
import { SummaryCollectorArtwork, SummaryCollector, SummaryMerchant, SummaryMerchantArtwork, SummaryTransaction } from "../../crud/dashboard";

const history =  createBrowserHistory();

export default function Dashboard() {
  const [loading, setLoading] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 7));


  // const typeDownload = [
  //   {
  //     id: 0,
  //     name: 'CSV',
  //   },
  //   {
  //     id: 1,
  //     name: 'Excel',
  //   },
  // ]

  const getToken = () => {
    return localStorage.getItem("token")
  };

  const [responseMA, setResponseMA] = useState(0);
  const [responseM, setResponseM] = useState(0);
  const [responseCA, setResponseCA] = useState(0);
  const [responseC, setResponseC] = useState(0);
  const [labelHC, setLabelHC] = useState([]);
  const [dataHC, setDataHC] = useState([]);
  const [dataHCA, setDataHCA] = useState([]);
  const [labelHM, setLabelHM] = useState([]);
  const [dataHM, setDataHM] = useState([]);
  const [dataHMA, setDataHMA] = useState([]);
  const getSummary = async () => {
    setLoading(true);
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    const params = `?startDate=${start}&endDate=${end}`;
    const paramsCol = `?start_date=${start}&end_date=${end}&user_type=2`;
    const paramsMerch = `?start_date=${start}&end_date=${end}&user_type=3`;
    try {
      let responseMA = await SummaryMerchantArtwork(getToken(), params);
      let responseM = await SummaryMerchant(getToken(), params);
      let responseCA = await SummaryCollectorArtwork(getToken(), params);
      let responseC = await SummaryCollector(getToken(), params);
      let responsHC = await SummaryTransaction(getToken(), paramsCol);
      let responsHM = await SummaryTransaction(getToken(), paramsMerch);
      setResponseMA(responseMA.data.data.total);
      setResponseM(responseM.data.data.total);
      setResponseCA(responseCA.data.data.total);
      setResponseC(responseC.data.data.total);

      let labHC = [];
      let datHC = [];
      let dataHCA = [];
      if(responsHC.data.data){
        responsHC.data.data.forEach(element => {
          labHC.push(element.trx_date);
          datHC.push(parseFloat(element.trx_count));
          dataHCA.push(parseFloat(element.trx_total_price_idr))
        });
      }
      let labHM = [];
      let datHM = [];
      let dataHMA = [];
      if(responsHM.data.data){
        responsHM.data.data.forEach(element => {
          labHM.push(element.trx_date);
          datHM.push(parseFloat(element.trx_count));
          dataHMA.push(parseFloat(element.trx_total_price_idr))
        });
      }
      setLabelHC(labHC);
      setDataHC(datHC);
      setDataHCA(dataHCA);
      setLabelHM(labHM);
      setDataHM(datHM);
      setDataHMA(dataHMA);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if(error.response){
        if(error.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          setInterval(function(){ 
            history.push("/auth");
            window.location.reload();
          }, 1500);
        }
        else{
          toast.error(`error code: ${error.response.data.code} message: ${error.response.data.message}`);
        }
      }
    }
  };

  useEffect(() => {
    getToken();
    getSummary();
  }, []);

  return (
    <>
      <div className="head">
        <div className="kt-portlet" style={{ marginBottom: '10px' }}>
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12">
                  <div className="kt-section mb-0">
                    <div className="row">
                      <div className="col-md-2">
                        <DatePicker
                          className="form-control font-8rem"
                          popperPlacement="right-start"
                          selected={startDate}
                          maxDate={new Date()}
                          onChange={(e) => e && setStartDate(e)}
                        />
                      </div>
                      <div className="col-md-2">
                        <DatePicker
                          className="form-control font-8rem"
                          popperPlacement="right-start"
                          selected={endDate}
                          maxDate={addDays(startDate, 60)}
                          minDate={startDate}
                          onChange={(e) => e && setEndDate(e)}
                        />
                      </div>
                      <div className="col-md-8">
                        <button
                          type="button"
                          style={{ width: '20%', padding: '0.65rem 1rem' }}
                          onClick={getSummary}
                          className={`btn btn-success btn-sm ${clsx(
                            {
                              "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                            }
                          )}`}
                          disabled={loading}
                        >Search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0">
          <div className="col-md-3" style={{ padding: '0 5px 0 0' }}>
            <div className="kt-portlet" style={{ marginBottom: '10px' }}>
              <div className="kt-portlet__body">
                <div className="kt-portlet__preview">
                  {loading ?
                    <div style={{ height: '104px' }}>
                      <ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(52, 191, 163)', height: '5%', width: '15%', top: '20%'}} type={'spin'} color={'#5867dd'} height={'5%'} width={'5%'} />
                    </div>
                  :
                    <div className="kt-section mb-0">
                      <div className="dasboard-content-title-card">
                        <span>Total Merchant’s Artworks</span>
                      </div>
                      <div className="dasboard-content-value-card">
                        <span>{responseMA}</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3" style={{ padding: '0 5px' }}>
            <div className="kt-portlet" style={{ marginBottom: '10px' }}>
              <div className="kt-portlet__body">
                <div className="kt-portlet__preview">
                  {loading ?
                    <div style={{ height: '104px' }}>
                      <ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(52, 191, 163)', height: '5%', width: '15%', top: '20%'}} type={'spin'} color={'#5867dd'} height={'5%'} width={'5%'} />
                    </div>
                  :
                    <div className="kt-section mb-0">
                      <div className="dasboard-content-title-card">
                        <span>Total Merchants</span>
                      </div>
                      <div className="dasboard-content-value-card">
                        <span>{responseM}</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3" style={{ padding: '0 5px' }}>
            <div className="kt-portlet" style={{ marginBottom: '10px' }}>
              <div className="kt-portlet__body">
                <div className="kt-portlet__preview">
                  {loading ?
                    <div style={{ height: '104px' }}>
                      <ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(52, 191, 163)', height: '5%', width: '15%', top: '20%'}} type={'spin'} color={'#5867dd'} height={'5%'} width={'5%'} />
                    </div>
                  :
                    <div className="kt-section mb-0">
                      <div className="dasboard-content-title-card">
                        <span>Total Collector’s Artworks</span>
                      </div>
                      <div className="dasboard-content-value-card">
                        <span>{responseCA}</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3" style={{ padding: '0 0 0 5px' }}>
            <div className="kt-portlet" style={{ marginBottom: '10px' }}>
              <div className="kt-portlet__body">
                <div className="kt-portlet__preview">
                  {loading ?
                    <div style={{ height: '104px' }}>
                      <ReactLoading style={{position: 'relative', left: '50%', fill: 'rgb(52, 191, 163)', height: '5%', width: '15%', top: '20%'}} type={'spin'} color={'#5867dd'} height={'5%'} width={'5%'} />
                    </div>
                  :
                    <div className="kt-section mb-0">
                      <div className="dasboard-content-title-card">
                        <span>Total Collectors</span>
                      </div>
                      <div className="dasboard-content-value-card">
                        <span>{responseC}</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet" style={{ marginBottom: '10px' }}>
          <div className="kt-portlet__body p-0">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <ComponentChart
                  title="Merchant's NFT Total Transactions"
                  datas={dataHM}
                  load={loading}
                  label={labelHM}/>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet" style={{ marginBottom: '10px' }}>
          <div className="kt-portlet__body p-0">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <ComponentChart
                  title="Merchant's NFT Total Transactions Amount"
                  datas={dataHMA}
                  load={loading}
                  label={labelHM}/>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet" style={{ marginBottom: '10px' }}>
          <div className="kt-portlet__body p-0">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <ComponentChart
                  title="Collector's NFT Total Transactions"
                  datas={dataHC}
                  load={loading}
                  label={labelHC}/>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet" style={{ marginBottom: '10px' }}>
          <div className="kt-portlet__body p-0">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <ComponentChart
                  title="Collector's NFT Total Transactions Amount"
                  datas={dataHCA}
                  load={loading}
                  label={labelHC}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
