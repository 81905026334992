export const obj2ParamStr = (obj) => Object.entries(obj).map(([key, val]) => `${key}=${val}`).join('&');
export const limitAddressDisplay = (length, string) => `${string.substring(0, length)} ... ${string.substring(string.length - length)}`;
export const limitTitleDisplay = (string, length) => {
    let display = '';
    if (string) {
        if (string.length > length) {
            display = `${string.substring(0, length)} ...`;
        } else {
            display = `${string.substring(0, length)}`;
        }
    }

    return display;
};
export const numberWithCommas = (x, commas = 6) => {
    const checkNumber = x.toFixed(commas).toString().split('.');
    let numberCommas = '';
    if (checkNumber[1] === '000000') {
        numberCommas = checkNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
        numberCommas = `${checkNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${checkNumber[1]}`;
    }
    return numberCommas;
};
export const isValidHttpUrl = (string) => {
    let url;
    
    try {
        url = new URL(string);
    } catch (_) {
        return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
}
export const convertTZ = (date, tzString = "Asia/Jakarta") => {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
  }