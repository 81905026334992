import React from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import List from "../data";
import { ButtonGroup, Button, Table, Badge, Modal, OverlayTrigger, ButtonToolbar, Tooltip } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import InfiniteScroll from 'react-infinite-scroll-component';
import SelectDate from '../../component/datetime';
import ViewBB from '../../component/thumbnailBB';
import Pagination from "react-js-pagination";
import { DetailBB, SummaryBB, SummaryBBNFTSeries } from '../../crud/artwork';
import { obj2ParamStr } from '../../helper';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class Blindbox extends React.Component {
  historyEdit(id) {
    history.push("/leaderboard/"+id+"/editEvent");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      linkImage: process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/',
      show: false,
      loading: false,
      totalData: 0,
      data:[],
      datasNFT: [],
      datasSeries: [],
      dataCategory:[],
      postPerPage: 15,
      seacrhParams: '',
      orderBy: '',
      orderSort: 'desc',
      active: 1,
      params: '',
      date: null,
      isCheckAll: false,
      isCheck: [],
      list: [],
      CategoryStatus: '',
      status: '',
      rejectTrue: false,
      rejectReason: '',
      activeTab: false,
      activeTabList: false,
      idChange: 0,
      dataDetail: {},
      hasMore: true,
      hasMores: true,
      currentPage: 1,
      currentPageS: 1,
      idDetail: null,
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleShow(id) {
    this.setState({ show: true, idDetail: id});
    this.detailBB(id);
    this.getShowNFT(id);
    this.getShowBBSeries(id);
  }
  handleClose() {
    this.setState({ show: false, hasMore: true, hasMores: true, currentPage: 1, currentPageS: 1 });
  }

  handlePageChange = (pageNumber) => {
    let activePage = pageNumber
    this.setState({
      active: activePage
    }, () => {
      this.getSummary()
    })
  }

  dateTime = date => {
    var date1 = date
    var fmtDate = moment(date1).format('DD-MM-YY hh:m A');

    return fmtDate
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getSummary() {
    this.setState({
      loading: true
    })

    let search = this.state.seacrhParams !== '' ? `&title=${this.state.seacrhParams}` : '';
    let statusCat = this.state.CategoryStatus !== '' ? `&status=${parseInt(this.state.CategoryStatus)}` : '';
    let dates = this.state.date ? `&date=${moment(this.state.date).format('YYYY-MM-DD')}` : '';

    let params = '';
    if(this.state.orderBy !== ''){
      params = `?&order_by=${this.state.orderBy}&order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}${search}${statusCat}${dates}`
    }else{
      params = `?&order_sort=${this.state.orderSort}&page=${this.state.active}&limit=${this.state.postPerPage}${search}${statusCat}${dates}`
    }

    SummaryBB(this.getToken(), params)
    .then(res => {
      this.setState({
        totalData: res.data.data.total_data,
        data: res.data.data.data || [],
        postPerPage: res.data.data.per_page,
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        data:[],
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  detailBB(id){
    this.setState({
      loading: true
    })

    DetailBB(this.getToken(), id)
    .then(res => {
      this.setState({
        loading: false,
        dataDetail: res.data.data || {}
      })
      this.getSummary();
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  getShowNFT(id, page = 1) {
    this.setState({
      loading: true
    })
    const currentParams = {
      page,
      limit: this.state.postPerPage,
      type: 'nft',
      id,
    };
    const params = `?${obj2ParamStr(currentParams)}`;

    SummaryBBNFTSeries(this.getToken(), params)
    .then(res => {
      if (page === 1) {
        this.setState({
          datasNFT: res.data.data.data || [],
          loading: false,
          currentPage: res.data.data.current_page,
          hasMore: true,
        })
      } else if (res.data.data.data.length) {
        this.setState({
          datasNFT: Array.from(this.state.datasNFT).concat(res.data.data.data),
          loading: false,
          currentPage: res.data.data.current_page,
        })
      } else {
        this.setState({
          hasMore: false,
        })
      }
      this.setState({
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        datasNFT:[],
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  getShowBBSeries(id, page = 1) {
    this.setState({
      loading: true
    })
    const currentParams = {
      page,
      limit: this.state.postPerPage,
      type: 'series',
      id,
    };
    const params = `?${obj2ParamStr(currentParams)}`;

    SummaryBBNFTSeries(this.getToken(), params)
    .then(res => {
      if (page === 1) {
        this.setState({
          datasSeries: res.data.data.data || [],
          loading: false,
          currentPageS: res.data.data.current_page,
          hasMores: true,
        })
      } else if (res.data.data.data.length) {
        this.setState({
          datasSeries: Array.from(this.state.datasSeries).concat(res.data.data.data),
          loading: false,
          currentPageS: res.data.data.current_page,
        })
      } else {
        this.setState({
          hasMores: false,
        })
      }
      this.setState({
        loading: false,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        datasSeries:[],
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  componentDidMount(){
    this.getSummary();
    this.setState({
      list: List.getList()
    })
  }

  copyPaste(val){
    navigator.clipboard.writeText(val);
    this.handleNotif(val);
    return
  }
  handleNotif(val){
    Swal.fire({
      icon: 'success',
      title: `Address ${val} copied`,
      showConfirmButton: false,
      timer: 1500
    })
  }

  onSortChange = async (name) => {
    
    const { orderSort } = this.state;
    let nextSort;

    if (orderSort === 'desc') nextSort = 'asc';
    if (orderSort === 'asc') nextSort = 'desc';

    await this.setState({
      orderSort: nextSort,
      orderBy: name,
    });
    
    this.getSummary()
  };

  changeSearch = (event) => {
    this.setState({ seacrhParams: event.target.value, active: 1 });
  }

  handleDelete(){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // this.delete(id);
      }
    })
  }

  limitAddressDisplay = (address, length) => {
    return address.substring(0, length) + " ... " + address.substring(address.length-length);
  }

  handleSearch = () => {
    this.setState({
      active: 1
    }, () => {
      this.getSummary()
    })
  }
  handleNextNFT = () => {
    this.getShowNFT(this.state.idDetail, this.state.currentPage + 1);
    this.setState({currentPage: this.state.currentPage+1})
  };
  handleNextSeries = () => {
    this.getShowBBSeries(this.state.idDetail, this.state.currentPageS + 1);
    this.setState({currentPageS: this.state.currentPageS+1})
  };

  render(){
    const statusBB = [
      {
        id: "",
        label: 'All',
      },
      {
        id: "0",
        label: 'Draft',
      },
      {
        id: "1",
        label: 'Confirm',
      },
      {
        id: "2",
        label: 'Minting',
      },
      {
        id: "3",
        label: 'Minted',
      },
    ];
    return (
      <div className="head" style={{fontSize: '10px'}}>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-start">
                  <input 
                    type="text" 
                    className="form-control mr-3" 
                    name="Artwork Title" 
                    placeholder="Artwork Title" 
                    value={this.state.seacrhParams} 
                    onChange={this.changeSearch} />
                  <div style={{width: '25%', marginRight: '1rem'}}>
                    <SelectDate
                      changeDate={(val) => this.setState({ date: val })}
                      selected={this.state.date}
                      placeholder="Created Date"/>
                  </div>
                  <select className="form-control font-8rem mr-3" onChange={(e) => this.setState({ CategoryStatus: e.target.value })} placeholder="roles" style={{width: '25%', height: '39px'}}>
                    {/* <option disabled selected={this.state.status === '' ? true : false}>status</option> */}
                    {statusBB.map((e) => (
                      <option key={e.id} value={e.id} defaultValue={e.id === this.state.CategoryStatus ? true : false}>{e.label}</option>
                    ))}
                  </select>
                  <button className="btn btn-success btn-sm pr-5 pl-5 mr-3" type="button" onClick={() => this.handleSearch()}>Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body pddlessForce">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <Table responsive style={{borderBottom: '1px solid #ebedf2'}}>
                  <thead>
                    <tr>
                      <th className="tableheh" style={{ width: '4%' }}>
                        No
                      </th>
                      <th className="tableheh" style={{ width: '31%' }}>
                        Artworks
                        {/* <button onClick={(e) => this.onSortChange('event_name')} className="sort-button">
                          <i className={`fas fa-${sortTypes[this.state.orderSort].class}`} />
                        </button> */}
                      </th>
                      <th className="tableheh">
                        Created At &nbsp;
                      </th>
                      <th className="tableheh">
                        Status
                      </th>
                      <th className="tableheh center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.map((item, index) => (
                      <tr key={index}>
                        <td className="align-middle">{index+1}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <ViewBB data={item} />
                            <div>
                              <span className="name-user">{item.title}</span>
                              <br />
                              {/* <span className="btn-action email-user" onClick={() =>  this.copyPaste(item.contract.contract_address)}>
                                <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                                <u>{this.limitAddressDisplay(item.contract.contract_address, 12)}</u>
                              </span> */}
                            </div>
                          </div>
                        </td>
                        <td className="align-middle">{this.dateTime(item.created_at)}</td>
                        <td className="align-middle">
                          {item.status === 0 && <Badge variant="danger">Draft</Badge>}
                          {item.status === 1 && <Badge variant="warning">Confirm</Badge>}
                          {item.status === 2 && <Badge variant="warning">Minting</Badge>}
                          {item.status === 3 && <Badge variant="success">Minted</Badge>}
                        </td>
                        <td className="all-center">
                          <ButtonToolbar style={{ justifyContent: 'center'}}>
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Detail Toko Surprise
                                </Tooltip>
                              }
                            >
                              <span className="btn-action" onClick={() => this.handleShow(item.id)}>
                                <i style={{fontSize: '1rem', paddingRight: '0'}} className="far fa-eye" />
                              </span>
                            </OverlayTrigger>
                          </ButtonToolbar>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-center align-items-center pagination-data">
                  <Pagination
                    activePage={this.state.active}
                    itemsCountPerPage={this.state.postPerPage}
                    itemClass="page-item"
                    linkClass="page-link"
                    totalItemsCount={this.state.totalData}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={() => this.handleClose()}
          dialogClassName="modal-costume-width"
          centered
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton style={{padding: '0', borderBottom: '0'}}/>
          <Modal.Body className="pt-0">
            {/* <button type="button" className="close" onClick={() => this.handleClose()}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button> */}
            <div className="detail-artwork">
              {this.state.dataDetail && <>
                <div className="detai-artwork-container-top">
                  <img src={`${this.state.linkImage}${this.state.dataDetail.uri_image}`} className='img-detail' alt="" />
                  {/* <ViewBB data={this.state.dataDetai} /> */}
                  <div style={{width: '100%'}}>
                    <div className="detail-artwork-nft-name">
                      <span>{this.state.dataDetail.title}</span>
                    </div>
                    <div className="detail-artwork-name">
                      <span>{this.state.dataDetail.creator?.fullname}</span>
                    </div>
                    <div className="detail-artwork-created-at">
                      <span>Created at {moment(this.state.dataDetail.created_at).format('DD MMMM YYYY')}</span>
                    </div>
                    <div className="d-flex" style={{marginBottom: '20px'}}>
                      <div className="detail-address-price">
                        <span>Contract ID: </span>&nbsp;
                      </div>
                      <span className="btn-action detail-address mr-2" onClick={() =>  this.copyPaste(this.state.dataDetail.contract?.contract_address || '0')}>
                        <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                        <u>{this.limitAddressDisplay(this.state.dataDetail.contract?.contract_address || '0', 6)}</u>
                      </span>
                      <div>
                      </div>
                      <div className="detail-address-price">
                        <span>Reveal Time: </span>&nbsp;
                      </div>
                      <span className="btn-action detail-address mr-2">
                        {this.state.dataDetail.reveal_time_sec}
                      </span>
                      <div>
                      </div>
                    </div>
                    <div style={{marginBottom: '20px'}}>
                      <ButtonGroup>
                        <Button variant={!this.state.activeTab ? 'outline-success' : 'outline-secondary'} onClick={() => this.setState({activeTab: false})}>Desc (ID)</Button>
                        <Button variant={!this.state.activeTab ? 'outline-secondary' : 'outline-success'} onClick={() => this.setState({activeTab: true})}>Desc (EN)</Button>
                      </ButtonGroup>
                    </div>
                    <div className="detail-desc">
                      {this.state.activeTab ?
                        <div
                          className="desc"
                          dangerouslySetInnerHTML={{
                            __html: this.state.dataDetail.description_id,
                          }}
                        />
                        :
                        <div
                          className="desc"
                          dangerouslySetInnerHTML={{
                            __html: this.state.dataDetail.description_en,
                          }}
                        />
                      }
                    </div>
                  </div>
                </div>
                <div style={{marginBottom: '20px'}}>
                  <ButtonGroup>
                    <Button variant={!this.state.activeTabList ? 'outline-success' : 'outline-secondary'} onClick={() => this.setState({activeTabList: false})}>Series List</Button>
                    <Button variant={!this.state.activeTabList ? 'outline-secondary' : 'outline-success'} onClick={() => this.setState({activeTabList: true})}>NFT List</Button>
                  </ButtonGroup>
                </div>
                <div style={{ height: '200px' }}>
                  {!this.state.activeTabList ?
                    <InfiniteScroll
                      scrollableTarget="scroll-parent"
                      dataLength={this.state.datasSeries.length}
                      next={this.handleNextSeries}
                      hasMore={this.state.hasMores}
                    >
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Other Series</th>
                            <th className="center">Token ID</th>
                            <th className="center">Price</th>
                            <th className="center">Status</th>
                            <th className="center">Current Owner</th>
                          </tr>
                        </thead>
                        <tbody id="scroll-parent">
                          {this.state.datasSeries?.map((item, i) => (
                            <tr key={i}>
                              <td>#{item.blindbox_series}</td>
                              <td className="center">{item.token_id}</td>
                              <td className="center">
                                {item.price ?
                                <span>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'IDR' }).format(item.price)}</span> : '-'}
                              </td>
                              <td className="center">
                                {item.status === 0 && 'Not Opened'}
                                {item.status === 1 && 'Opened'}
                                {item.status === 2 && 'On Sale'}
                              </td>
                              <td className="center">{item.owner ? item.owner.fullname : 
                                <span className="btn-action detail-address mr-2" onClick={() =>  this.copyPaste(item.owner_address || '0')}>
                                  <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                                  <u>{this.limitAddressDisplay(item.owner_address, 6)}</u>
                                </span>}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </InfiniteScroll> :
                    <InfiniteScroll
                      scrollableTarget="scroll-parent"
                      dataLength={this.state.datasNFT.length}
                      next={this.handleNextNFT}
                      hasMore={this.state.hasMore}
                    >
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>NFT Title</th>
                            <th className="center">NFT Contract ID</th>
                            <th className="center">Token ID</th>
                            <th className="center">NFT belong to</th>
                            <th className="center">Current Owner</th>
                          </tr>
                        </thead>
                        <tbody id="scroll-parent">
                          {this.state.datasNFT?.map((item, i) => (
                            <tr key={i}>
                              <td>{item.artwork.title}</td>
                              <td className="center">
                                <span className="btn-action detail-address mr-2" onClick={() =>  this.copyPaste(item.artwork.contract.contract_address || '0')}>
                                  <i style={{fontSize: '1rem', paddingRight: '7px'}} className="far fa-copy" />
                                  <u>{this.limitAddressDisplay(item.artwork.contract.contract_address,6)}</u>
                                </span>
                              </td>
                              <td className="center">{item.artwork.token_id}</td>
                              <td className="center">{item.blindbox.title} {item.blind_box_series_id && `#${item.blindbox_series.blindbox_series}`}</td>
                              <td className="center">{item.artwork.owner.fullname}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </InfiniteScroll>}
                </div>
              </>}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}