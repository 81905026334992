import axios from "axios";

const baseUrl =
  process.env.REACT_APP_ENV !== "production"
    ? "https://api-tokomall-demo.tcdx.id"
    : "https://service.tkonft.art";
const instance = (process.env.REACT_APP_ENV === 'demo') ? 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  }) :
  (process.env.REACT_APP_ENV === 'production') ? 
    axios.create({
      baseURL: 'https://service.tkonft.art',
    }) : 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  });

export function SummaryListUserPartner(token, search = '', page = 1) {
  return instance.get(`admin/tkonft/featured-partners/user/list?limit=20&page=${page}${search !== '' ? `&q=${search}` : ''}`,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}
// featured partner
export function SummaryPartner(token) {
  return instance.get(`admin/tkonft/featured-partners-list`, {
    headers: {
      Authorization: token,
      "X-TCDX-SIGNATURE": "salamtothemoon",
    },
  });
}
export function insertPartner(token, data) {
  return instance.post("/admin/tkonft/add-featured-partners", data, {
    headers: {
      Authorization: token,
      "X-TCDX-SIGNATURE": "salamtothemoon",
    },
  });
}
export function removePartner(token, id) {
  return instance.delete(`/admin/tkonft/remove-featured-partners/${id}`, {
    headers: {
      Authorization: token,
      "X-TCDX-SIGNATURE": "salamtothemoon",
    },
  });
}
export function UpdatePartner(token, data) {
  return instance.put("/admin/tkonft/featured-partners-sequence", data, {
    headers: {
      Authorization: token,
      "X-TCDX-SIGNATURE": "salamtothemoon",
    },
  });
}

// detail featured partner
export function DetailPartner(token, id) {
  return instance.get(`/admin/tkonft/featured-partners/${id}`, {
    headers: {
      Authorization: token,
      "X-TCDX-SIGNATURE": "salamtothemoon",
    },
  });
}
export function ListNftProject(token, id, page = 1, search = '') {
  return instance.get(`admin/tkonft/featured-partners/${id}/project/list?limit=30&page=${page}&q=${search}`, {
    headers: {
      Authorization: token,
      "X-TCDX-SIGNATURE": "salamtothemoon",
    },
  });
}
export function ListNftPartner(token, id, page = 1, searchData) {
  const resultData = instance.get(
    `/admin/tkonft/featured-partners/${id}/items?limit=30&page=${page}&q=${searchData}`,
    {
      headers: {
        Authorization: token,
        "X-TCDX-SIGNATURE": "salamtothemoon",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return resultData;
}
export function AvailableSetNft(token, id) {
  return instance.get(`admin/tkonft/max-featured-nft?id_featured_partners=${id}`, {
    headers: {
      Authorization: token,
      "X-TCDX-SIGNATURE": "salamtothemoon",
    },
  });
}
export function insertNftPartner(token, id, data) {
  return instance.post(`/admin/tkonft/featured-partners/${id}/items`, data, {
    headers: {
      Authorization: token,
      "X-TCDX-SIGNATURE": "salamtothemoon",
    },
  });
}
export function UpdateType(token, id, data) {
  return instance.put(`/admin/tkonft/featured-partners/${id}`, data, {
    headers: {
      Authorization: token,
      "X-TCDX-SIGNATURE": "salamtothemoon",
    },
  });
}
export function UpdatePosisiItemPartner(token, id, data) {
  return instance.put(
    `/admin/tkonft/featured-partners/${id}/update-position`,
    data,
    {
      headers: {
        Authorization: token,
        "X-TCDX-SIGNATURE": "salamtothemoon",
      },
    }
  );
}
export function removeNftPartner(token, partnerId, id) {
  return instance.delete(
    `/admin/tkonft/featured-partners/${partnerId}/items/${id}`,
    {
      headers: {
        Authorization: token,
        "X-TCDX-SIGNATURE": "salamtothemoon",
      },
    }
  );
}

// featured partner
export function SummaryArtwork(token) {
  return instance.get("/admin/tkonft/featured-artworks",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function UpdateArtwork(token, data) {
  return instance.put("/admin/tkonft/featured-artworks-update", data,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    }
  });
}

export function SummaryMerchant(token) {
  return instance.get("/admin/tkonft/featured-merchants",{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}

export function UpdateMerchant(token, data) {
  return instance.put("/admin/tkonft/featured-merchants-update", data,{
    headers: {
      'Authorization': token,
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    }
  });
}