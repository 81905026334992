import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import clsx from "clsx";
import moment from 'moment';
import { Form, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';
import { convertTZ } from '../../helper';
import { DetailSummaryBanners, CreateBanner, UpdateBanner } from '../../crud/banner';
import SelectDate from '../../component/datetime';
require("bootstrap/scss/bootstrap.scss");

const ManagementBanner = () => {
  const history =  createBrowserHistory();
  const linkImage = useState(process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/')
  const [titleBanner, setTitleBanner] = useState('');
  const [urlRedirection, setUrlRedirection] = useState('');
  const [bannerType, setBannerType] = useState(0);
  const [sorting, setSorting] = useState('');
  const [bannerImage, setBannerImage] = useState(null);
  const [bannerImageURL, setBannerImageURL] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [statusBanner, setStatusBanner] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [editPage, setEditPage] = useState(false);
  const [pageID, setPageID] = useState(0);

  const BannerTypes = [
    {
      value: 0,
      label: "Hero Section",
    },
    {
      value: 1,
      label: "Our NFT",
    },
  ];

  const Status = [
    {
      value: 0,
      label: "Enable",
    },
    {
      value: 1,
      label: "Disabled",
    },
  ];

  const ErrorFunction = (erorr) => {
    console.log(erorr);
    setIsLoading(false);
    if(erorr.response){
      if(erorr.response.data.code === 401){
        toast.error("token has expired");
        localStorage.clear();
        history.push("/auth");
        window.location.reload();
      }
      else{
        toast.error(`error code: ${erorr.response.data.code} message: ${erorr.response.data.message}`);
      }
    }
  };

  const splitDataBanner = (dataBanner) => {
    setTitleBanner(dataBanner.title);
    setUrlRedirection(dataBanner.url);
    setStatusBanner(dataBanner.status)
    setBannerType(dataBanner.type);
    setSorting(dataBanner.order);
    setBannerImageURL(dataBanner.image);
    setStartDate(convertTZ(dataBanner.publish_start));
    setEndDate(convertTZ(dataBanner.publish_end));
  }
  const getDetailDataBanner = (page) => {
    setIsLoading(true);
    DetailSummaryBanners(page)
    .then(responseBanner => {
      splitDataBanner(responseBanner.data.data);
      setIsLoading(false);
    })
    .catch(e => {
      ErrorFunction(e);
      setIsLoading(false);
    })
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const userAddress = window.location.pathname.split('/')[2];
      if(userAddress !== 'add-banner'){
        setEditPage(true);
        getDetailDataBanner(userAddress);
        setPageID(userAddress);
      }
    }
  }, []);

  const isSubmit = event => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('title', titleBanner);
    formData.append('url', urlRedirection);
    formData.append('order', sorting);
    formData.append('status', statusBanner);
    formData.append('type', bannerType);
    if(bannerImage){
      formData.append('image', bannerImage);
    }
    if(startDate){
      formData.append('publish_start', moment(startDate).format('YYYY-MM-DD'));
    }
    if(endDate){
      formData.append('publish_end', moment(endDate).format('YYYY-MM-DD'));
    }

    if(editPage){
      updateBanner(formData);
    }else{
      createBanner(formData);
    }
  }

  const backToBannerPage = () => {
    const timeoutId = setTimeout(() => {
      history.push("/banner-management");
      window.location.reload();
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }
  const createBanner = (dataSubmission) => {
    CreateBanner(dataSubmission)
    .then(responseDeleteBanner => {
      if (responseDeleteBanner.data.code === 200) {
        toast.success('Banner has been created');
        backToBannerPage();
      }
    })
    .catch(e => {
      ErrorFunction(e);
      setIsLoading(false);
    })
  }
  const updateBanner = (dataSubmission) => {
    UpdateBanner(pageID, dataSubmission)
    .then(responseDeleteBanner => {
      if (responseDeleteBanner.data.code === 200) {
        toast.success('Banner has been Updated');
        backToBannerPage();
      }
    })
    .catch(e => {
      ErrorFunction(e);
      setIsLoading(false);
    })
  }

  const onChangeFile = (e) => {
    let file = e.target.files[0];
    if (file) {
      setBannerImage(file);
    }
  };

  return (
    <div style={{fontSize: '10px'}} className="head">
      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-portlet__preview">
            <div className="kt-section">
              <form className="m-login__form m-form" onSubmit={isSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group>
                      <Form.Label>Banner Title</Form.Label>
                      <Form.Control type="text" placeholder="Banner Title" value={titleBanner} onChange={(event) => setTitleBanner(event.target.value)} required/>
                    </Form.Group>
                  </div>
                  <div className="col-md-7">
                    <Form.Group>
                      <Form.Label>Url</Form.Label>
                      <Form.Control type="text" placeholder="https://www.example.com" value={urlRedirection}  onChange={(event) => setUrlRedirection(event.target.value)} required/>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group>
                      <Form.Label>Type</Form.Label>
                      <select className="custom-select costume-dropdown" onChange={(e) => setBannerType( e.target.value )} required>
                        {BannerTypes.map((e) => (
                          <option key={e.value} value={e.value} selected={e.value === bannerType} defaultValue={e.value === bannerType}>{e.label}</option>
                        ))}
                      </select>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group>
                      <Form.Label>Sorting No.</Form.Label>
                      <Form.Control type="number" placeholder="0" value={sorting} onChange={(event) => setSorting(event.target.value)} required/>
                    </Form.Group>
                  </div>
                  <div className='col-md-9'>
                    <Form.Group>
                      <Form.Label>Banner Image</Form.Label>
                      <div className='d-flex justify-content-center align-items-center'>
                        <Form.Control
                          className='form-control'
                          type='file'
                          onChange={onChangeFile}
                          accept='image/*'
                        />
                      </div>
                      <Form.Text className='text-muted'>
                        <span>*Recommended size: Hero Section 600x600px, Our NFT 450x480px, with max size 10mb</span>
                      </Form.Text>
                    </Form.Group>
                  </div>
                  <div className='col-md-3'>
                    <Form.Group className='d-flex justify-content-center'>
                      {!bannerImage && bannerImageURL !== '' && (
                        <div style={{ marginTop: '2rem', width: '150px', height: '160px' }}>
                          <a
                            href={linkImage[0]+bannerImageURL}
                            target='_blank'
                            without
                            rel='noopener noreferrer'
                          >
                            <img
                              style={{ width: '100%', height: '100%' }}
                              src={linkImage[0]+bannerImageURL}
                              alt='img'
                              className='img-banner'
                            />
                          </a>
                        </div>)}
                    </Form.Group>
                  </div>
                  <div className='col-md-4'>
                    <Form.Group>
                      <Form.Label>Publish Start Date</Form.Label>
                      <div style={{width: '100%', marginRight: '1rem'}}>
                        <SelectDate
                          changeDate={(val) => setStartDate(val)}
                          selected={startDate}
                          placeholder="Publish start date"/>
                      </div>
                    </Form.Group>
                  </div>
                  <div className='col-md-4'>
                    <Form.Group>
                      <Form.Label>Publish End Date</Form.Label>
                      <div style={{width: '100%', marginRight: '1rem'}}>
                        <SelectDate
                          changeDate={(val) => setEndDate(val)}
                          selected={endDate}
                          placeholder="Publish end date"/>
                      </div>
                    </Form.Group>
                  </div>
                  <div className='col-md-4'>
                    <Form.Group>
                      <Form.Label>Status</Form.Label>
                      <select className="custom-select costume-dropdown" onChange={(e) => setStatusBanner( e.target.value )} required>
                        {Status.map((e) => (
                          <option key={e.value} value={e.value} selected={e.value === statusBanner} defaultValue={e.value === statusBanner}>{e.label}</option>
                        ))}
                      </select>
                    </Form.Group>
                  </div>
                </div>
                <div className="kt-login__actions" style={{justifyContent: 'center', display: 'flex'}}>
                  <Link to="/banner-management" style={{width: '17%'}}>
                    <Button variant="outline-danger" className="mr-2">Back To List Banners</Button>
                  </Link>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
                      }
                    )}`}
                  >
                    {editPage ? 'Update' : 'Create'} Banner
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ManagementBanner;