import React from 'react';
import { Link } from "react-router-dom";
import clsx from "clsx";
import moment from 'moment';
import { Form, Button } from "react-bootstrap";
import { createBrowserHistory } from 'history';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class AddsupportContract extends React.Component {
  history() {
    history.push("/leaderboard");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tcdx-upload-dev.oss-ap-southeast-5.aliyuncs.com/' : 'https://tcdx-upload.oss-ap-southeast-5.aliyuncs.com/',
      page: '',
      idData: '',
      loading: false,
      name: '',
      address: '',
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
  }

  emptyState(){
    this.setState({
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tcdx-upload-dev.oss-ap-southeast-5.aliyuncs.com/' : 'https://tcdx-upload.oss-ap-southeast-5.aliyuncs.com/',
      page: '',
      idData: '',
      loading: false,
      name: '',
      address: '',
    });
  }
  // getToken(){
  //   let token = localStorage.getItem("token")
  //   return token;
  // }


  componentDidMount(){
    let checkPath = this.props.history.location.pathname.split('/');
    if(checkPath.indexOf("edit-category") > 0){
      this.setState({
        page: checkPath[checkPath.length - 1],
        idData: checkPath[checkPath.length - 2],
        editPage: true,
      });
      // this.getDetailData(checkPath[checkPath.length - 2]);
    }
    // let id = this.props.history.location.pathname.split('/')[this.props.history.location.pathname.split('/').length - 2];
  }

  dateTime = date => {
    var ISOformat = moment(date).toISOString()
    return ISOformat
  }

  render(){
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                  <Form.Group>
                    <Form.Label>Contract Name</Form.Label>
                    <Form.Control type="text" value={this.state.name} onChange={this.handleChange('name')} required/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Contract Address</Form.Label>
                    <Form.Control type="text" value={this.state.address} onChange={this.handleChange('address')} required/>
                  </Form.Group>
                  <div className="kt-login__actions">
                    <Link to="/supported-contracts">
                      <Button variant="outline-danger" className="mr-4" style={{width: '10%'}}>Back</Button>
                    </Link>
                    <button
                      style={{width: '12%'}}
                      type="submit"
                      disabled={this.state.loading}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                        }
                      )}`}
                    >
                      {this.state.editPage ? 'Edit' : 'Add'} Contract
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}