import React from 'react';
import { Link } from "react-router-dom";
import clsx from "clsx";
import moment from 'moment';
import { Form, Button } from "react-bootstrap";
import { createBrowserHistory } from 'history';
import { DetailCategory, CreateCategory, UpdateCategory } from '../../crud/faq';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class AddFAQ extends React.Component {
  history() {
    history.push("/leaderboard");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/',
      page: '',
      idData: '',
      loading: false,
      editPage: false,
      titleID: '',
      titleEN: '',
      sorting: 0,
      slug: '',
      status: 0,
      icon: null,
      iconPreview: ''

    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
  }

  emptyState(){
    this.setState({
      page: '',
      idData: '',
      loading: false,
      questionID: '',
      questionEN: '',
      contentID: '<h2>Syarat Dan Ketentuan</h2><ul><li>Syarat A</li><li>Syarat B</li><li>Syarat C</li><li>Syarat D</li><li>Syarat E</li></ul><p><a href="https://tokocrypto.com">Selengkapnya →</a></p>',
      contentEN: '<h2>Term And Condition</h2><ul><li>Term A</li><li>Term B</li><li>Term C</li><li>Term D</li><li>Term E</li></ul><p><a href="https://tokocrypto.com">Show More →</a></p>',
      sorting: 0,
      slug: '',
      status: 0,
      icon: null,
      iconPreview: ''
    });
  }

  handleChangeEditorID =  ( event ) => {
    const data = event.editor.getData();
    this.setState({ contentID: data });
  }

  handleChangeEditorEN =  ( event ) => {
    const data = event.editor.getData();
    this.setState({ contentEN: data });
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getDetailData(id){
    this.setState({
      loading: true
    })

    DetailCategory(this.getToken(), id)
    .then(res => {
      console.log('detail :', res.data.data)
      this.setState({
        loading: false,
        titleID: res.data.data.title_id,
        titleEN: res.data.data.title_en,
        status: res.data.data.status,
        slug: res.data.data.slug,
        sorting: res.data.data.order,
        iconPreview: res.data.data.photo,
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }

  isSubmit = event => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('title_id', this.state.titleID);
    formData.append('title_en', this.state.titleEN);
    formData.append('status', this.state.status);
    formData.append('slug', this.state.slug);
    formData.append('order', this.state.sorting);
    if(this.state.icon !== null){
      formData.append('file', this.state.icon);
    }

    if(this.state.editPage){
      this.editEvent(formData);
    }else{
      this.addEvent(formData);
    }
  }

  editEvent(data){
    this.setState({
      loading: true
    })

    UpdateCategory(this.getToken(), this.state.idData,data)
    .then(res => {
      this.setState({
        loading: false,
      })
      this.emptyState();
      history.push("/faq/category-faq");
      window.location.reload();
    })
    .catch(e => {
      console.log(e);
      this.emptyState();
      this.setState({
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  addEvent(data){
    this.setState({
      loading: true
    })

    CreateCategory(this.getToken(), data)
    .then(res => {
      this.setState({
        loading: false,
      })
      this.emptyState();
      history.push("/faq/category-faq");
      window.location.reload();
    })
    .catch(e => {
      console.log(e);
      this.emptyState();
      this.setState({
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }


  componentDidMount(){
    let checkPath = this.props.history.location.pathname.split('/');
    if(checkPath.indexOf("update") > 0){
      this.setState({
        page: checkPath[checkPath.length - 1],
        idData: checkPath[checkPath.length - 2],
        editPage: true,
      });
      this.getDetailData(checkPath[checkPath.length - 2]);
    }
    // let id = this.props.history.location.pathname.split('/')[this.props.history.location.pathname.split('/').length - 2];
  }

  dateTime = date => moment(date).toISOString();

  onChangeFile = async (e) => {
    let file = e.target.files[0];
    if(file){
      await this.setState({
        icon: file
      })
    }
  }

  render(){
    const statusFaq = [
      {
        value: 0,
        label : 'Enable'
      },
      {
        value: 1,
        label : 'Disabled'
      }
    ]
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Category Title (ID)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Category Name" value={this.state.titleID} onChange={this.handleChange('titleID')} required/>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Category Title (EN)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Category Name" value={this.state.titleEN} onChange={this.handleChange('titleEN')} required/>
                      </Form.Group>
                    </div>
                  </div>
                  {/* <Form.Group>
                    <Form.Label>Content (ID</Form.Label>
                    <Form.Control as="textarea" rows={3} value={this.state.contentID} onChange={this.handleChange('contentID')}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Content (EN)</Form.Label>
                    <Form.Control as="textarea" rows={3} value={this.state.contentEN} onChange={this.handleChange('contentEN')}/>
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label>Slug</Form.Label>
                    <Form.Control type="text" placeholder="slug-name" value={this.state.slug} onChange={this.handleChange('slug')} required/>
                  </Form.Group>
                  <div className="row">
                    <div className="col-md-9">
                      <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <select className="custom-select costume-dropdown" onChange={(e) => this.setState({status: e.target.value})} placeholder="Select Status" required>
                          <option disabled selected={this.state.status === null ? true : false}>Select Status</option>
                          {statusFaq.map((e) => (
                            <option key={e.value} value={e.value} selected={e.value === this.state.status ? true : false} defaultValue={e.value === this.state.status ? true : false}>{e.label}</option>
                          ))}
                        </select>
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group>
                        <Form.Label>Sorting Number</Form.Label>
                        <Form.Control type="number" min="0" value={this.state.sorting} onChange={(e) => this.setState({ sorting: e.target.value })}/>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-10 col-sm-12">
                    <Form.Group>
                      <Form.Label>Category Icon</Form.Label>
                      <div className="d-flex justify-content-center align-items-center">
                        <Form.Control className="form-control" type="file" onChange={this.onChangeFile} accept="image/*"/>
                      </div>  
                      <Form.Text className="text-muted">
                        <span>*Please using 1440 x 400 px for this image</span>
                      </Form.Text>
                    </Form.Group>
                    </div>
                    <div className="col-md-2 col-sm-12">
                      <Form.Group>
                        <div style={{marginTop: '2rem'}}>
                          {this.state.iconPreview !== '' && (
                            <a href={this.state.urlAsset+this.state.iconPreview} target='_blank' without rel="noopener noreferrer">
                              <img src={this.state.urlAsset+this.state.iconPreview} alt="img" className="img-bn" />
                            </a>
                          )}
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="kt-login__actions" style={{justifyContent: 'center', display: 'flex'}}>
                    <Link to="/faq/category-faq" style={{width: '17%'}}>
                      <Button variant="outline-danger" className="mr-2">Back To Category</Button>
                    </Link>
                    <button
                      type="submit"
                      disabled={this.state.loading}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                        }
                      )}`}
                    >
                      {this.state.editPage ? 'Edit' : 'Add'} Category
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}