import React from 'react';
import { Link } from "react-router-dom";
import clsx from "clsx";
import moment from 'moment';
import CKEditor from 'ckeditor4-react';
import { Form, Button } from "react-bootstrap";
import { createBrowserHistory } from 'history';
import { Detail, Create, Update, SummaryCategoryDropdown } from '../../crud/faq';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class AddFAQ extends React.Component {
  history() {
    history.push("/leaderboard");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      page: '',
      idData: '',
      loading: false,
      editPage: false,
      questionID: '',
      questionEN: '',
      contentID: '<p><img alt="photo" src="https://drive.google.com/uc?export=view&amp;id=1YOsSqncZUL49arXxZEwcajKOS7YRoiI_" style="height:220px; width:800px" /></p>\n\n<p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.</p>\n',
      contentEN: '<p><img alt="photo" src="https://drive.google.com/uc?export=view&amp;id=1YOsSqncZUL49arXxZEwcajKOS7YRoiI_" style="height:220px; width:800px" /></p>\n\n<p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.</p>\n',
      sorting: 0,
      dataDropdown: [],
      dropdown: '',
      slug: '',
      status: 0,

    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
  }

  emptyState(){
    this.setState({
      page: '',
      idData: '',
      loading: false,
      questionID: '',
      questionEN: '',
      contentID: '<h2>Syarat Dan Ketentuan</h2><ul><li>Syarat A</li><li>Syarat B</li><li>Syarat C</li><li>Syarat D</li><li>Syarat E</li></ul><p><a href="https://tokocrypto.com">Selengkapnya →</a></p>',
      contentEN: '<h2>Term And Condition</h2><ul><li>Term A</li><li>Term B</li><li>Term C</li><li>Term D</li><li>Term E</li></ul><p><a href="https://tokocrypto.com">Show More →</a></p>',
      sorting: 0,
      dropdown: '',
      slug: '',
      status: 0,
    });
  }

  handleChangeEditorID =  ( event ) => {
    const data = event.editor.getData();
    this.setState({ contentID: data });
  }

  handleChangeEditorEN =  ( event ) => {
    const data = event.editor.getData();
    this.setState({ contentEN: data });
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getdataDropdown(){
    this.setState({
      loading: true
    })

    SummaryCategoryDropdown(this.getToken())
    .then(res => {
      let datas = []
      res.data.data.forEach((element) => {
        datas.push({
          label: element.title_en,
          value: element.id
        })
      });
      this.setState({
        dataDropdown: datas,
        loading: false
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }

  getDetailData(id){
    this.setState({
      loading: true
    })

    Detail(this.getToken(), id)
    .then(res => {
      this.setState({
        loading: false,
        dropdown: res.data.data.id_faq_category,
        questionID: res.data.data.title_id,
        questionEN: res.data.data.title_en,
        contentID: res.data.data.body_id,
        contentEN: res.data.data.body_en,
        sorting: res.data.data.order,
        status: res.data.data.status,
        slug: res.data.data.slug
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }

  isSubmit = event => {
    event.preventDefault();

    let data = {
      'title_id': this.state.questionID,
      'title_en': this.state.questionEN,
      'body_id': this.state.contentID,
      'body_en': this.state.contentEN,
      'slug': this.state.slug,
      'status': this.state.status,
      'order': this.state.sorting,
    }
    if(this.state.dropdown !== null){
      data.id_faq_category = parseInt(this.state.dropdown)
    }

    if(this.state.editPage){
      this.editEvent(data);
    }else{
      this.addEvent(data);
    }
  }

  editEvent(data){
    this.setState({
      loading: true
    })

    Update(this.getToken(), this.state.idData,data)
    .then(res => {
      this.setState({
        loading: false,
      })
      this.emptyState();
      history.push("/faq");
      window.location.reload();
    })
    .catch(e => {
      console.log(e);
      this.emptyState();
      this.setState({
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  addEvent(data){
    this.setState({
      loading: true
    })

    Create(this.getToken(), data)
    .then(res => {
      this.setState({
        loading: false,
      })
      this.emptyState();
      history.push("/faq");
      window.location.reload();
    })
    .catch(e => {
      console.log(e);
      this.emptyState();
      this.setState({
        loading: true
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }


  componentDidMount(){
    this.getdataDropdown();
    let checkPath = this.props.history.location.pathname.split('/');
    if(checkPath.indexOf("edit-faq") > 0){
      this.setState({
        page: checkPath[checkPath.length - 1],
        idData: checkPath[checkPath.length - 2],
        editPage: true,
      });
      this.getDetailData(checkPath[checkPath.length - 2]);
    }
    // let id = this.props.history.location.pathname.split('/')[this.props.history.location.pathname.split('/').length - 2];
  }

  dateTime = date => {
    var ISOformat = moment(date).toISOString()
    return ISOformat
  }

  render(){
    const statusFaq = [
      {
        value: 0,
        label : 'Enable'
      },
      {
        value: 1,
        label : 'Disabled'
      }
    ]
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group>
                        <Form.Label>Category</Form.Label>
                        <select className="custom-select costume-dropdown" onChange={(e) => this.setState({dropdown: e.target.value})} placeholder="Select Category" required>
                          <option disabled selected={this.state.dropdown === null || this.state.dropdown === '' ? true : false}>Select Category</option>
                          {this.state.dataDropdown.map((e) => (
                            <option key={e.value} value={e.value} selected={e.value === this.state.dropdown ? true : false} defaultValue={e.value === this.state.dropdown ? true : false}>{e.label}</option>
                          ))}
                        </select>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Question (ID)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Event Name" value={this.state.questionID} onChange={this.handleChange('questionID')} required/>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Question (EN)</Form.Label>
                        <Form.Control type="text" placeholder="Enter Event Name" value={this.state.questionEN} onChange={this.handleChange('questionEN')} required/>
                      </Form.Group>
                    </div>
                  </div>
                  {/* <Form.Group>
                    <Form.Label>Content (ID</Form.Label>
                    <Form.Control as="textarea" rows={3} value={this.state.contentID} onChange={this.handleChange('contentID')}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Content (EN)</Form.Label>
                    <Form.Control as="textarea" rows={3} value={this.state.contentEN} onChange={this.handleChange('contentEN')}/>
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label>Content (ID)</Form.Label>
                    <br />
                    <CKEditor
                      onChange={this.handleChangeEditorID}
                      data={this.state.contentID}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Content (EN)</Form.Label>
                    <br />
                    <CKEditor
                      onChange={this.handleChangeEditorEN}
                      data={this.state.contentEN}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Slug</Form.Label>
                    <Form.Control type="text" placeholder="slug-name" value={this.state.slug} onChange={this.handleChange('slug')} required/>
                  </Form.Group>
                  <div className="row">
                    <div className="col-md-9">
                      <Form.Group>
                        <Form.Label>Status</Form.Label>
                        <select className="custom-select costume-dropdown" onChange={(e) => this.setState({status: e.target.value})} placeholder="Select Status" required>
                          <option disabled selected={this.state.status === null ? true : false}>Select Status</option>
                          {statusFaq.map((e) => (
                            <option key={e.value} value={e.value} selected={e.value === this.state.status ? true : false} defaultValue={e.value === this.state.status ? true : false}>{e.label}</option>
                          ))}
                        </select>
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group>
                        <Form.Label>Sorting Number</Form.Label>
                        <Form.Control type="number" min="0" value={this.state.sorting} onChange={(e) => this.setState({ sorting: e.target.value })}/>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="kt-login__actions" style={{justifyContent: 'center', display: 'flex'}}>
                    <Link to="/faq" style={{width: '17%'}}>
                      <Button variant="outline-danger" className="mr-2">Back To FAQ</Button>
                    </Link>
                    <button
                      type="submit"
                      disabled={this.state.loading}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                        }
                      )}`}
                    >
                      {this.state.editPage ? 'Edit' : 'Add'} FAQ
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}