import axios from "axios";

const instance = (process.env.REACT_APP_ENV === 'demo') ? 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  }) :
  (process.env.REACT_APP_ENV === 'production') ? 
    axios.create({
      baseURL: 'https://service.tkonft.art',
    }) : 
  axios.create({
    baseURL: 'https://api-tokomall-demo.tcdx.id',
  });

export function SummaryBanners(params) {
  return instance.get("/admin/tkonft/banner-list" + params, {
    headers: {
      'Authorization': localStorage.getItem('token'),
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function DetailSummaryBanners(id) {
  return instance.get("/admin/tkonft/banner-list/" + id, {
    headers: {
      'Authorization': localStorage.getItem('token'),
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function CreateBanner(data) {
  return instance.post("/admin/tkonft/banner-create", data, {
    headers: {
      'Authorization': localStorage.getItem('token'),
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function UpdateBanner(id, data) {
  return instance.put("/admin/tkonft/banner-update/"+id, data, {
    headers: {
      'Authorization': localStorage.getItem('token'),
      'X-TCDX-SIGNATURE': 'salamtothemoon'
    }
  });
}

export function UpdateStatusBanner(id) {
  return instance.put("/admin/tkonft/banner-update-status/"+id,{}, {
    headers: {
      'Authorization': localStorage.getItem('token'),
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    }
  });
}

export function DeleteBanner(id) {
  return instance.delete("/admin/tkonft/banner-softdelete/"+id, {
    headers: {
      'Authorization': localStorage.getItem('token'),
      'X-TCDX-SIGNATURE': 'salamtothemoon',
    }
  });
}
