import React from 'react';
import { Link } from "react-router-dom";
import clsx from "clsx";
import Select from 'react-select';
import { Form, Button } from "react-bootstrap";
import { createBrowserHistory } from 'history';
import { Create, Detail, Update, getListRole } from '../../crud/admin';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
require("bootstrap/scss/bootstrap.scss");

const history =  createBrowserHistory();

export default class AddCategory extends React.Component {
  history() {
    history.push("/leaderboard");
    window.location.reload();
  }

  constructor(props) {
    super(props);
    this.state = {
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tcdx-upload-dev.oss-ap-southeast-5.aliyuncs.com/' : 'https://tcdx-upload.oss-ap-southeast-5.aliyuncs.com/',
      page: '',
      idData: '',
      loading: false,
      name: '',
      email: '',
      role: 'admin',
      password: '',
      confirm: '',
      status: '1',
      roles: [],
      valueRoleDefault: []
    }

    this.handleChange = (fieldName) => (event) => {
      let obj = Object.assign({})
      obj[fieldName] = event.target.value
      obj.resetCapctha = false;
      this.setState(obj)
    }
    this.handleSelect = this.handleSelect.bind(this)
  }

  emptyState(){
    this.setState({
      urlAsset: process.env.REACT_APP_ENV === 'demo' ? 'https://tcdx-upload-dev.oss-ap-southeast-5.aliyuncs.com/' : 'https://tcdx-upload.oss-ap-southeast-5.aliyuncs.com/',
      page: '',
      idData: '',
      loading: false,
      name: '',
      email: '',
      role: 'admin',
      password: '',
      confirm: '',
      status: '',
      roles: [],
      valueRoleDefault: []
    });
  }

  handleChangeEditorID =  ( event ) => {
    const data = event.editor.getData();
    this.setState({ descID: data });
  }

  handleChangeEditorEN =  ( event ) => {
    const data = event.editor.getData();
    this.setState({ descEN: data });
  }

  getToken(){
    let token = localStorage.getItem("token")
    return token;
  }

  getDetailData(id){
    this.setState({
      loading: true
    })

    Detail(this.getToken(), id)
    .then(res => {
      let role = [];
      let idRole = [];
      res.data.data.admin_role_menus.map((val) => 
        role.push({
          value: val.id,
          label: val.name
        })
      )
      res.data.data.admin_role_menus.map((val) => 
        idRole.push(val.id),
      )
      this.setState({
        loading: false,
        name: res.data.data.name,
        email: res.data.data.email,
        valueRoleDefault: role,
        password: '',
        confirm: '',
        status: res.data.data.status.toString(),
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }

  getRole(){
    this.setState({
      loading: true
    })

    getListRole(this.getToken())
    .then(res => {
      const datas = res.data.data;
      let arr = [];
      datas.map((e) => 
        arr.push({
          value: e.id,
          label: e.name
        })
      )
      this.setState({
        loading: false,
        roles: arr
      })
    })
    .catch(e => {
      console.log(e);
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    })
  }

  isSubmit = event => {
    event.preventDefault();
    let valRoles = Array.isArray(this.state.valueRoleDefault)?this.state.valueRoleDefault.map(x=>x.value):[];
    let data = {
      'email': this.state.email,
      'name': this.state.name,
      'role': valRoles,
      'status': this.state.status,
    }

    if(this.state.confirm !== ''){
      if(this.state.password === this.state.confirm){
        data['password'] = this.state.confirm
      }else{
        toast.error(`your password and your confirm is not match`);
      }
    }
    
    if(this.state.editPage){
      this.editEvent(data);
    }else{
      this.addEvent(data);
    }
  }

  editEvent(data){
    this.setState({
      loading: true
    })

    Update(this.getToken(), this.state.idData,data)
    .then(res => {
      this.setState({
        loading: false,
      })
      this.emptyState();
      history.push("/admin");
      window.location.reload();
    })
    .catch(e => {
      console.log(e);
      this.emptyState();
      this.setState({
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  addEvent(data){
    this.setState({
      loading: true
    })

    Create(this.getToken(), data)
    .then(res => {
      this.setState({
        loading: false,
      })
      this.emptyState();
      history.push("/admin");
      window.location.reload();
    })
    .catch(e => {
      console.log(e);
      this.emptyState();
      this.setState({
        dataEvent:[],
        loading: false
      })
      if(e.response){
        if(e.response.data.code === 401){
          toast.error("token has expired");
          localStorage.clear();
          history.push("/auth");
          window.location.reload();
        }
        else{
          toast.error(`error code: ${e.response.data.code} message: ${e.response.data.message}`);
        }
      }
    }) 
  }

  componentDidMount(){
    this.getRole();
    let checkPath = this.props.history.location.pathname.split('/');
    if(checkPath.indexOf("edit-admin") > 0){
      this.setState({
        page: checkPath[checkPath.length - 1],
        idData: checkPath[checkPath.length - 2],
        editPage: true,
      });
      this.getDetailData(checkPath[checkPath.length - 2]);
    }
    // let id = this.props.history.location.pathname.split('/')[this.props.history.location.pathname.split('/').length - 2];
  }

  handleSelect(e) {
    this.setState({
      valueRoleDefault: e || [],
    })
  }

  render(){
    const statusType = [
      {
        id: "1",
        name: 'Active',
      },
      {
        id: "0",
        name: 'In Active',
      },
    ];
    return (
      <div style={{fontSize: '10px'}} className="head">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="kt-portlet__preview">
              <div className="kt-section">
                <form className="m-login__form m-form" onSubmit={this.isSubmit}>
                  <Form.Group>
                    <Form.Label>Admin Name</Form.Label>
                    <Form.Control type="text" value={this.state.name} onChange={this.handleChange('name')} required/>
                  </Form.Group>
                  <div className="row">
                    <div className="col-md-7">
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" value={this.state.email} onChange={this.handleChange('email')} required/>
                      </Form.Group>
                    </div>
                    <div className="col-md-5">
                      {/* <div className="form-group"> */}
                        {/* <label className="form-label">Roles</label> */}
                        {/* <div className="input-group">
                            <select className="form-control" onChange={(e) => this.setState({ role: e.target.value })} placeholder="Select Role" required>
                                <option disabled selected={this.state.role === '' ? true : false}>Select role</option>
                                {role.map((e) => (
                                  <option key={e.id} value={e.id} selected={e.id === this.state.role ? true : false} defaultValue={e.id === this.state.role ? true : false}>{e.name}</option>
                                ))}
                            </select>
                        </div> */}
                        <div className="form-group">
                        <label className="form-label">Roles</label>
                          <Select
                            value={this.state.valueRoleDefault}
                            onChange={this.handleSelect}
                            isMulti
                            options={this.state.roles}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" value={this.state.password} onChange={this.handleChange('password')}/>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label>Re-type Password</Form.Label>
                        <Form.Control type="password" value={this.state.confirm} onChange={this.handleChange('confirm')}/>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Status</label>
                    <div className="input-group">
                        <select className="form-control" onChange={(e) => this.setState({ status: e.target.value })} placeholder="Select Data">
                            <option disabled selected={this.state.status === '' ? true : false}>Pilih Salah Satu</option>
                            {statusType.map((e) => (
                              <option key={e.id} value={e.id} selected={e.id === this.state.status ? true : false} defaultValue={e.id === this.state.status ? true : false}>{e.name}</option>
                            ))}
                        </select>
                    </div>
                  </div>
                  <div className="kt-login__actions">
                    <Link to="/admin" style={{width: '17%'}}>
                      <Button variant="outline-danger" className="mr-3">Back To Admin</Button>
                    </Link>
                    <button
                      style={{width: '10%'}}
                      type="submit"
                      disabled={this.state.loading}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                        }
                      )}`}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}