let role = localStorage.getItem("menu")
let items = []
// ["dashboard", "category", "static_pages", "faq", "merchant", "merchant_approval", "collector", "artworks", "featured_merchants", "featured_artworks", "transaction", "user_management", "general_configuration", "system_log", "supported_contracts", "memchaced"]
// console.log('biadd :', arrayList.length);
// console.log('arrayList :', arrayList);
if(role){
  let arrayList = JSON.parse(role);
  if(arrayList.includes('dashboard')){
    items.push({
      title: "Dashboard",
      root: true,
      icon: "fas fa-home",
      page: "dashboard",
      bullet: "dot"
    })
  }
  if(arrayList.includes('category')){
    items.push({
      title: "Category",
      root: true,
      icon: "far fa-list-alt",
      page: "category",
      bullet: "dot"
    })
  }
  if(arrayList.includes('static_pages')){
    items.push({
      title: "Static Pages",
      root: true,
      icon: "fas fa-newspaper",
      bullet: "dot",
      submenu: [
        {
          title: "How it Works",
          page: "static-pages/how-it-works"
        },
        {
          title: "Term & Condition",
          page: "static-pages/term-condition"
        },
        {
          title: "Privacy & Policy",
          page: "static-pages/privacy-policy"
        },
        {
          title: "What is TKO NFT",
          page: "static-pages/what-is-tko-nft"
        },
      ]
    })
  }
  if(arrayList.includes('faq')){
    items.push({
      title: "FAQ",
      root: true,
      icon: "flaticon-chat",
      page: "faq",
      bullet: "dot"
    })
  }
  if(arrayList.includes('banner_management')){
    items.push({
      title: "Banner Management",
      root: true,
      icon: "fas fa-images",
      page: "banner-management",
      bullet: "dot"
    })
  }
  if(arrayList.includes('merchant')){
    items.push({
      title: "Merchants",
      root: true,
      icon: "fas fa-store",
      page: "merchants",
      bullet: "dot"
    })
  }
  if(arrayList.includes('merchant_approval')){
    items.push({
      title: "Merchants",
      root: true,
      icon: "fas fa-store",
      page: "merchants",
      bullet: "dot"
    })
  }
  if(arrayList.includes('collector')){
    items.push({
      title: "Collectors",
      root: true,
      icon: "fas fa-user-tag",
      page: "collectors",
      bullet: "dot"
    })
  }
  if(arrayList.includes('artworks')){
    items.push({
      title: "Artwork",
      root: true,
      icon: "flaticon-line-graph",
      bullet: "dot",
      submenu: [
        {
          title: "Single NFT",
          page: "artworks"
        },
        {
          title: "Toko Surprise",
          page: "toko-surpise"
        },
      ]
    })
  }
  if(arrayList.includes('featured_artworks')){
    items.push({
      title: "Feature Artwork",
      root: true,
      icon: "fas fa-sort-amount-down",
      page: "feature-artwork",
      bullet: "dot"
    })
  }
  if(arrayList.includes('featured_partner')){
    items.push({
      title: "Feature Partner",
      root: true,
      icon: "fas fa-sort-amount-down",
      page: "feature-partner",
      bullet: "dot"
    })
  }
  if(arrayList.includes('transaction')){
    items.push({
      title: "Transaction",
      root: true,
      icon: "fas fa-shopping-bag",
      bullet: "dot",
      submenu: [
        {
          title: "NFTs & TokoSurprise",
          page: "transaction-nft-tokosurprise"
        },
        {
          title: "Collectibles",
          page: "transaction-collectibles"
        },
      ]
    })
  }
  if(arrayList.includes('user_management')){
    items.push({
      title: "User Management",
      root: true,
      icon: "fas fa-users",
      page: "admin",
      bullet: "dot"
    })
  }
  if(arrayList.includes('general_configuration')){
    items.push({
      title: "General Configuration",
      root: true,
      icon: "flaticon2-gear",
      page: "general-configuration",
      bullet: "dot"
    })
  }
  if(arrayList.includes('system_log')){
    items.push({
      title: "System Log",
      root: true,
      icon: "fas fa-clipboard-list",
      page: "system-log",
      bullet: "dot"
    })
  }
  if(arrayList.includes('collectibles')){
    items.push({
      title: "Import Contract",
      root: true,
      icon: "fas fa-file-import",
      page: "import-contract",
      bullet: "dot"
    })
  }
}

export default {
  header: {
    self: {},
    items: []
  },
  aside: {
    self: {},
    items: items
  }
};
