/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';
import ListExtension from '../handler/extension.json';
import { toAbsoluteUrl } from "../../_metronic/utils/utils";

const Viewcard = ({
  data, specialPage = false,
}) => {
  const listext = ListExtension;
  const linkImage = process.env.REACT_APP_ENV === 'demo' ? 'https://tko-nft-demo.oss-ap-southeast-5.aliyuncs.com/' : 'https://tko-nft-prod.oss-ap-southeast-5.aliyuncs.com/';
  const [extension, setExt] = useState('image');
  const [url, setUrl] = useState('');
  useEffect(() => {
    if (data && data.uri_image) {
      const ext = data.image_ext?.toLowerCase();
      const extset = listext[ext] ? listext[ext] : 'none';
      setExt(extset);
      setUrl(linkImage + data.uri_image);
    }
  }, [data, linkImage, listext]);
  const image = (
    <>
      <img src={`${url}`} className={!specialPage ? 'img-list-data' : 'img-detail'} alt="" />
    </>
  );
  const video = (
    <>
      <img src={`${url}`} className={!specialPage ? 'img-list-data' : 'img-detail'} alt="" />
    </>
  );
  const audio = (
    <>
      <img src={toAbsoluteUrl("/media/nft/default-audio.png")} className={!specialPage ? 'img-list-data' : 'img-detail'} alt="" />
    </>
  );
  return (
    <>
      {extension === 'image' && (
        <>
          { image }
        </>
      )}
      {extension === 'video' && (
        <>
          { video }
        </>
      )}
      {extension === 'audio' && (
        <>
          { audio }
        </>
      )}
      {extension === 'none' && (
        <>
          Unable to preview file.
        </>
      )}
    </>
  );
};
export default Viewcard;
